import {
  COLOUR_STANDARD_CUSTOM_ID,
  FABRIC_SUPPLIED_BY_ALUXOR,
  isMotionSensor,
  ORDER_STATUS_QUOTE,
  isManual,
  isVarioVolant,
  isRemote,
  isHardwired,
  isSelectaPitch,
  isNullEmptyOrWhitespace,
  PRODUCTID_SDM,
  IsNullOrUndefined,
  MOUNTING_EXTENSION,
  PRODUCTGROUPID_CONSERVATORY,
  MOUNTING_BACKPOSTS,
  isSDorSM,
  hasInstallationPosts,
  AwningRequiresFabric,
  FRAME_FEATURE_SELECTAPITCH,
  DRIVE_MECHANISM_MANUAL,
  RequireAdditionalBracketCount,
  PRODUCTGROUPID_FOLDING_ARM, CanHaveWedges, IsRoofSystem, IsPelmetTypeVisible
} from '../../constants';

export const validationCreator = (isValid = true, message = '')=> {
  return {isValid, message};
};

export const colourValidation = (val, state) => {
  if(val.selectedStandardColourId != COLOUR_STANDARD_CUSTOM_ID && (val.selectedStandardColourId == 0 || val.selectedStandardColourId == null)){
    return validationCreator(false, 'Please select a valid colour');
  }
  if(val.selectedStandardColourId == COLOUR_STANDARD_CUSTOM_ID && (val.selectedCustomColourId == 0 || val.selectedCustomColourId == null)){
    return validationCreator(false, 'Please select a valid colour');
  }
  return validationCreator();
};

export const ValidationRules = {
  productGroupId: {
    validator: (val, state) => {
      return val == null || val == 0 ? validationCreator(false, 'Please select a product group') : validationCreator();
    }
  },
  productId: {
    validator: (val, state) => {
      return val == null || val == 0 ? validationCreator(false, 'Please select a product') : validationCreator();
    }
  },
  /*productVariationLookupDataId: {
    validator: (val, state) => {
      return (state.validationMessages.productVariationLookupDataId == undefined || state.validationMessages.productVariationLookupDataId == '') ? validationCreator()
        : validationCreator(false, state.validationMessages.productVariationLookupDataId);
    }
  },*/
  width: {
    validator: (val, state) => {
      let {selectedProduct, selectedFrameFeature, awning} = state;
      let {fieldConfiguration} = awning;

      if(fieldConfiguration){
        return {isValid: true, message: ''};
      }

      if(!selectedProduct){
        return {isValid: false, message: 'Error - no selected product'};
      }
      let maxWidth = (selectedFrameFeature && selectedFrameFeature.maxWidth) ? selectedFrameFeature.maxWidth : selectedProduct.maxWidth;
      let minWidth = (selectedFrameFeature && selectedFrameFeature.minWidth) ? selectedFrameFeature.minWidth : selectedProduct.minWidth;
      let message = `Please enter width between: ${minWidth} and ${maxWidth}. For awnings > ${maxWidth}, please contact Aluxor or refer to the hardcopy price list.`;
      if (!val || val == '') {
        return {isValid: false, message: `Width cannot be empty`};
      }
      let intVal = parseInt(val);
      if (intVal < minWidth || intVal > maxWidth) {
        return {
          isValid: false, message
        };
      }
      if(state.oversquare && isSelectaPitch(state.awning)){
        return {isValid: true, message: 'Dimensions are oversquare, and cannot be combined with SelectaPitch'};
      }
      if (intVal >= selectedProduct.minWidth && intVal <= maxWidth) {
        return {isValid: true, message: ''};
      }
      return {isValid: false, message};
    }
  },
  projection: {
    validator: (val, state) => {
      let {selectedFrameFeature, selectedProduct, awning} = state;
      if(!selectedProduct){
        return validationCreator();
      }
      let maxProjection = (selectedFrameFeature && selectedFrameFeature.maxProjection) ? selectedFrameFeature.maxProjection : selectedProduct.maxProjection;
      let minProjection = (selectedFrameFeature && selectedFrameFeature.minProjection) ? selectedFrameFeature.minProjection : selectedProduct.minProjection;
      let message = IsNullOrUndefined(awning.steerAroundDimensions) ? `Please enter projection between: ${minProjection} and ${maxProjection}. For awnings > ${maxProjection}, please contact Aluxor or refer to the hardcopy price list.` :
        `Total projection (sum of track lengths) must be between ${minProjection} and ${maxProjection}. For awnings > ${maxProjection}, please contact Aluxor or refer to the hardcopy price list.`;


      if(state.projections.length === 0 && (!selectedProduct || (selectedProduct && selectedProduct.fixedProjection))){
        return validationCreator();
      }

      if(selectedProduct && !selectedProduct.fixedProjection) {
        if (isNullEmptyOrWhitespace(val)) {
          return validationCreator(false, 'Projection cannot be empty');
        }
        let intVal = parseInt(val);
        if (intVal < minProjection || intVal > maxProjection) {
          return validationCreator(false, message);
        }
      }

      if(selectedFrameFeature && selectedFrameFeature.maxProjection){
        return val != null && val > 0 && val <= selectedFrameFeature.maxProjection ? validationCreator()
          : validationCreator(false, `Selected frame feature has a max projection of ${selectedFrameFeature.maxProjection}`);
      }

      return ((val == null || val == 0) && state.projections.length > 0) ? validationCreator(false, 'Please select a projection') : validationCreator();
    }
  },
  driveMechanismId: {
    validator: (val, state) => {
      return ((val == null || val == 0) && state.driveMechanisms.length > 0) ? validationCreator(false, 'Please select a drive mechanism') : validationCreator()
    }
  },
  crankHandleId: {
    validator: (val, state) => {
      return ((val == null || val == 0) && state.crankHandles.length > 0) ?
        validationCreator(false, 'Please select a crank handle mechanism') :
        validationCreator();
    }
  },
  sideOfOperation: {
    validator: (val, state) => {
      return (IsNullOrUndefined(val) && !IsNullOrUndefined(state.awning) && IsNullOrUndefined(state.awning.fieldConfiguration)) ?
        validationCreator(false, 'Please select a side of operation') :
        validationCreator();
    }
  },
  remote: {
    validator: (val, state) => {
      return ((val == null || val == 0) && state.remotes.length > 0) ?
        validationCreator(false, 'Please select a remote') :
        validationCreator();
    }
  },
  frameColourId: {
    validator: colourValidation
  },
  pelmetColourId: {
    validator: (val, state) => state.awning.includePelmet ? colourValidation(val,state) : validationCreator()
  },
  flashingColourId: {
    validator: (val, state) => state.awning.flashingType > 0 ? colourValidation(val,state) : validationCreator()
  },
  /*'awningFlashing.height': {
    validator: (val, state) => {
      console.log(val);
      return ((val == null) && state.awning.flashingType == 1) ?
        validationCreator(false, 'Please select a flashing depth') :
        validationCreator();
    }
  },*/
  fabricSuppliedBy: {
    validator: (val, state) => {
      return (val == null) ?
        validationCreator(false, 'Please make a fabric choice') :
        validationCreator();
    }
  },
  fabricBrandId: {
    validator: (val, state, props) => {
      return ((val == null || val == 0) && AwningRequiresFabric(props.order, state.awning)) ?
        validationCreator(false, 'Please select a fabric brand') :
        validationCreator();
    }
  },
  fabricId: {
    validator: (val, state, props) => {
      return ((val == null || val == 0) && AwningRequiresFabric(props.order, state.awning)) ?
        validationCreator(false, 'Please select a fabric') :
        validationCreator();
    }
  },
  fabricValance: {
    validator: (val, state, props) => {
      return (val == null &&
          AwningRequiresFabric(props.order, state.awning) && !isVarioVolant(state.awning) && !IsRoofSystem(state.awning) &&
        ((state.selectedProduct && state.selectedProduct.includeValance == 1) ||
          ((state.selectedProduct && state.selectedProduct.includeValance == 2) && state.oversquare))) ?
        validationCreator(false, 'Please select a fabric valance') :
        validationCreator();
    }
  },
  mountingBracketId: {
    validator: (val, state) => {
      return ((val == null || val == 0)) ?
        validationCreator(false, 'Please select a mounting bracket') :
        validationCreator();
    }
  },
  additionalMountingBrackets: {
    validator: (val, state) => {
      let intVal = parseInt(val);
      if (intVal < 0 || (!IsNullOrUndefined(state.awning.additionalMountingBrackets) && state.awning.additionalMountingBrackets.toString().trim() === '')) {
        return validationCreator(false, 'Please enter a value of zero or greater for additional mounting brackets');
      }
      let requireAdditionalBracketCount = RequireAdditionalBracketCount(state.mountingBrackets, state.awning);

      if (intVal <= 0 && state.selectedProduct &&
          !state.selectedProduct.hasAdditionalMountingBrackets && (state.awning.productLookupDataId > 0 && state.awning.numberOfMountingBracket <= 0)
          && requireAdditionalBracketCount
      ) {
        return validationCreator(false, 'Please enter the number of mounting brackets required');
      }

      return validationCreator();
    }
  },
  trimBiasColourId: {
    validator: (val, state) => {
      return ((val == null || val == 0) && state.trimBiasColours.length > 0) ?
        validationCreator(false, 'Please select a trim bias colour') :
        validationCreator();
    }
  },
  drop: {
    validator: (val, state) => {
      let {selectedFrameFeature} = state;
      let drop = parseInt(val);

      return state.drops.length == 0 || !selectedFrameFeature || selectedFrameFeature.maxDrop == null || drop <= selectedFrameFeature.maxDrop ? validationCreator() :
        validationCreator(false, `Please enter a drop up to ${selectedFrameFeature.maxDrop}`);
    }
  },
  frameFeatureDriveMechanismId: {
    validator: (val, state) => {
      return ((val == null || val == '') && state.frameFeatureDriveMechanisms.length>0) ?
        validationCreator(false, 'Please select a frame feature drive mechanism') :
        validationCreator();
    }
  },
  frameFeatureSideOfOperation: {
    validator: (val, state) => {
      return ((val == null || val == '') && state.frameFeaturSideOfOperations.length>0) ?
        validationCreator(false, 'Please select a frame feature operating side') :
        validationCreator();
    }
  },
  frameFeatureFabricBrandId: {
    validator: (val, state, props) => {
      return ((val == null || val == '') && state.frameFeatureFabricBrands.length>0 && AwningRequiresFabric(props.order, state.awning)) ?
        validationCreator(false, 'Please select a frame feature fabric brand') :
        validationCreator();
    }
  },
  frameFeatureFabricId: {
    validator: (val, state, props) => {
      return ((val == null || val == '') && state.frameFeatureFabrics.length>0 && AwningRequiresFabric(props.order, state.awning)) ?
        validationCreator(false, 'Please select a frame feature fabric') :
        validationCreator();
    }
  },
  motionSensorColour: {
    validator: (val, state) => {
      return ((val == null || val == '') && isMotionSensor(state.awning)) ?
        validationCreator(false, 'Please select a motion sensor colour') :
        validationCreator();
    }
  },
  automationCableId: {
    validator: (val, state) => {
      return ((val == null || val == '') && (state.automationCables.length>0 && state.awning.driveMechanismId != null && !isManual(state.awning))) ?
        validationCreator(false, 'Please select an automation cable') :
        validationCreator();
    }
  },
  additionalCradles: {
    validator: (val, state) => {
      let cradles = parseInt(val);

      return (state.awning.hasAdditionalCradles && cradles <= 0)?
        validationCreator(false, 'Extra cradles must be a value of zero or greater') :
        validationCreator();
    }
  },
  frameFeatureCrankHandleId: {
    validator: (val, state) => {
      return (isNullEmptyOrWhitespace(val) && (state.frameFeatureCrankHandles.length > 0 
          || state.awning.frameFeature === FRAME_FEATURE_SELECTAPITCH) && state.awning.driveMechanismId !== DRIVE_MECHANISM_MANUAL) ?
        validationCreator(false, 'Please select a frame feature crank handle') :
        validationCreator();
    }
  },
  sections: {
    validator: (val, state) => {
      return ((val == null || val == '') && state.sectionOptions.length>0) ?
        validationCreator(false, 'Please select the number of sections') :
        validationCreator();
    }
  },
  installationBracketId: {
    validator: (val, state) => {
      return ((val == null) && state.installationBrackets.length > 0) ?
        validationCreator(false, 'Please a installation bracket') :
        validationCreator();
    }
  },

  includePelmet: {
    validator: (val, state) => {
      return (val == null) ?
        validationCreator(false, 'Please a make a pelmet choice') :
        validationCreator();
    }
  },
  flashingType: {
    validator: (val, state) => {
      if(state.flashingOptions.length === 0){
        return validationCreator();
      }

      return (val == null) ?
        validationCreator(false, 'Please a make a flashing choice') :
        validationCreator();
    }
  },
  desiredRemoteColour: {
    validator: (val, state) => {
      return isNullEmptyOrWhitespace(val) && state.remoteColours.length > 0 && IsNullOrUndefined(state.awning.remoteParentAwningNumber) ?
        validationCreator(false, 'Please a make a remote colour choice') :
        validationCreator();
    }
  },
  motorId: {
    validator: (val, state) => {
      return isNullEmptyOrWhitespace(val) && state.motors.length > 0 ?
        validationCreator(false, 'Please a make a motor selection') :
        validationCreator();
    }
  },
  mountingBracketLength: {
    validator: (val, state) => {
      if(state.awning.mountingBracketId===MOUNTING_EXTENSION){
        return (isNullEmptyOrWhitespace(val) || parseInt(val) < 60 || parseInt(val) > 300)  ?
            validationCreator(false, 'Please specify a mounting height length between 60-300mm') :
            validationCreator();
      }
      if(state.awning.mountingBracketId===MOUNTING_BACKPOSTS){
        return (isNullEmptyOrWhitespace(val) || parseInt(val) < 500 || parseInt(val) > 3500)  ?
            validationCreator(false, 'Please specify a back post height between 500-3500mm') :
            validationCreator();
      }

      return validationCreator();
    }
  },
  productLookupDataId: {
    validator: (val, state) => {
      return (isNullEmptyOrWhitespace(val) || parseInt(val) <= 0) ?
        validationCreator(false, 'Product combination of width and projection not valid') :
        validationCreator();
    }
  },
  installationBracketLength: {
    validator: (val, state) => {
      let {awning, fieldInstallationOptions} = state;
      return (isNullEmptyOrWhitespace(val) || parseInt(val) < 500 || parseInt(val) > 3500) &&
      (hasInstallationPosts(awning, fieldInstallationOptions)) ?
          validationCreator(false, 'Please specify a installation post height, between 500-3500mm') :
          validationCreator();
    }
  },
  includeRainCover: {
    validator: (val, state) => {
      return (IsNullOrUndefined(val) && isSDorSM(state.awning)) ?
        validationCreator(false, 'Please select a rain cover option') :
        validationCreator();
    }
  },
  fieldConfigurationOption: {
    validator: (val, state) => {
      return state.fieldConfigurationOptions.length > 0 &&
      !IsNullOrUndefined(state.awning) &&
      (IsNullOrUndefined(state.awning.fieldConfiguration) ||
          (!IsNullOrUndefined(state.awning.fieldConfiguration) && isNullEmptyOrWhitespace(state.awning.fieldConfiguration.fieldConfigurationOptionId))) ?
          validationCreator(false, 'Please select a field option') :
          validationCreator();
    }
  },
  middleArmSide: {
    validator: (val, state) => {
      return (IsNullOrUndefined(val) && state.awning.numberOfArms === 3 && state.awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM) ?
          validationCreator(false, 'Please select a middle arm side option') :
          validationCreator();
    }
  },
  additionalImRollerCount: {
    validator: (val, state) => {
      return (IsNullOrUndefined(val) && state.awning.intermediateRoller > 0) ?
          validationCreator(false, 'Please select an additional IM roller option') :
          validationCreator();
    }
  },
  includeWedges: {
    validator: (val, state) => {
      return (IsNullOrUndefined(val) && CanHaveWedges(state.awning) && IsNullOrUndefined(state.awning.includeWedges)) ?
          validationCreator(false, 'Please make a wedges selection') :
          validationCreator();
    }
  },
  includeStabiliserPoles: {
    validator: (val, state) => {
      return (IsNullOrUndefined(val) && state.awning.automationSensorType == null && state.awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM &&
          state.awning.productLookupDataId > 0) ?
          validationCreator(false, 'Please make a stabiliser poles selection') :
          validationCreator();
    }
  },
  pelmetType: {
    validator: (val, state) => {
      return (IsNullOrUndefined(val) && IsPelmetTypeVisible(state.organisation, state.awning)) ?
          validationCreator(false, 'Please make a pelmet type selection') :
          validationCreator();
    }
  }
};