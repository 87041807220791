import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {apiRequest} from "../../utils/request";
import TextBoxControl from '../common/TextBoxControl';
import SelectControl from '../common/SelectControl';
import CheckBoxControl from '../common/CheckBoxControl';
import ColourSelectorControl from '../common/ColourSelectorControl';
import { isEqual, differenceBy, merge, keys, pullAllWith, union } from 'lodash';
import {
  isSelectaPitch,
  isVarioVolant,
  DATE_SHORT_FORMAT,
  ORDER_STATUS_QUOTE,
  isFrameAdjusters,
  isManualFrameFeature,
  isMotionSensor,
  SensorTypes,
  isHardwired,
  isRemote,
  JoinerOptionsRequiredJoiner,
  ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED,
  edit_order_can_deselect_pelmet_amendment_requested,
  edit_order_can_deselect_pelmet_quote,
  PRODUCTID_TOGA,
  isGeigerRemote,
  PRODUCTID_OLYMPUS,
  FLASHINGTYPE_WallSeal,
  isNullEmptyOrWhitespace,
  DRIVE_MECHANISM_MotorisedPrepOnly,
  FRAME_FEATURE_SELECTAPITCH,
  PRODUCTGROUPID_CONSERVATORY,
  MountingBracketName,
  IsNullOrUndefined,
  MOUNTING_EXTENSION,
  Aux,
  REMOTE_MULTICHANNEL,
  MOUNTING_BACKPOSTS,
  isSDSM,
  isSDorSM,
  hasInstallationPosts,
  AwningRequiresFabric,
  isScSz,
  isDualMount,
  isMotor,
  PRODUCTGROUPID_FOLDING_ARM,
  PRODUCTID_DS,
  RequireAdditionalBracketCount,
  OrganisationOrderAwningSupplyOwnMotor,
  PRODUCTID_SD,
  IsConservatoryOrRoofSystem,
  TextInputParseInt,
  TextInputParseBool,
  IsRoofSystem,
  CalculateFinishedProjection,
  FLASHINGTYPE_Sintesi300,
  FLASHINGTYPE_Sintesi500,
  SM_FASCIA_BRACKET_ID,
  CanHaveWedges,
  PRODUCTGROUPID_ROOFING_SYSTEMS,
  PRODUCTID_S1,
  PRODUCTID_ECO,
  IsConservatory,
  PELMET_TYPE_Aluminium,
  PELMET_TYPE_Colorbond, IsPelmetTypeVisible
} from '../../constants';
import {ValidationRules} from "./AwningValidators";
import moment from 'moment';
import RadioControlGroup from '../common/RadioControlGroup';
import TextViewControl from "../common/TextViewControl";
import AwningAttachments from "./AwningAttachments";
import ProductResourcesPage from "../portal/ProductResourcesPage";
import {Modal, ModalBody} from "reactstrap";
import ModalHeader from "react-bootstrap/es/ModalHeader";
import {some} from 'lodash';
import AwningFieldsControl, {
  GetFieldConfigValidationMessages,
} from "./AwningFieldsControl";
import AwningSADimensionControl, {GetSADimensionValidationMessages} from "./AwningSADimensionControl";
import {toastr} from 'react-redux-toastr';
import DiagramSD from "../awningdiagrams/DiagramSD";
import ProductInformationControl, {GetProductResourceTags} from "./ProductInformationControl";
import ReactJson from "react-json-view";
import {GetSintesiValidationMessages} from "./SintesiValidators";
import DiagramST from "../awningdiagrams/DiagramST";

const Messages = {
  AwningWidthJoiners: 'Awning will be dispatched in 2 sections unless otherwise requested',
  RemoteOther: 'Surcharge may apply for certain hand held remotes selected',
};

export const OperatingSides = [{value: 'Left', label: 'Left'}, {value: 'Right', label: 'Right'}];
export const YesOrNo = [{value: true, label: 'Yes'}, {value: false, label: 'No'}];
const Yes = [{value: true, label: 'Yes'}];
const No = [{value: false, label: 'No'}];
const MotionSensorColours = [{value: 'White', label: 'White'}, {value: 'Cream', label: 'Cream'}, {value: 'Black', label: 'Black'}];
const MocoTypes= [{value: 3, label: '3 Pin plug'},{value: 1, label: '1AC MOCO'}, {value: 2, label: '4AC MOCO'}, {value:4, label: 'Hirschmann set'}];
const SintesiFreeStandingTypes= [{value: 0, label: 'No'},{value: 1, label: 'Single Pitch'}, {value: 2, label: 'Double Pitch'}];
const SintesiInfillPanelMaterial= [{value: 1, label: 'Metal'},{value: 2, label: 'PVC'}, {value: 3, label: 'Other - please specify'}];
const SintesiVerticalScreenSides = [{value: 1, label: 'Left'},{value: 2, label: 'Right'}, {value: 3, label: 'Back'}, {value: 4, label: 'Front'}];
const SintesiLightingOptions = [{value: 1, label: 'LED on Windbreaks'},{value: 2, label: 'Headbox Downlights'}, {value: 3, label: 'Post Lights'}];
const SintesiVerticalScreenDriveOperations = [{value: 1, label: 'Manual'},{value: 0, label: 'Motorised'}];
const SintesAdditionalWindbreaks = [{value: 0, label: 'None'},{value: 1, label: 'One'}, {value: 2, label: 'Two'}];
const SintesAdditionalSidePosts = [{value: 0, label: 'None'},{value: 1, label: 'One'}];
const SintesAdditionalFrontPosts = [...SintesAdditionalSidePosts];
const SintesAdditionalBackPosts = [...SintesAdditionalSidePosts];
const SintesHeaters = [{value: 0, label: 'None'},{value: 1, label: 'One'}, {value: 2, label: 'Two'}];
const PelmetTypes = [{value: PELMET_TYPE_Aluminium, label: 'Aluminium'}, {value: PELMET_TYPE_Colorbond, label: 'Colorbond'}];
export const MiddleArmOptions = [{value: 1, label: 'Left'}, {value: 2, label: 'Right'},{value: 0, label: 'ALX to select'}];
const AdditionalImRollers = [{value: 0, label: 'None'},{value: 1, label: 'One'}, {value: 2, label: 'Two'}];

export function getDefaultAwning(awning, originalAwning = null, order=null, selectedProduct=null) {  
  let start = getFrameFeatureDefaults(awning, null, originalAwning, order, selectedProduct);
  return {
    ...start,
    driveMechanismId: null,
    crankHandleId: null,
    fabricBrandId: null,
    fabricId: null,
    installationBracketId: null,
    installationBracketTypeId: null,
    desiredRemoteColour: null,
    remote: null,
    additionalMountingBrackets: 0,
    middleArmSide: 0,
    additionalImRollerCount: null,
    includeWedges: false,
    includeStabiliserPoles: false,
    includeLed: false,
    includeRainCover: false,
    pelmetType: null,
  };
}

function getFrameFeatureDefaults(awning, frameFeature = null, originalAwning = null, order=null, selectedProduct=null){  
  let returnValue = {
      ...awning,
      frameFeature: IsNullOrUndefined(frameFeature) ? null : frameFeature,
      drop: null,
      frameFeatureCrankHandleId: null,
      frameFeatureDriveMechanismId: null,
      frameFeatureSideOfOperation: null,
      frameFeatureFabricBrandId: null,
      frameFeatureFabricId: null,
      frameFeatureFieldType: null,
      frameFeatureSteerAroundType: null,
      fabricValance: null,
      trimBiasColourId: null,
      fieldConfiguration: null,
      steerAroundDimensions: null,
  };
  
  if(originalAwning !== null){    
    if(AllowedValance(returnValue, order, selectedProduct)){
      returnValue = {
        ...returnValue,
        fabricValance: originalAwning.fabricValance,
        trimBiasColourId: originalAwning.trimBiasColourId,
      }
    }
  }
  
  return returnValue;
}

function AllowedValance(awning, order = null, selectedProduct = null){
  
  let requiresFabric = order !== null ? AwningRequiresFabric(order, awning) : true;
  let hasValance = selectedProduct !== null ? IncludeValance(selectedProduct, awning) : true;
  
  return requiresFabric && !isVarioVolant(awning) && !IsRoofSystem(awning) && hasValance;
}

function IncludeValance(selectedProduct, awning) {
  return ((selectedProduct && selectedProduct.includeValance === 1) ||
      (selectedProduct && selectedProduct.includeValance === 2 && awning.oversquare))
}

const newSintesi = () => {
  return {
    backHeight: null,
    frontHeight: null,
    fallBackToFront: null,
    frontDifferential: null,
    horizontalProjection: null,
    finishedAngle: null,
    freeStandingType: 0,
    isQuatroFinish: false,
    windbreakColourId: null,
    windbreakCustomColourId: null,
    hasFrontGutter: null,
    hasInfillPanelLeft: null,
    hasInfillPanelRight: null,
    hasInfillPanelBack: null,
    infillPanelMaterial: null,
    additionalWindbreakCount: 0,
    additionalSidePostCount: 0,
    additionalFrontPostCount: 0,
    verticalScreenIsManual: null,
    verticalScreenFabricBrandId: null,
    verticalScreenFabricId: null,
    hasLedOnWindbreaks: null,
    hasHeadboxDownlights: null,
    hasPostlights: null,
    selectedVerticalScreenOptions: [],
    verticalScreens: [],
    additionalBackPostCount: 0,
    heaterCount: 0,
    hasHeaterExtension: false,
    hasAudioSet: false,
  };
};

const newSintesiVerticalScreen = (sintesiModel) => {
  return {
    ...sintesiModel,    
    verticalScreenIsManual: null,
    verticalScreenFabricBrandId: null,
    verticalScreenFabricId: null,
    verticalScreens: [],
  };
};

class AwningView extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      productGroups: [],
      awning: props.awning,
      products: [],
      projections: [],
      driveMechanisms: [],
      crankHandles: [],
      remotes: [],
      coloursStandard: [],
      coloursCustom: [],
      pelmetOptions: YesOrNo,
      flashingOptions: [],
      fabricBrands: [],
      fabrics: [],
      valances: [],
      fabricSuppliers: [],
      mountingBrackets: [],
      sideOfOperations: OperatingSides,
      frameFeaturSideOfOperations: [],
      validationMessages: {},
      sintesiValidationMessages: {},
      surchargeMessages: new Map(),
      messages: new Set(),
      selectedProduct: null,
      organisation: IsNullOrUndefined(props.organisation) ? {} : props.organisation,
      order: props.order,
      trimBiasColours: [],
      frameFeatures: [],
      selectedFrameFeature: null,
      frameFeatureDriveMechanisms: [],
      drops: [],
      frameFeatureFabricBrands: [],
      frameFeatureFabrics: [],
      frameFeatureCrankHandles: [],
      motionSensorColours: MotionSensorColours,
      automationCables: [],
      installationBrackets: [],
      ledLightings: [],
      mocoTypes: MocoTypes,
      joinerOptions: [],
      sectionOptions: [],
      defaultSections: null,
      oversquare: false,
      installationBracketTypes: [],
      isProductResourcesOpen: false,
      remoteColours: [],
      motors: [],
      frameFeatureSteerAroundTypes: [],
      frameFeatureFieldTypes: [],
      fieldInstallationOptions: [],
      fieldConfigurationOptions: [],
      sintesiVerticalFabricBrands: [],
      sintesiVerticalFabrics: [],
    };

    this.fetchProductGroups = this.fetchProductGroups.bind(this);
    this.fetchProducts = this.fetchProducts.bind(this);
    this.handlFieldChange = this.handlFieldChange.bind(this);
    this.raiseAwningChanged = this.raiseAwningChanged.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.fetchCrankHandles = this.fetchCrankHandles.bind(this);
    this.handleColourChange = this.handleColourChange.bind(this);
    this.fetchFabrics = this.fetchFabrics.bind(this);
    this.fetchFabricBrands = this.fetchFabricBrands.bind(this);
    this.fetchMountingBrackets = this.fetchMountingBrackets.bind(this);
    this.fetchProductLookup = this.fetchProductLookup.bind(this);
    this.isValid = this.isValid.bind(this);
    this.showAwningMessages = this.showAwningMessages.bind(this);
    this.fetchTrimBiasColours = this.fetchTrimBiasColours.bind(this);
    this.fetchFrameFeatures = this.fetchFrameFeatures.bind(this);
    this.fetchFrameFeatureDriveMechanisms = this.fetchFrameFeatureDriveMechanisms.bind(this);
    this.fetchFrameFeatureOperatingSides = this.fetchFrameFeatureOperatingSides.bind(this);
    this.fetchDrops = this.fetchDrops.bind(this);
    this.fetchFrameFeatureFabrics = this.fetchFrameFeatureFabrics.bind(this);
    this.fetchFrameFeatureFabricBrands = this.fetchFrameFeatureFabricBrands.bind(this);
    this.fetchAutomationCables = this.fetchAutomationCables.bind(this);
    this.fetchLedLighting = this.fetchLedLighting.bind(this);
    this.fetchFrameFeatureCrankHandles = this.fetchFrameFeatureCrankHandles.bind(this);
    this.handlFieldChangeWithAwningId = this.handlFieldChangeWithAwningId.bind(this);
    this.fetchJoinerOptiosn = this.fetchJoinerOptiosn.bind(this);
    this.fetchProductProjections = this.fetchProductProjections.bind(this);
    this.handleRadioChangeWithAwningId = this.handleRadioChangeWithAwningId.bind(this);
    this.fetchInstallationBrackets = this.fetchInstallationBrackets.bind(this);
    this.fetchDriveMechanisms = this.fetchDriveMechanisms.bind(this);
    this.fetchFabricSuppliers = this.fetchFabricSuppliers.bind(this);
    this.setFabricSameAsAwning = this.setFabricSameAsAwning.bind(this);
    this.setColourSameAsAwning = this.setColourSameAsAwning.bind(this);
    this.setDriveSameAsAwning = this.setDriveSameAsAwning.bind(this);
    this.setFlashingOptions = this.setFlashingOptions.bind(this);
    this.fetchRemoteColours = this.fetchRemoteColours.bind(this);
    this.fetchFieldTypes = this.fetchFieldTypes.bind(this);
    this.fetchSteerAroundTypes = this.fetchSteerAroundTypes.bind(this);
    this.fetchFieldConfigurationOptions = this.fetchFieldConfigurationOptions.bind(this);
    this.handleFrameFeatureFieldValueUpdate = this.handleFrameFeatureFieldValueUpdate.bind(this);
    this.frameFeaturesControl = this.frameFeaturesControl.bind(this);
    this.handleSteerAroundDimensionUpdate = this.handleSteerAroundDimensionUpdate.bind(this);
    this.fetchFieldInstallationOptions = this.fetchFieldInstallationOptions.bind(this);
    this.handleFieldConfigurationOptionUpdate = this.handleFieldConfigurationOptionUpdate.bind(this);
    this.getRemoteParentOptions = this.getRemoteParentOptions.bind(this);
    this.handleSintesiFieldUpdate = this.handleSintesiFieldUpdate.bind(this);
    this.handleSintesiFieldUpdateWithAwningId = this.handleSintesiFieldUpdateWithAwningId.bind(this);
    this.handleSintesiColourUpdate = this.handleSintesiColourUpdate.bind(this);
    this.fetchSintesiVerticalFabrics = this.fetchSintesiVerticalFabrics.bind(this);
    this.handleVerticalScreenSidesUpdate = this.handleVerticalScreenSidesUpdate.bind(this);
    this.handleVerticalScreenFieldUpdate = this.handleVerticalScreenFieldUpdate.bind(this);
    this.fetchInstallationBracketTypes = this.fetchInstallationBracketTypes.bind(this);
  }

  raiseAwningChanged() {
    this.props.onChange(this.state.awning);
    this.showAwningMessages();
  }

  showAwningMessages() {
    let {awning, messages, surchargeMessages} = this.state;
    let {siteSettings} = this.props;

    if (awning.width > 7000) {
      messages.add(Messages.AwningWidthJoiners);
    } else {
      messages.delete(Messages.AwningWidthJoiners);
    }

    if (awning.remote == 4) {
      messages.add(Messages.RemoteOther);
    } else {
      messages.delete(Messages.RemoteOther);
    }

    messages.delete(siteSettings.ProductPriceListMessage);
    messages.delete(siteSettings.ProductSintesiPriceListMessage);
    
    if(awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM || awning.productGroupId === PRODUCTGROUPID_CONSERVATORY){
      messages.add(siteSettings.ProductPriceListMessage)
    } else if(awning.productGroupId === PRODUCTGROUPID_ROOFING_SYSTEMS){
      messages.add(siteSettings.ProductSintesiPriceListMessage);
    }   

    let addColourSurcharge = (name) => {
      let field = `${name}CustomColourId`;
      if (awning[field] != null) {
        surchargeMessages.set(field, `Surcharge applies as you have ordered a custom ${name} colour`)
      } else {
        surchargeMessages.delete(field);
      }
    };

    addColourSurcharge('frame');
    addColourSurcharge('pelmet');
    addColourSurcharge('flashing');

    this.setState({messages: new Set(messages), surchargeMessages: new Map(surchargeMessages)}, () => {
      this.props.onMessagesChange(this.state.awning.id, {
        messages: this.state.messages,
        validationMessages: this.state.validationMessages,
        surchargeMessages: this.state.surchargeMessages
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    this.fetchProductGroups(prevProps, prevState);
    this.fetchCrankHandles(prevState);
    this.fetchProducts(prevState);
    this.fetchMountingBrackets(prevState);
    this.fetchProductLookup(prevState);
    this.fetchTrimBiasColours(prevState);
    this.fetchFabricBrands(prevState);
    this.fetchFabrics(prevState);
    this.fetchFrameFeatureFabricBrands(prevState);
    this.fetchFrameFeatureFabrics(prevState);
    this.fetchFrameFeatures(prevState);
    this.fetchFrameFeatureDriveMechanisms(prevState);
    this.fetchFrameFeatureOperatingSides(prevState);
    this.fetchDrops(prevState);
    this.fetchSteerAroundTypes(prevState);
    this.fetchFieldTypes(prevState);
    this.fetchAutomationCables(prevState);
    this.fetchLedLighting(prevState);
    this.fetchFrameFeatureCrankHandles(prevState);
    this.fetchJoinerOptiosn(prevState);
    this.fetchProductProjections(prevState);
    this.fetchInstallationBrackets(prevState);
    this.fetchDriveMechanisms(prevState);
    this.fetchFabricSuppliers(prevState);
    this.fetchRemoteColours(prevState);
    this.fetchMotors(prevState);
    this.fetchFieldInstallationOptions(prevState);


    if (this.state.awning.driveMechanismId && prevState.awning.driveMechanismId !== this.state.awning.driveMechanismId ||
        this.state.awning.productId && prevState.awning.productId !== this.state.awning.productId
    ) {
      this.fetchLookupData(`/api/remotes?driveMechanismId=${this.state.awning.driveMechanismId}&productId=${this.state.awning.productId}`, 'remotes', 'remote');
    }
    if (this.state.awning.productId && prevState.awning.productId !== this.state.awning.productId && !this.state.selectedProduct.pdfOrderOnly) {
      if (this.state.awning.productId != null) {
        this.fetchLookupData(`/api/colours/${this.state.awning.productId}/frame`, 'coloursStandard');
      } else {
        this.setState({coloursStandard: []});
      }
    }

    if (this.state.awning.productId && prevState.awning.productId !== this.state.awning.productId) {
      this.setFlashingOptions();
    }

    this.fetchInstallationBracketTypes(prevState);

    this.fetchFieldConfigurationOptions(prevState);
    if (this.state.awning.sintesi) {     
      let {sintesi} = this.state.awning; 
      
      this.handleVerticalScreenSidesUpdate(prevState);
      this.fetchSintesiVerticalFabrics(prevState);

      let hasPosts = hasInstallationPosts(this.state.awning, this.state.fieldInstallationOptions);
      let hadPosts = hasInstallationPosts(prevState.awning, prevState.fieldInstallationOptions);

      if ( IsNullOrUndefined(prevState.awning.sintesi)
          || prevState.awning.sintesi.horizontalProjection !== sintesi.horizontalProjection
          || prevState.awning.sintesi.frontHeight !== sintesi.frontHeight
          || prevState.awning.sintesi.backHeight !== sintesi.backHeight
          || prevState.awning.sintesi.fallBackToFront !== sintesi.fallBackToFront
          || hasPosts !== hadPosts
      ) {

        let {finishedProjection, finishedAngle} = CalculateFinishedProjection(sintesi.horizontalProjection, sintesi.backHeight,
            sintesi.frontHeight, sintesi.fallBackToFront, hasPosts);        
        
        setTimeout(() => {
          this.setState({
            awning: {
              ...this.state.awning, 
              projection: finishedProjection,
              sintesi: {
                ...sintesi,                
                finishedAngle,
              }
            }
          }, ()=>{
            this.raiseAwningChanged();
          });  
        }, 100);
      }
    }
  }

  handleVerticalScreenSidesUpdate(prevState) {
    let {awning,} = this.state;
    let sintesi = awning.sintesi;
    if (IsNullOrUndefined(sintesi)) {
      return;
    }

    if (!IsNullOrUndefined(sintesi) && IsNullOrUndefined(prevState.awning.sintesi)) {
      return;
    }

    // if the prevState options are different, then add/remove from sintesi.verticalScreens
    if (JSON.stringify(sintesi.selectedVerticalScreenOptions) !== JSON.stringify(prevState.awning.sintesi.selectedVerticalScreenOptions)) {
      let toAdd = differenceBy(sintesi.selectedVerticalScreenOptions, prevState.awning.sintesi.selectedVerticalScreenOptions);
      let toRemove = differenceBy(prevState.awning.sintesi.selectedVerticalScreenOptions, sintesi.selectedVerticalScreenOptions);

      sintesi.verticalScreens = pullAllWith(sintesi.verticalScreens, toRemove, (orgi, rem) => {
        return orgi.sideType === rem.value;
      });

      sintesi.verticalScreens = union(sintesi.verticalScreens, toAdd.map(x => {
        return {
          id: -1,
          sideType: x.value,
          sideOfOperation: null,
          width: null,
          projection: null,
        };
      }));

      this.setState({
        awning: {
          ...awning,
          sintesi: sintesi.verticalScreens.length === 0 ? newSintesiVerticalScreen(sintesi) : {...sintesi, verticalScreens: sintesi.verticalScreens},
        }
      }, this.raiseAwningChanged)
    }
  }

  handleVerticalScreenFieldUpdate(e, validationFieldName, valueAction, splitShift = 0) {
    if (IsNullOrUndefined(e) || IsNullOrUndefined(e.target)) {
      return;
    }

    //name={`${awning.id}_sintesi_verticalScreens_${v.sideType}_sideOfOperation`}
    let {awning} = this.state;
    let {sintesi} = awning;
    let {verticalScreens} = sintesi;

    let s = e.target.name.split("_");
    let sideType = parseInt(s[splitShift + 1]);
    let fieldName = s[splitShift + 2];
    let value = e.target.value;

    let updatedVerticalScreens = verticalScreens.map(x => {
      let returnVal = x;
      if (returnVal.sideType === sideType) {
        returnVal[fieldName] = valueAction(value);
      }
      return returnVal;
    });
    let updatedSintesi = {...sintesi, verticalScreens: updatedVerticalScreens};

    let sintesiValidationMessages = GetSintesiValidationMessages(updatedSintesi, this.state, validationFieldName);

    this.setState({
      awning: {
        ...awning,
        sintesi: updatedSintesi,
      },
      sintesiValidationMessages,
    }, this.raiseAwningChanged);

  }
  
  fetchFieldConfigurationOptions(prevState) {
    let {awning, frameFeatureFieldTypes} = this.state;
    let {frameFeatureFieldType, frameFeature} = awning;

    let fieldTypeChanged = !IsNullOrUndefined(prevState) && prevState.awning.frameFeatureFieldType !== frameFeatureFieldType;

    if (!prevState || fieldTypeChanged) {
      let featureValues = frameFeatureFieldTypes.find(x => x.value === frameFeatureFieldType);

      if (featureValues) {
        this.setState({
          awning: {...awning, fieldConfiguration: fieldTypeChanged ? null : awning.fieldConfiguration},
          fieldConfigurationOptions: []
        }, () => {

          apiRequest(`/api/frameFeatures/${frameFeature}/FieldConfigurationOptions?productId=${awning.productId}&numberOfFields=${featureValues.numberOfFields}&numberOfMotors=${featureValues.numberOfMotors}`)
              .then(x => {
                let data = x.data ? x.error ? [] : x.data : [];
                this.setState({
                  awning: {
                    ...awning,
                    fieldConfiguration: fieldTypeChanged ? null : awning.fieldConfiguration
                  },
                  fieldConfigurationOptions: data
                }, ()=>{
                  if(IsRoofSystem(this.state.awning) && data.length === 1 && IsNullOrUndefined(this.state.awning.fieldConfiguration)){                    
                    this.handleFieldConfigurationOptionUpdate({simpleValue: data[0].value});  
                  } else {
                    this.raiseAwningChanged();
                  }
                });
              });
        });
      } else {
        this.setState({
          awning: {...awning, fieldConfiguration: null},
          fieldConfigurationOptions: []
        }, this.raiseAwningChanged);
      }
    }
  }

  fetchInstallationBracketTypes(prevState) {
    let {productId, mountingBracketId} = this.state.awning;

    if (prevState && productId === prevState.awning.productId && mountingBracketId === prevState.awning.mountingBracketId) {
      return;
    }

    this.fetchLookupData(`/api/brackets/installationBracketTypes?productId=${productId}&mountingBracketId=${mountingBracketId}`, 'installationBracketTypes', null, [], (newState) => {
      return newState.installationBracketTypes.length === 1 ? {
        ...newState,
        awning: {...this.state.awning, installationBracketTypeId: newState.installationBracketTypes[0].id}
      } : newState;
    });
  }

  handleFieldConfigurationOptionUpdate(val, e) {
    let {awning, frameFeatureFieldTypes, frameFeatures, fieldConfigurationOptions, selectedProduct} = this.state;
    let {frameFeatureFieldType, frameFeature} = awning;

    let featureValues = frameFeatureFieldTypes.find(x => x.value === frameFeatureFieldType);
    let feature = frameFeatures.find(x => x.id === frameFeature);
    let option = null;
    if (!IsNullOrUndefined(val) && val.simpleValue) {
      option = fieldConfigurationOptions.find(x => x.value === val.simpleValue);
    }

    if (!IsNullOrUndefined(option) && !IsNullOrUndefined(featureValues) && !IsNullOrUndefined(feature)) {

      let fieldConfiguration = {
        numberOfFields: featureValues.numberOfFields,
        numberOfMotors: featureValues.numberOfMotors,
        maxWidth: feature ? feature.maxWidth : selectedProduct.maxWidth,
        minWidth: selectedProduct.minWidth,
        instBracketPermutationId: null,
        fieldConfigurationOptionId: option.data.id,
        fieldConfigurationOption: {...option.data},
        fields: option.data.fields.map(x => {
          return {
            number: x.number,
            width: x.number === 1 && !isNullEmptyOrWhitespace(awning.width) ? awning.width : 0, // use parent width for the first field
            sideOfOperation: null,
            isMotorRequired: x.hasMotor,
            sidesOfOperation: x.sidesOfOperation,
          };
        }),
      };

      this.setState({awning: {...awning, fieldConfiguration}}, this.raiseAwningChanged);
    } else {
      this.setState({awning: {...awning, fieldConfiguration: null}}, this.raiseAwningChanged);
    }
  }

  handleFrameFeatureFieldValueUpdate(awningId, field, width, sideOfOperation) {
    let {awning} = this.state;
    let {fieldConfiguration} = awning;

    this.setState({
      awning: {
        ...awning,
        width: field.number === 1 ? width : awning.width,
        fieldConfiguration: {
          ...fieldConfiguration,
          fields: [...fieldConfiguration.fields.map(x => {
            let retVal = x;
            if (field.number === retVal.number) {
              retVal = {...x, width, sideOfOperation};
            }
            return retVal;
          })]
        }
      }
    }, this.raiseAwningChanged);
  }

  handleSteerAroundDimensionUpdate(dimensions) {
    let {awning} = this.state;

    let projection = null;
    let steerAroundDimensions = null;

    //set the projection from H1, H2, H3
    if (!IsNullOrUndefined(dimensions)) {
      steerAroundDimensions = {...dimensions};
      projection = steerAroundDimensions.h1 + steerAroundDimensions.h2 + steerAroundDimensions.h3;

      let validationMessages = this.getValidationMessages('projection', projection);

      this.setState({
        awning: {
          ...awning,
          steerAroundDimensions,
          projection
        },
        validationMessages
      }, this.raiseAwningChanged);
    }
  }

  handleSintesiFieldUpdate(e, valSelector, modelAction = null) {
    let {awning} = this.state;
    let {sintesi} = awning;

    let updatedValue = valSelector(e);
    let updatedSintesi = {...sintesi};
    
    updatedSintesi[e.target.name] = updatedValue;
    if(modelAction!==null){
      updatedSintesi = modelAction(updatedSintesi);
    }

    let sintesiValidationMessages = GetSintesiValidationMessages(updatedSintesi, this.state, e.target.name);
    
    this.setState({
      awning: {
        ...awning,
        sintesi: updatedSintesi,        
      },
      sintesiValidationMessages,
    }, this.raiseAwningChanged);
  }

  handleSintesiFieldUpdateWithAwningId(e, valSelector, modelAction = null) {
    if (IsNullOrUndefined(e) || IsNullOrUndefined(e.target)) {
      return;
    }
    let newEvent = {...e,
      target: {...e.target,
        name: e.target.name.split('_')[1],        
      }
    };

    this.handleSintesiFieldUpdate(newEvent, valSelector, modelAction);
  }

  handleSintesiColourUpdate(val, fieldName) {
    let {awning} = this.state;
    let {sintesi} = awning;
    let {selectedStandardColourId, selectedCustomColourId} = val;
    
    let updatedSintesi = {...sintesi};
    updatedSintesi[`${fieldName}ColourId`] = selectedStandardColourId;
    updatedSintesi[`${fieldName}CustomColourId`] = selectedCustomColourId;    
    
    let sintesiValidationMessages = GetSintesiValidationMessages(updatedSintesi, this.state, fieldName);
    
    this.setState({
      awning: {
        ...awning,
        sintesi: updatedSintesi,
      },
      sintesiValidationMessages,
    }, this.raiseAwningChanged);
  }


  setFlashingOptions() {
    this.fetchLookupData(`/api/flashings?productId=${this.state.awning.productId}`, 'flashingOptions');
  }

  fetchProductGroups(prevProps, prevState) {
    let {organisationId} = this.state.order;

    if (prevState) {
      if (prevState.order.organisationId === organisationId) {
        return;
      }
    }

    if (organisationId > 0) {
      this.fetchLookupData(`/api/productgroups?organisationId=${organisationId}`, 'productGroups');
    }
  }

  fetchProducts(prevState) {
    let {productGroupId, productId} = this.state.awning;
    let {organisationId} = this.state.order;

    if (prevState) {
      if (productGroupId === prevState.awning.productGroupId
          && productId === prevState.awning.productId
          && prevState.order.organisationId === organisationId
      ) {
        return;
      }
    }

    if (!productGroupId) {
      this.setState({products: [], selectedProduct: null});
      return;
    }

    apiRequest(`/api/products?productGroupId=${productGroupId}&organisationId=${organisationId}`)
        .then(x => {
          let data = x.data ? x.error ? [] : x.data : [];
          let selectedProducts = data.filter(x => x.id === productId);
          let selectedProduct = selectedProducts.length === 1 ? selectedProducts[0] : null;

          let canPelmet =
              ((this.props.order.orderStatus === ORDER_STATUS_QUOTE && this.props.user.hasAccessTag(edit_order_can_deselect_pelmet_quote)) ||
                  (this.props.order.orderStatus === ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED && this.props.user.hasAccessTag(edit_order_can_deselect_pelmet_amendment_requested)))
              && !this.state.awning.productId === PRODUCTID_TOGA;

          let includePelmet = null;

          //Pelmet is set by default if pelmetDecision is false and hasPelmet true AND canView false
          if (!canPelmet && (selectedProduct && selectedProduct.hasPelmet && !selectedProduct.pelmetDecision)) {
            includePelmet = true;
          } else if (canPelmet && (selectedProduct && selectedProduct.hasPelmet && !selectedProduct.pelmetDecision) && this.state.awning.includePelmet == null) {
            includePelmet = true;
          } else if (selectedProduct && !selectedProduct.hasPelmet && !selectedProduct.pelmetDecision) {
            includePelmet = false;
          } else if (this.state.awning.includePelmet != null) {
            includePelmet = this.state.awning.includePelmet;
          }

          let samePelmet = (includePelmet === this.state.awning.includePelmet);
          let allowedValance = IncludeValance(selectedProduct, this.state.awning);

          let isRoofingSystem = IsRoofSystem(this.state.awning);
          let sintesi = isRoofingSystem && this.state.awning.id <= 0 ?
              newSintesi() :
              !isRoofingSystem ? null :
              this.state.awning.id > 0 && IsNullOrUndefined(this.state.awning.sintesi) ?
                  null : this.state.awning.sintesi;
          
          includePelmet = IsNullOrUndefined(sintesi) ? includePelmet : false;
          
          this.setState({
            products: data, selectedProduct, awning: {
              ...this.state.awning,                            
              pelmetColourId: samePelmet ? this.state.awning.pelmetColourId : null,
              pelmetCustomColourId: samePelmet ? this.state.awning.pelmetCustomColourId : null,
              fabricValance: allowedValance ? this.state.awning.fabricValance : null,
              additionalCradles: (selectedProduct && selectedProduct.hasCradles) ? this.state.awning.additionalCradles : 0,
              hasAdditionalCradles: (selectedProduct && selectedProduct.hasCradles) ? this.state.awning.hasAdditionalCradles : false,
              additionalMountingBrackets: (selectedProduct && selectedProduct.hasAdditionalMountingBrackets) || this.state.awning.additionalMountingBrackets > 0 ? this.state.awning.additionalMountingBrackets : 0,
              productArmBracketDeadSpace: selectedProduct ? selectedProduct.armBracketDeadSpace : 0,
              productDescription: selectedProduct ? selectedProduct.description : '',
              sintesi,
              flashingType: IsNullOrUndefined(sintesi) ? this.state.awning.flashingType : 0,
              includePelmet: includePelmet,
              pelmetType: includePelmet ? this.state.awning.pelmetType : null,
            }
          });
        });
  }

  fetchProductProjections(prevState) {

    let {selectedProduct, awning} = this.state;
    let {productId, width} = awning;
    if (prevState) {
      if (productId === prevState.awning.productId &&
          width === prevState.awning.width) {
        return;
      }
    }
    if (productId == null || width === 0 || (selectedProduct && !selectedProduct.fixedProjection)) {
      this.setState({
        awning: {
          ...this.state.awning,
          projection: null,
        },
        projections: []
      }, this.raiseAwningChanged);
      return;
    }
    apiRequest(`/api/ProductLookupData/projections?productId=${productId}&width=${width}`)
        .then(x => {
          let data = x.data ? x.error ? [] : x.data : [];
          this.setState({
            projections: data
          }, this.raiseAwningChanged);
        });
  }

  fetchFabricSuppliers(prevState) {

    let {productId} = this.state.awning;
    let {organisationId} = this.state.order;

    if (prevState && productId === prevState.awning.productId && prevState.order.organisationId === organisationId) {
      return;
    }

    if (productId == null) {
      this.setState({
        fabricSuppliers: []
      }, this.raiseAwningChanged);
      return;
    } else {
      this.fetchLookupData(`/api/fabricbrands/suppliers?productId=${productId}&organisationId=${organisationId}`, 'fabricSuppliers');
    }
  }

  fetchProductLookup(prevState) {

    let {productId, width, projection} = this.state.awning;
        
    if (prevState) {
      let prevProj = prevState.awning.projection; 
      
      if (productId === prevState.awning.productId &&
          width === prevState.awning.width &&
          projection === prevProj) {          
        return;
      }
    }
    if (productId == null || width === 0 || projection == null || projection === 0) {
      this.setState({
        awning: {
          ...this.state.awning,
          productLookupDataId: null,
        }
      }, this.raiseAwningChanged);
      return;
    }
    apiRequest(`/api/ProductLookupData?productId=${productId}&width=${width}&projection=${projection}`)
        .then(x => {

          let gotData = x.data && x.data.ok === undefined;

          let id = !gotData ? null : x.data.id;
          let defaultSections = !gotData ? null : x.data.defaultSections;
          let oversquare = !gotData ? null : x.data.oversquare;
          let insideArmSpacing = !gotData || x.data.insideArmSpacing < 0 ? 0 : x.data.insideArmSpacing;
          let outsideArmSpacing = !gotData || x.data.outsideArmSpacing < 0 ? 0 : x.data.outsideArmSpacing;
          let numberOfMountingBracket = !gotData || x.data.numberOfMountingBracket < 0 ? 0 : x.data.numberOfMountingBracket;
          let numberOfArms = !gotData || x.data.numberOfArms < 0 ? 0 : x.data.numberOfArms;
          let averageLoadPerBracket = !gotData || x.data.averageLoadPerBracket < 0 ? 0 : x.data.averageLoadPerBracket;
          let weightExcMotor = !gotData || x.data.weightExcMotor < 0 ? 0 : x.data.weightExcMotor;
          let numberOfCradles = !gotData || parseInt(x.data.numberOfCradles) < 0 ? 0 : parseInt(x.data.numberOfCradles);
          let intermediateRoller = !gotData || parseInt(x.data.intermediateRoller) < 0 ? null : parseInt(x.data.intermediateRoller);

          let {validationMessages} = this.state;
          if (id == null || id == undefined) {
            validationMessages = {...validationMessages, productLookupDataId: x.data.message}
          } else {
            validationMessages = {...validationMessages, productLookupDataId: ''}
          }
          let a = {
            ...this.state.awning,
            productLookupDataId: id,
            sections: defaultSections == 1 ? 1 : null,
            isAssembled: defaultSections == 1,
            insideArmSpacing,
            outsideArmSpacing,
            numberOfMountingBracket,
            numberOfArms,
            averageLoadPerBracket,
            weightExcMotor,
            numberOfCradles,
            intermediateRoller,
            oversquare,
            additionalImRollerCount: !IsNullOrUndefined(prevState) && !IsNullOrUndefined(prevState.awning) && 
            (width !== prevState.awning.width || projection !== prevState.awning.projection || IsNullOrUndefined(intermediateRoller) || intermediateRoller === 0) ?
             null : IsNullOrUndefined(this.state.awning) ? null : this.state.awning.additionalImRollerCount,
          };
          
          this.setState({
            awning: a,
            validationMessages,
            defaultSections,
            oversquare,
          }, ()=> {          
            this.raiseAwningChanged();
          });
        });
  }

  fetchCrankHandles(prevState) {
    if (prevState && prevState.awning.driveMechanismId === this.state.awning.driveMechanismId) {
      return;
    }

    let driveMechanismId = this.state.awning.driveMechanismId;

    if (!driveMechanismId) {
      this.setState({
        crankHandles: [], awning: {
          ...this.state.awning,
          crankHandleId: null,
        }
      });
    } else {
      apiRequest(`/api/crankhandles?driveMechanismId=${driveMechanismId}`)
          .then(x => {
            let data = x.data ? x.error ? [] : x.data : [];
            let validationMessages = {};
            for (let propName in this.state.validationMessages) {
              if (data.length > 0) {
                validationMessages[propName] = this.state.validationMessages[propName];
              } else if (propName != 'crankHandleId' && propName != 'sideOfOperation') {
                validationMessages[propName] = this.state.validationMessages[propName];
              }
            }
            this.setState({
              crankHandles: data,
              awning: {
                ...this.state.awning,
                crankHandleId: data.length > 0 ? this.state.awning.crankHandleId : null,
              },
              validationMessages
            });
          });
    }
  }

  fetchMotors(prevState) {
    if (prevState &&
        prevState.awning.driveMechanismId === this.state.awning.driveMechanismId &&
        prevState.awning.productLookupDataId === this.state.awning.productLookupDataId &&
        prevState.order.organisationId === this.state.order.organisationId) {
      return;
    }

    let {awning} = this.state;
    let {driveMechanismId, productLookupDataId, motorId} = awning;
    let {organisationId} = this.state.order;

    if (!driveMechanismId) {
      this.setState({
        motors: [], awning: {
          ...this.state.awning,
          motorId: null,
        }
      });
    } else if (driveMechanismId !== DRIVE_MECHANISM_MotorisedPrepOnly || !productLookupDataId) {
      this.setState({motors: []});
    } else {
      apiRequest(`/api/motors/all?organisationId=${organisationId}&motorBrandId=2&productLookupId=${productLookupDataId}`)
          .then(x => {
            let data = x.data ? x.error ? [] : x.data : [];

            this.setState({
              motors: data,
              awning: {
                ...awning,
                motorId: motorId > 0 && some(data, y => y.id === motorId) ? motorId : null
              }
            }, this.raiseAwningChanged);
          });
    }
  }

  fetchFrameFeatureCrankHandles(prevState) {
    if (prevState
        && prevState.awning.driveMechanismId == this.state.awning.driveMechanismId
        && prevState.awning.frameFeatureCrankHandleId == this.state.awning.frameFeatureCrankHandleId
        && prevState.awning.frameFeatureDriveMechanismId == this.state.awning.frameFeatureDriveMechanismId
        && isSelectaPitch(prevState.awning) == isSelectaPitch(this.state.awning)
        && isVarioVolant(prevState.awning) == isVarioVolant(this.state.awning)
        && isManualFrameFeature(prevState.awning) == isManualFrameFeature(this.state.awning)
        && isFrameAdjusters(prevState.awning) == isFrameAdjusters(this.state.awning)) {
      return;
    }

    let isSP = isSelectaPitch(this.state.awning) || (isVarioVolant(this.state.awning) && isManualFrameFeature(this.state.awning));
    let isFF = isFrameAdjusters(this.state.awning);
    let url = '';

    if (isSP || isFF) {
      url = `/api/crankhandles?isSelectaPitch=${true}`;
    }

    if (url == '') {
      this.setState({
        frameFeatureCrankHandles: []
      });
      return;
    }

    apiRequest(url)
        .then(x => {
          let data = x.data ? x.error ? [] : x.data : [];

          if (this.state.awning.driveMechanismId) {
            apiRequest(`/api/crankhandles?driveMechanismId=${this.state.awning.driveMechanismId}`)
                .then(y => {
                  let equalCranks = isEqual(data, y.data);
                  this.setState({
                    frameFeatureCrankHandles: equalCranks ? [] : data
                  });
                });
          } else {
            this.setState({
              frameFeatureCrankHandles: data
            });
          }
        });
  }

  fetchTrimBiasColours(prevState) {
    let {fabricValance} = this.state.awning;

    if (prevState &&
        prevState.awning.fabricValance == fabricValance) {
      return;
    }

    if (!fabricValance) {
      this.setState({trimBiasColours: []});
      return;
    }

    apiRequest(`api/fabricbrands/trimbiascolours?valanceType=${fabricValance}`)
        .then(x => {
          let data = x.data ? x.error ? [] : x.data : [];
          this.setState({trimBiasColours: data});
        });
  }

  fetchRemoteColours(prevState) {
    let {remote, automationSensorType, productId, driveMechanismId} = this.state.awning;

    if (prevState &&
        prevState.awning.remote === remote &&
        prevState.awning.automationSensorType === automationSensorType &&
        prevState.awning.productId === productId &&
        prevState.awning.driveMechanismId === driveMechanismId) {
      return;
    }

    if (isNullEmptyOrWhitespace(remote)) {
      this.setState({remoteColours: [], desiredRemoteColour: null});
      return;
    }

    apiRequest(`api/remotes/colours?remoteLookupId=${remote}&sensorTypeId=${automationSensorType}`)
        .then(x => {
          let data = x.data ? x.error ? [] : x.data : [];
          this.setState({
            remoteColours: data,
            awning: {
              ...this.state.awning,
              desiredRemoteColour: data.filter(y => y.value === this.state.awning.desiredRemoteColour).length > 0 ? this.state.awning.desiredRemoteColour : null
            }
          }, this.raiseAwningChanged);
        });
  }

  fetchFrameFeatures(prevState) {
    let {awning, frameFeatures} = this.state;
    let {productId, width, projection, numberOfCradles, additionalCradles, productGroupId} = awning;

    if (prevState &&
        prevState.awning.productId === productId &&
        prevState.awning.width === width &&
        prevState.awning.projection === projection &&
        numberOfCradles === prevState.awning.numberOfCradles &&
        additionalCradles === prevState.awning.additionalCradles
    ) {
      return;
    }

    if (!productId || (!IsConservatoryOrRoofSystem(awning) && (width == '' || width <= 0 || !projection))) {
      this.setState({frameFeatures: []});
      return;
    }

    let hasCradles = (numberOfCradles + additionalCradles) > 0;

    let isRoofingSystem = IsRoofSystem(awning);
    
    if(isRoofingSystem && frameFeatures.length > 0){
      return;
    }
    
    apiRequest(`api/frameFeatures?productId=${productId}&width=${width}&projection=${projection}&hasCradles=${hasCradles}`)
        .then(x => {
          let data = x.data ? x.error ? [] : x.data : [];
          let features = data.filter(x => x.id === this.state.awning.frameFeature);
          let selectedFrameFeature = features.length === 1 ? features[0] : null;
          
          if(isRoofingSystem && data.length === 1) {
            selectedFrameFeature = data[0];
          }          

          if (!IsNullOrUndefined(awning.frameFeature) && awning.productGroupId === PRODUCTGROUPID_CONSERVATORY && data.length === 0) {
            toastr.warning('This frame feature is no longer supported given the following dimensions');
          } else {                       
            this.setState({
              frameFeatures: data,
              selectedFrameFeature,              
              frameFeatureFieldTypes: data.length > 0 ? this.state.frameFeatureFieldTypes : [],
              frameFeatureSteerAroundTypes: data.length > 0 ? this.state.frameFeatureSteerAroundTypes : [],
              frameFeatureCrankHandles: data.length > 0 ? this.state.frameFeatureCrankHandles : [],
              frameFeatureFabrics: data.length > 0 ? this.state.frameFeatureFabrics : [],
              frameFeatureFabricBrands: data.length > 0 ? this.state.frameFeatureFabricBrands : [],
              frameFeatureSideOfOperation: data.length > 0 ? this.state.frameFeatureSideOfOperation : [],
            }, ()=>{
              let currentAwning = this.state.awning;
              
              if(IsNullOrUndefined(selectedFrameFeature) || currentAwning.frameFeature !== selectedFrameFeature.id) {
                let newAwning = this.state.awning.id < 0 ? getFrameFeatureDefaults(currentAwning,
                    IsNullOrUndefined(selectedFrameFeature) ? null : selectedFrameFeature.id, this.state.awning, this.props.order, this.state.selectedProduct) : currentAwning;
                if (awning.frameFeature === newAwning.frameFeature) {
                  newAwning = {
                    ...newAwning,
                    frameFeatureSteerAroundType: currentAwning.frameFeatureSteerAroundType,
                    steerAroundDimensions: currentAwning.steerAroundDimensions,
                  }
                }
                this.setState({awning: newAwning})
              }              
            });
          }
        });
  }

  fetchFrameFeatureDriveMechanisms(prevState) {
    let {frameFeature, driveMechanismId} = this.state.awning;

    if (prevState &&
        prevState.awning.frameFeature === frameFeature &&
        prevState.awning.driveMechanismId === driveMechanismId) {
      return;
    }

    if (!frameFeature) {
      this.setState({frameFeatureDriveMechanisms: []});
      return;
    }

    apiRequest(`api/frameFeatures/${frameFeature}/drivemechanisms?awningDriveMechanismId=${driveMechanismId}`)
        .then(x => {
          let data = x.data ? x.error ? [] : x.data : [];
          this.setState({frameFeatureDriveMechanisms: data});
        });
  }

  fetchFrameFeatureOperatingSides(prevState) {
    let {frameFeature} = this.state.awning;

    if (prevState &&
        prevState.awning.frameFeature == frameFeature) {
      return;
    }

    if (!frameFeature || IsRoofSystem(this.state.awning)) {
      this.setState({frameFeaturSideOfOperations: []});
      return;
    }

    apiRequest(`api/frameFeatures/${frameFeature}/sideOfOperations`)
        .then(x => {
          let data = x.data ? x.error ? [] : x.data : [];
          this.setState({frameFeaturSideOfOperations: data});
        });
  }

  fetchDrops(prevState) {
    let {frameFeature} = this.state.awning;

    if (prevState &&
        prevState.awning.frameFeature == frameFeature) {
      return;
    }

    if (!frameFeature) {
      this.setState({drops: []});
      return;
    }

    apiRequest(`api/frameFeatures/${frameFeature}/drops`)
        .then(x => {
          let data = x.data ? x.error ? [] : x.data : [];
          this.setState({drops: data});
        });
  }

  fetchFieldTypes(prevState) {
    let {frameFeature} = this.state.awning;

    if (prevState &&
        prevState.awning.frameFeature === frameFeature) {
      return;
    }

    if (!frameFeature) {
      this.setState({frameFeatureFieldTypes: []});
      return;
    }

    apiRequest(`api/frameFeatures/${frameFeature}/fieldTypes`)
        .then(x => {
          let data = x.data ? x.error ? [] : x.data : [];
          
          let {awning} = this.state;
          if(IsRoofSystem(awning) && IsNullOrUndefined(awning.frameFeatureFieldType) && data.length > 0){
            awning = {...awning, frameFeatureFieldType: data[0].value};            
          }
          
          this.setState({frameFeatureFieldTypes: data, awning}, () => {
            this.fetchFieldConfigurationOptions();
          });
        });
  }

  fetchSteerAroundTypes(prevState) {
    let {awning} = this.state;
    let {frameFeature, steerAroundDimensions} = awning;

    if (prevState &&
        prevState.awning.frameFeature === frameFeature) {
      return;
    }

    if (!frameFeature) {
      this.setState({frameFeatureSteerAroundTypes: [], awning: {...awning, steerAroundDimensions: null}});
      return;
    }

    apiRequest(`api/frameFeatures/${frameFeature}/steeraroundtypes`)
        .then(x => {
          let data = x.data ? x.error ? [] : x.data : [];
          this.setState({frameFeatureSteerAroundTypes: data}, () => {

            let sad = this.state.frameFeatureSteerAroundTypes.length > 0 ? IsNullOrUndefined(this.state.awning.steerAroundDimensions) ? {
              h1: 0,
              h2: 0,
              h3: 0,
              h4: 0,
              h5: 0,
              c1: 0,
              c2: 0,
              c3: 0,
              alpha: 0,
              beta: 0,
            } : steerAroundDimensions : null;

            this.handleSteerAroundDimensionUpdate(sad);
          });
        });
  }

  fetchFieldInstallationOptions(prevState) {

    //if the number of fields for the field configuration changes
    let {awning} = this.state;
    let {fieldConfiguration} = awning;

    if (prevState &&
        prevState.awning.productId === awning.productId &&
        (!fieldConfiguration || (fieldConfiguration &&
            prevState.awning.fieldConfiguration &&
            prevState.awning.fieldConfiguration.numberOfFields === fieldConfiguration.numberOfFields))) {
      return;
    }

    if (IsNullOrUndefined(awning.productId) && this.state.fieldInstallationOptions.length > 0) {
      this.setState({fieldInstallationOptions: []}, this.raiseAwningChanged);
    } else {
      let numberOfFields = !IsNullOrUndefined(fieldConfiguration) && fieldConfiguration.numberOfFields > 0 ? fieldConfiguration.numberOfFields : 1;
      apiRequest(`/api/brackets/installationOptions?fieldCount=${numberOfFields}&productId=${awning.productId}`)
          .then(x => {
            let data = x.data ? x.error ? [] : x.data : [];
            this.setState({fieldInstallationOptions: data}, this.raiseAwningChanged);
          });
    }
  }

  fetchLookupData(url, stateField, awningField = null, defaultValue = [], stateAction = null) {
    let defState = {};
    if (this.state[stateField] && this.state[stateField].length > 0) {
      defState[`${stateField}`] = defaultValue;
    }
    this.setState(defState, () => {
      apiRequest(url)
          .then(x => {
            let data = x.data ? x.error ? defaultValue : x.data : defaultValue;

            let stateUpdate = {};

            if (awningField) {
              let validationMessages = {};
              for (let propName in this.state.validationMessages) {
                if (data.length > 0) {
                  validationMessages[propName] = this.state.validationMessages[propName];
                } else if (propName != awningField) {
                  validationMessages[propName] = this.state.validationMessages[propName];
                }
              }
              stateUpdate.validationMessages = validationMessages;
            }

            stateUpdate[`${stateField}`] = data;

            if (stateAction !== null) {
              stateUpdate = stateAction(stateUpdate);
            }

            this.setState(stateUpdate, this.raiseAwningChanged);
          }).catch(x => console.log(url, stateField, x));
    });
  }

  fetchMountingBrackets(prevState) {
    let {productId, includePelmet, fieldConfiguration, frameFeature, width, numberOfArms} = this.state.awning;

    if (prevState && prevState.awning.productId === productId &&
        prevState.awning.includePelmet === includePelmet &&
        prevState.awning.numberOfArms === numberOfArms &&
        prevState.awning.width === width &&
        prevState.awning.frameFeature === frameFeature &&
        ((prevState.awning.fieldConfiguration && fieldConfiguration &&
            (
                (prevState.awning.fieldConfiguration.fieldConfigurationOptionId === fieldConfiguration.fieldConfigurationOptionId) ||
                (IsNullOrUndefined(prevState.awning.fieldConfiguration.fieldConfigurationOptionId) &&
                    IsNullOrUndefined(fieldConfiguration.fieldConfigurationOptionId))
            )
        ) || (!prevState.awning.fieldConfiguration && !fieldConfiguration))) {
      return;
    }
    if (!productId) {
      return;
    }
    this.fetchLookupData(`/api/brackets?productId=${productId}&includePelmet=${includePelmet}&frameFeatureId=${frameFeature}&fieldConfigurationOptionId=${IsNullOrUndefined(fieldConfiguration) ? null : fieldConfiguration.fieldConfigurationOptionId}&width=${width}&numberOfArms=${numberOfArms}`, 
        'mountingBrackets', null, [], (newState) => {
      if (newState.mountingBrackets.length === 1) {
        return {
          ...newState,
          awning: {...this.state.awning, mountingBracketId: newState.mountingBrackets[0].id}
        };
      }

      if (this.state.awning.mountingBracketId > 0 && newState.mountingBrackets.findIndex(x => x.id === this.state.awning.mountingBracketId) < 0) {
        return {
          ...newState,
          awning: {...this.state.awning, mountingBracketId: null}
        };
      }

      return newState;
    });
  }

  fetchSintesiVerticalFabrics(prevState) {
    let {awning} = this.state;
    let {sintesi} = awning;

    if (IsNullOrUndefined(awning) || IsNullOrUndefined(sintesi)) {
      return;
    }

    let {verticalScreenFabricBrandId} = sintesi;
    if (prevState && prevState.awning.sintesi) {
      let prevFabricBrandId, prevFabricSuppliedBy;
      prevFabricBrandId = prevState.awning.sintesi.verticalScreenFabricBrandId;

      if (prevFabricBrandId === verticalScreenFabricBrandId) {
        return;
      }
    }

    if (verticalScreenFabricBrandId != null) {
      this.fetchLookupData(`/api/fabricbrands/byBrand?fabricBrandId=${verticalScreenFabricBrandId}`, 'sintesiVerticalFabrics');
    } else {
      this.setState({
        sintesiVerticalFabrics: [],
      });
    }
  }
  
  fetchFabrics(prevState) {
    let {fabricBrandId, fabricSuppliedBy} = this.state.awning;
    if (prevState) {
      let prevFabricBrandId, prevFabricSuppliedBy;
      prevFabricBrandId = prevState.awning.fabricBrandId;
      prevFabricSuppliedBy = prevState.awning.fabricSuppliedBy;

      if (prevFabricBrandId === fabricBrandId && prevFabricSuppliedBy === fabricSuppliedBy) {
        return;
      }
    }

    if (AwningRequiresFabric(this.props.order, this.state.awning) && fabricBrandId != null) {
      this.fetchLookupData(`/api/fabricbrands/byBrand?fabricBrandId=${fabricBrandId}`, 'fabrics');
    } else {
      this.setState({
        fabrics: [],
      });
    }
  }

  fetchFabricBrands(prevState) {
    let {width, projection, productId, numberOfCradles, additionalCradles} = this.state.awning;

    if (prevState && width === prevState.awning.width &&
        projection === prevState.awning.projection &&
        numberOfCradles === prevState.awning.numberOfCradles &&
        additionalCradles === prevState.awning.additionalCradles &&
        productId === prevState.awning.productId) {
      return;
    }

    if (width == 0 || projection == null || productId == null) {
      this.setState({
        fabricBrands: [],
        fabrics: [],
      });
    } else {
      let hasCradles = (numberOfCradles + additionalCradles) > 0;
      this.fetchLookupData(`/api/fabricbrands?width=${width}&projection=${projection}&productId=${productId}&hasCradles=${hasCradles}`, 'fabricBrands');
    }
  }

  setColourSameAsAwning(e, awningNumber = 1) {
    e.preventDefault();
    let {order, awning} = this.state;
    let fromAwning = order.awnings.filter(x => x.number === awningNumber)[0];

    this.setState({
      awning: {
        ...awning,
        frameColourId: fromAwning.frameColourId,
        frameCustomColourId: fromAwning.frameCustomColourId,
      }
    }, () => {
      this.raiseAwningChanged();
    });
  }

  setDriveSameAsAwning(e, awningNumber = 1) {
    e.preventDefault();
    let {order, awning} = this.state;
    let fromAwning = order.awnings.filter(x => x.number === awningNumber)[0];

    this.setState({
      awning: {
        ...awning,
        driveMechanismId: fromAwning.driveMechanismId,
      }
    }, () => {
      this.raiseAwningChanged();
    });
  }

  setFabricSameAsAwning(e, awningNumber = 1) {
    e.preventDefault();
    let {order, awning} = this.state;
    let fromAwning = order.awnings.filter(x => x.number === awningNumber)[0];

    this.setState({
      awning: {
        ...awning,
        fabricId: fromAwning.fabricId,
        fabricBrandId: fromAwning.fabricBrandId,
        fabricSuppliedBy: fromAwning.fabricSuppliedBy,
        fabricValance: fromAwning.fabricValance,
      }
    }, () => {
      this.raiseAwningChanged();
      this.fetchFabricBrands();
      this.fetchFabrics();
    });
  }

  fetchFrameFeatureFabricBrands(prevState) {
    let {drop, frameFeature} = this.state.awning;

    if (prevState && drop == prevState.awning.drop &&
        frameFeature == prevState.awning.frameFeature) {
      return;
    }

    if (!drop || !frameFeature) {
      this.setState({
        frameFeatureFabricBrands: [],
        frameFeatureFabrics: [],
        awning: {
          ...this.state.awning,
          frameFeatureFabricId: null,
          frameFeatureFabricBrandId: null
        }
      }, this.raiseAwningChanged);
    } else {
      this.fetchLookupData(`/api/framefeatures/${frameFeature}/fabricBrands?drop=${drop}`, 'frameFeatureFabricBrands');
    }
  }

  fetchFrameFeatureFabrics(prevState) {
    let {frameFeatureFabricBrandId, fabricSuppliedBy} = this.state.awning;
    if (prevState) {
      let prevFabricBrandId, prevFabricSuppliedBy;
      prevFabricBrandId = prevState.awning.frameFeatureFabricBrandId;
      prevFabricSuppliedBy = prevState.awning.fabricSuppliedBy;

      if (prevFabricBrandId == frameFeatureFabricBrandId && prevFabricSuppliedBy == fabricSuppliedBy) {
        return;
      }
    }

    if (fabricSuppliedBy == 1 && frameFeatureFabricBrandId != null) {
      this.fetchLookupData(`/api/fabricbrands/byBrand?fabricBrandId=${frameFeatureFabricBrandId}`, 'frameFeatureFabrics');
    } else {
      this.setState({
        frameFeatureFabrics: [],
      });
    }
  }

  fetchAutomationCables(prevState) {
    let {driveMechanismId} = this.state.awning;

    if (prevState && driveMechanismId == prevState.awning.driveMechanismId) {
      return;
    }

    if (!driveMechanismId) {
      this.setState({
        automationCables: [],
        awning: {
          ...this.state.awning,
          automationCableId: null
        }
      }, this.raiseAwningChanged);
    } else {
      this.fetchLookupData(`/api/automationcables?driveMechanismId=${driveMechanismId}`,
          'automationCables', null, [], (state) => {
            let retState = state;

            if (retState.automationCables.length > 0 && this.state.awning.automationCableId == null) {
              retState.awning = {...this.state.awning, automationCableId: retState.automationCables[0].value};
            }

            return retState;
          });
    }
  }

  fetchLedLighting(prevState) {
    let {driveMechanismId, productId} = this.state.awning;

    if (prevState && driveMechanismId == prevState.awning.driveMechanismId
        && productId == prevState.awning.productId) {
      return;
    }

    if (!driveMechanismId || !productId) {
      this.setState({
        ledLightings: [],
        awning: {
          ...this.state.awning,
          includeLed: false
        }
      }, this.raiseAwningChanged);
    } else {
      this.fetchLookupData(`/api/lightings/led?driveMechanismId=${driveMechanismId}&productId=${productId}`, 'ledLightings');
    }
  }

  fetchInstallationBrackets(prevState) {
    let {installationBracketTypeId, mountingBracketId} = this.state.awning;

    if (prevState && installationBracketTypeId === prevState.awning.installationBracketTypeId && mountingBracketId === prevState.awning.mountingBracketId) {
      return;
    }

    if(mountingBracketId === SM_FASCIA_BRACKET_ID){
      installationBracketTypeId = 7;
    }
    
    if (!installationBracketTypeId) {
      this.setState({
        installationBrackets: [],
        awning: {
          ...this.state.awning,
          installationBracketId: null
        }
      }, this.raiseAwningChanged);
    } else {
      this.fetchLookupData(`/api/brackets/installation?installationBracketTypeId=${installationBracketTypeId}`, 'installationBrackets',
          null, [], (newState) => {
            return (newState.installationBrackets.length === 1) ? {
              ...newState,
              awning: {
                ...this.state.awning,
                installationBracketId: newState.installationBrackets[0].id,
              }
            } : newState;
          });
    }
  }

  fetchJoinerOptiosn(prevState) {
    let {width, frameFeature, productLookupDataId, projection} = this.state.awning;
    if (prevState) {
      if (width == prevState.awning.width
          && frameFeature == prevState.awning.frameFeature
          && projection == prevState.awning.projection
          && productLookupDataId == prevState.awning.productLookupDataId
          && this.state.defaultSections == prevState.defaultSections
      ) {
        return;
      }
    }

    if (this.state.defaultSections > 1) {
      this.setState({joinerOptions: JoinerOptionsRequiredJoiner});
    } else if (!width || this.state.defaultSections == 1 || this.state.awning.defaultSections == 1 || isSelectaPitch(this.state.awning)) {
      this.setState({joinerOptions: []});
    }
  }

  fetchDriveMechanisms(prevState) {
    let {width, productId} = this.state.awning;

    if (prevState && width == prevState.awning.width && productId == prevState.awning.productId) {
      return;
    }

    if (width > 0 && productId) {
      this.fetchLookupData(`/api/drivemechanisms?productId=${productId}&width=${width}`, 'driveMechanisms');
    } else {
      this.setState({driveMechanisms: []});
    }
  }


  handleRadioChangeWithAwningId(val, e, valSelector, fieldName = null, action = null) {

    let x = {...this.state.awning};
    let newState = {...this.state};
    let awningFieldValue = valSelector(val);
    let awningFieldName = e.target.name.split('_')[0];
    x[`${awningFieldName}`] = awningFieldValue;

    if (fieldName) {
      newState[`${fieldName}`] = val.objectValue;
    }
    let validationMessages = this.getValidationMessages(awningFieldName, awningFieldValue);

    newState.validationMessages = validationMessages;
    newState.awning = x;

    if (action) {
      newState = action(val, newState);
    }

    this.setState(newState, this.raiseAwningChanged);
  }


  handlFieldChangeWithAwningId(e, action = null, type = "text") {
    if (IsNullOrUndefined(e) || IsNullOrUndefined(e.target)) {
      return;
    }
    let newEvent = {
      target: {
        name: e.target.name.split('_')[0],
        value: e.target.value,
        type,
      }
    };

    this.handlFieldChange(newEvent, action);
  }

  handlFieldChange(e, action = null) {
    let newState = {...this.state};
    let x = {...this.state.awning};

    if (e.target.type === 'checkbox') {
      x[`${e.target.name}`] = e.target.checked;
    } else {
      if (e.target.type === 'number') {
        x[`${e.target.name}`] = isNullEmptyOrWhitespace(e.target.value) ? 0 : parseInt(e.target.value);
      } else {
        x[`${e.target.name}`] = e.target.value;
      }
    }

    let validationMessages = this.getValidationMessages(e.target.name, x[`${e.target.name}`]);

    newState.validationMessages = validationMessages;
    newState.awning = x;

    if (action) {
      newState = action(x[`${e.target.name}`], newState);
    }
    this.setState(newState, this.raiseAwningChanged);
  }

  getValidationMessages(fieldName, val) {
    let validationMessages = {...this.state.validationMessages};
    let validation = ValidationRules[`${fieldName}`];
    if (validation) {
      let validationResult = validation.validator(val, this.state, this.props);
      if (!validationResult.isValid) {
        validationMessages[`${fieldName}`] = validationResult.message;
      } else {
        validationMessages[`${fieldName}`] = '';
      }
    }
    return validationMessages;
  }

  handleSelectChange(awningFieldName, val, valSelector, fieldName = null, action = null) {
    let x = {...this.state.awning};
    let newState = {...this.state};
    let awningFieldValue = valSelector(val);
    x[`${awningFieldName}`] = awningFieldValue;

    if (fieldName) {
      newState[`${fieldName}`] = val;
    }
    let validationMessages = this.getValidationMessages(awningFieldName, awningFieldValue);

    newState.validationMessages = validationMessages;
    newState.awning = x;

    if (action) {
      newState = action(val, newState);
    }
    this.setState(newState, this.raiseAwningChanged);
  }

  handleColourChange(field, val) {
    let {selectedStandardColourId, selectedCustomColourId} = val;
    let x = {...this.state.awning};
    let newState = {...this.state};

    x[`${field}ColourId`] = selectedStandardColourId;
    x[`${field}CustomColourId`] = selectedCustomColourId;

    let validationMessages = this.getValidationMessages(`${field}ColourId`, {
      selectedStandardColourId,
      selectedCustomColourId
    });

    newState.validationMessages = validationMessages;
    newState.awning = x;

    this.setState(newState, this.raiseAwningChanged);
  }

  isValid() {
    let {awning} = this.state;
    let messages = {};
    let isValid = true;

    for (let propName in ValidationRules) {

      let rule = ValidationRules[propName];
      let val = awning[propName];
      if (propName !== 'trimBiasColourId' && propName !== 'motionSensorColour' && propName !== 'desiredRemoteColour'
          && propName.includes('Colour') && rule) {
        val = {
          selectedStandardColourId: awning[propName],
          selectedCustomColourId: awning[propName.replace('ColourId', 'CustomColourId')]
        };
      }

      let valResult = rule.validator(val, this.state, this.props);
      if (!valResult.isValid) {
        messages[propName] = valResult.message;
        isValid = false;
      }
    }

    //check the AwningFieldsControl
    let awningFieldsValidation = !IsNullOrUndefined(awning.fieldConfiguration) ? GetFieldConfigValidationMessages(awning.fieldConfiguration, awning.id, awning.sideOfOperation) : {};
    if (awningFieldsValidation && keys(awningFieldsValidation).length > 0) {
      isValid = false;
      messages = merge(messages, awningFieldsValidation);
    }
    //check the SteerAroundDimensions
    let steerAroundValidation = !IsNullOrUndefined(awning.steerAroundDimensions) ? GetSADimensionValidationMessages(awning.steerAroundDimensions, awning.id, awning.frameFeatureSteerAroundType) : {};

    if (steerAroundValidation && keys(steerAroundValidation).length > 0) {
      isValid = false;
      messages = merge(messages, steerAroundValidation);
    }

    //check the Sintesi
    let sintesiValidation = !IsNullOrUndefined(awning.sintesi) ? GetSintesiValidationMessages(awning.sintesi, this.state) : {};

    if (sintesiValidation && keys(sintesiValidation).length > 0) {
      isValid = false;
      messages = merge(messages, sintesiValidation);
    }

    this.setState({validationMessages: messages, sintesiValidationMessages: sintesiValidation}, this.showAwningMessages);

    return isValid;
  } 
  

  componentWillReceiveProps(newProps) {
    let becomingVisible = this.props.isVisible != newProps.isVisible && newProps.isVisible;

    this.setState({awning: newProps.awning, order: newProps.order}, () => {
      if (!becomingVisible || IsNullOrUndefined(newProps.awning) || !newProps.awning.id) {
        return;
      }
      let {awning} = this.state;
      if (awning.productGroupId > 0) {
        this.fetchProducts();
      }
      if (awning.productId > 0) {
        this.fetchFrameFeatures();
        this.fetchProductProjections();
        this.fetchFabricSuppliers();
        this.setFlashingOptions();
      }
      if (awning.frameFeature) {
        this.fetchFrameFeatureDriveMechanisms();
        this.fetchFrameFeatureOperatingSides();
        this.fetchDrops();
        this.fetchFrameFeatureCrankHandles();
        this.fetchSteerAroundTypes();
        this.fetchFieldTypes();
      }

      this.fetchCrankHandles();
      this.fetchMotors();
      this.fetchMountingBrackets();
      this.showAwningMessages();
      this.fetchFabrics();
      this.fetchFabricBrands();
      this.fetchFrameFeatureFabricBrands();
      this.fetchFrameFeatureFabrics();
      this.fetchLedLighting();
      this.fetchJoinerOptiosn();
      this.fetchRemoteColours();
      this.fetchFieldInstallationOptions();

      if (awning.driveMechanismId) {
        this.fetchLookupData(`/api/remotes?driveMechanismId=${this.state.awning.driveMechanismId}&productId=${this.state.awning.productId}`, 'remotes', 'remote');
        this.fetchAutomationCables();
      }
      if (awning.fabricValance) {
        this.fetchTrimBiasColours();
      }
      if (awning.productId > 0) {
        this.fetchProductLookup();
      }
      this.fetchInstallationBrackets();
      this.fetchDriveMechanisms();
      if(this.state.awning.sintesi){
        this.fetchSintesiVerticalFabrics();
      }
    });
    if(!IsNullOrUndefined(newProps.organisation) && newProps.organisation.id !== this.state.organisation.id){
      this.setState({organisation: newProps.organisation});
    }
  }

  componentDidMount() {
    let {awning} = this.state;
    this.fetchProductGroups();

    if (awning.productGroupId > 0) {
      this.fetchProducts();
    }
    if (awning.productId > 0) {
      this.fetchFrameFeatures();
      this.fetchProductProjections();
      this.fetchFabricSuppliers();
      this.setFlashingOptions();
    }
    if (awning.frameFeature) {
      this.fetchFrameFeatureDriveMechanisms();
      this.fetchFrameFeatureOperatingSides();
      this.fetchDrops();
      this.fetchFrameFeatureCrankHandles();
      this.fetchSteerAroundTypes();
      this.fetchFieldTypes();
    }

    this.fetchCrankHandles();
    this.fetchMotors();
    this.fetchMountingBrackets();
    this.showAwningMessages();

    if (awning.driveMechanismId) {
      this.fetchLookupData(`/api/remotes?driveMechanismId=${this.state.awning.driveMechanismId}&productId=${this.state.awning.productId}`, 'remotes', 'remote');
      this.fetchAutomationCables();
    }

    if (awning.fabricValance) {
      this.fetchTrimBiasColours();
    }

    this.fetchLedLighting();
    this.fetchLookupData(`/api/colours/customframe`, 'coloursCustom');
    this.fetchLookupData(`/api/fabricbrands/valances`, 'valances');
    this.fetchFabrics();
    this.fetchFabricBrands();
    this.fetchFrameFeatureFabricBrands();
    this.fetchFrameFeatureFabrics();
    if(IsNullOrUndefined(this.props.organisation)){
      this.fetchLookupData(`/api/organisations/currentuser`, 'organisation', null, {id: 0});
    }    
    if (awning.productId > 0) {
      this.fetchProductLookup();
      this.fetchLookupData(`/api/colours/${awning.productId}/frame`, 'coloursStandard');
      this.fetchInstallationBracketTypes();      
    }
    this.fetchJoinerOptiosn();
    this.fetchInstallationBrackets();
    this.fetchDriveMechanisms();
    this.fetchRemoteColours();
    this.fetchFieldInstallationOptions();
    
    this.fetchLookupData(`/api/fabricBrands/sintesi/verticalFabricBrands`, 'sintesiVerticalFabricBrands', null, []);
    if(this.state.awning.sintesi){      
      this.fetchSintesiVerticalFabrics();
    }
  }

  frameFeaturesControl(style) {

    let {awning, validationMessages, frameFeatures} = this.state;

    return awning.productGroupId !== PRODUCTGROUPID_ROOFING_SYSTEMS ? (<Aux><RadioControlGroup
        style={style}
        name={`frameFeature_${awning.id}`}
        onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, newState) => {          
          return {
            ...newState, awning: {
              ...newState.awning,
              frameFeature: v == null ? null : v,
              drop: null,
              frameFeatureCrankHandleId: null,
              frameFeatureDriveMechanismId: null,
              frameFeatureSideOfOperation: null,
              frameFeatureFabricBrandId: null,
              frameFeatureFabricId: null,
              frameFeatureFieldType: null,
              frameFeatureSteerAroundType: null,
              fabricValance: null,
              trimBiasColourId: null,
              fieldConfiguration: null,
              steerAroundDimensions: null,
            }, selectedFrameFeature: v == null ? null : frameFeatures.filter(x => x.id === v)[0],
          };
        }, "number")}
        value={awning.frameFeature}
        labelText={awning.productGroupId === PRODUCTGROUPID_CONSERVATORY ? `Configuration if not standard single field` : 'Frame Feature (optional)'}
        onClearSelection={(awning.frameFeature ? (e) => {
          e.preventDefault();
          this.setState({
            awning: getFrameFeatureDefaults(this.state.awning, null, this.state.awning, this.props.order, this.state.selectedProduct),
            selectedFrameFeature: null,
            frameFeatureFabricBrands: [],
          }, this.raiseAwningChanged);
        } : null)}
        validationMessages={validationMessages}
        options={frameFeatures}
        valueKey={'id'}
        labelKey={'description'}
    />    
      { frameFeatures.length === 0 && !IsNullOrUndefined(awning.productId) && awning.width > 0 && awning.projection > 0 && <div className="alert alert-info" role="alert" style={{marginLeft: '-15px'}}>
        There were no frame features {awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM ? `(Selectapitch, Vario Volant) ` : ``}available for this size configuration
      </div> }      
    </Aux>) : null;
  }

  getRemoteParentOptions() {
    let {awning} = this.state;
    let {order} = this.props;

    if (awning.remote === REMOTE_MULTICHANNEL) {
      let returnVal = order.awnings.filter(x => x.number !== awning.number).map(x => {
        return {value: x.number, label: `Awning #${x.number}`};
      });
      return returnVal;
    }
    return [];
  }

  render() {
    let {
      awning,
      validationMessages,
      isProductResourcesOpen,
      selectedProduct,
      driveMechanisms,
      mountingBrackets,
      sintesiValidationMessages,
    } = this.state;
    let {order, user} = this.props;
    let pelmetName = (selectedProduct && selectedProduct.hasPelmet) ? 'Cassette Profile' : 'Pelmet';
    let pdfOnly = selectedProduct && selectedProduct.pdfOrderOnly;
    let resourceTags = GetProductResourceTags(awning.productId);
    let awningRequiresFabric = AwningRequiresFabric(order, awning);

    if (awning.canSupplyOwnMotor) {
      driveMechanisms = driveMechanisms.filter(x => x.value !== DRIVE_MECHANISM_MotorisedPrepOnly);
    }

    let requireAdditionalBracketCount = RequireAdditionalBracketCount(mountingBrackets, awning);
    let hasPosts = hasInstallationPosts(awning, this.state.fieldInstallationOptions);
    // let {finishedProjection, finishedAngle} = IsNullOrUndefined(awning.sintesi)? {finishedProjection: 0, finishedAngle: 0} :  CalculateFinishedProjection(awning.projection,
    //     awning.sintesi.backHeight,
    //     awning.sintesi.frontHeight,
    //     awning.sintesi.fallBackToFront,
    //     hasPosts);
    let isRoofingSystem = IsRoofSystem(awning);
    let headboxWidth = isRoofingSystem ? 40 : IsNullOrUndefined(selectedProduct) || IsNullOrUndefined(selectedProduct.headboxWidth) ? 0 : selectedProduct.headboxWidth;
    

    let selectedFabric = (awning.fabricId && this.state.fabrics) ? this.state.fabrics.find(x => x.id === awning.fabricId) : null;
    let selectedFrameFeatureFabric = (awning.frameFeatureFabricId && this.state.frameFeatureFabrics) ? this.state.frameFeatureFabrics.find(x => x.id === awning.frameFeatureFabricId) : null;

    return (
        <div style={{display: this.props.isVisible ? '' : 'none'}}>

          <Modal isOpen={resourceTags.length > 0 && isProductResourcesOpen}
                 toggle={() => this.setState({isProductResourcesOpen: false})}
          >
            <ModalHeader>
              <div style={{textAlign: 'right', width: '100%'}}>
                <button
                    className="btn pi-btn-base"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({isProductResourcesOpen: false})
                    }}
                ><i className={'fa fa-times'}/></button>
              </div>
            </ModalHeader>
            <ModalBody>
              <ProductResourcesPage controlTags={resourceTags}/>
            </ModalBody>
          </Modal>

          <RadioControlGroup
              name={`productGroupId_${awning.id}`}
              onChange={(x, e) => {
                this.handlFieldChangeWithAwningId(e, (v, newState) => {
                  return {
                    ...newState,
                    awning: {...getDefaultAwning(newState.awning), productId: null, projection: 0,},
                    selectedProduct: null,
                    products: [],
                    installationBrackets: [],
                    flashingOptions: [],
                    installationBracketTypes: [],
                  };                  
                }, "number")
              }}
              value={awning.productGroupId}
              labelText='Product Group'
              validationMessages={validationMessages}
              options={this.state.productGroups}
          />

          <RadioControlGroup
              name={`productId_${awning.id}`}
              valueKey={'id'}
              labelKey={'description'}
              onChange={(val, e) => this.handleRadioChangeWithAwningId(val, e, (v) => v.objectValue.id, 'selectedProduct', (v, newState) => {
                return {
                  ...newState, awning: {...getDefaultAwning(newState.awning)},
                  installationBrackets: [],
                  flashingOptions: [],
                  installationBracketTypes: [],
                  remoteColours: [],
                  remotes: [],
                };
              })}
              value={awning.productId}
              labelText='Product'
              validationMessages={validationMessages}
              options={this.state.products}
          />

          <ProductInformationControl id={`productInfo1_${awning.number}`} productId={awning.productId} onProductResourceSelect={(e) => {
            e.preventDefault();
            this.setState({isProductResourcesOpen: true})
          }}/>

          {pdfOnly ? <div>
                This product cannot yet be ordered online, please download the&nbsp;
                {this.state.selectedProduct.id === PRODUCTID_SD ? <span>
              <a href={`/order-forms/${this.state.selectedProduct.id}_MaxiRail.pdf`} target={'_blank'}>Standard/MaxiRail Aluxor Order Form</a>&nbsp;or&nbsp;
                      <a href={`/order-forms/${this.state.selectedProduct.id}_SteerAround.pdf`} target={'_blank'}>Steer Around Aluxor Order Form</a>
            </span> :
                    <a href={`/order-forms/${this.state.selectedProduct.id}.pdf`} target={'_blank'}>Aluxor Order
                      Form</a>}&nbsp;for this product
              </div> :

              <div>

                {IsConservatoryOrRoofSystem(awning) && this.frameFeaturesControl()}

                { IsConservatory(awning) && <ProductInformationControl id={`productInfo2_${awning.number}`} frameFeatureId={awning.frameFeature}/> }
                
                <RadioControlGroup
                    name={`frameFeatureFieldType_${awning.id}`}
                    onChange={(x, e) => {
                      this.handlFieldChangeWithAwningId(e, (v, m) => {                        
                        return {...m, awning: {...m.awning, remote: null, desiredRemoteColour: null}};
                      }, "number");
                    }}
                    value={awning.frameFeatureFieldType}
                    labelText='Multi Field Configuration (Field / Motor)'
                    validationMessages={validationMessages}
                    options={this.state.frameFeatureFieldTypes}                    
                />                
                
                { !isRoofingSystem && <RadioControlGroup
                    name={`fieldConfigurationOption_${awning.id}`}
                    onChange={this.handleFieldConfigurationOptionUpdate}
                    value={IsNullOrUndefined(awning.fieldConfiguration) ? null : awning.fieldConfiguration.fieldConfigurationOptionId}
                    labelText='Field Options'
                    validationMessages={validationMessages}
                    options={this.state.fieldConfigurationOptions}
                    displayAsList={true}
                /> }

                { selectedProduct && isRoofingSystem && !IsNullOrUndefined(awning.sintesi) &&
                    <Aux>


                      <AwningFieldsControl
                          awningId={awning.id}
                          fieldConfiguration={awning.fieldConfiguration}
                          onFieldConfigurationUpdated={this.handleFrameFeatureFieldValueUpdate}
                          headboxWidth={headboxWidth}
                          sideOfOperation={awning.sideOfOperation}
                          explicitEdit={isDualMount(awning)}
                          awning={awning}
                          fieldWidthMessage={"Field width is outside of headbox/guiderail excluding end-caps"}
                      />

                      <TextBoxControl
                          isDebounce={true}
                          debounceeTimeOut={300}
                          onChange={(e)=> this.handleSintesiFieldUpdate(e, v => TextInputParseInt(v.target.value))}
                          labelText="Horizontal Projection (is back of headbox to end of guiderail)"
                          value={awning.sintesi.horizontalProjection ? awning.sintesi.horizontalProjection.toString() : ''}
                          name="horizontalProjection"
                          type="number"
                          validationMessages={validationMessages}
                      />                      

                      <SelectControl
                          validationMessages={validationMessages}
                          labelText="Installation option"
                          name="instBracketPermutationId"
                          options={this.state.fieldInstallationOptions}
                          simpleValue={true}
                          onChange={(val) => {
                            
                            let newAwning = {
                              ...awning,
                              fieldConfiguration: {
                                ...awning.fieldConfiguration,
                                instBracketPermutationId: val,
                              }
                            };
                            
                            this.setState({
                              awning: {
                                ...awning,
                                fieldConfiguration: {
                                  ...awning.fieldConfiguration,
                                  instBracketPermutationId: val,
                                },
                               sintesi: (hasInstallationPosts(newAwning, this.state.fieldInstallationOptions) ? 
                                    { ...awning.sintesi, hasFrontGutter: true}
                                   : { ...awning.sintesi, hasFrontGutter: null}),
                              }}, this.raiseAwningChanged);
                          }}
                          value={awning.fieldConfiguration ? awning.fieldConfiguration.instBracketPermutationId : awning.instBracketPermutationId}
                          placeholder="Select Installation Option"
                          valueKey={'id'}
                          labelKey={'description'}
                      />
                      
                      {hasPosts && isRoofingSystem &&
                      <div className="alert alert-info" role="alert" style={{marginLeft: '-15px'}}>
                        Please download post information for this configuration <a href={`/order-forms/15.10_ST_Classic_-_Base_Plates_Options_v.19.pdf`} target={'_blank'}>here</a>
                      </div>
                      }

                      <RadioControlGroup
                          name={`sintesi_hasFrontGutter_${awning.id}`}
                          onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v => TextInputParseBool(v.target.value))}
                          value={awning.sintesi.hasFrontGutter}
                          labelText={`Include Front Gutter`}
                          validationMessages={sintesiValidationMessages}
                          options={hasPosts ? Yes : YesOrNo}
                          visible={isRoofingSystem}
                          validationName={"hasFrontGutter"}
                      />

                      <TextBoxControl
                          isDebounce={true}
                          debounceeTimeOut={300}
                          onChange={(e)=> this.handleSintesiFieldUpdate(e, v => TextInputParseInt(v.target.value))}
                          labelText="Back Height"
                          value={awning.sintesi.backHeight}
                          name="backHeight"
                          type="number"
                          validationMessages={sintesiValidationMessages}
                      />                     
                      
                      {hasPosts ? <TextBoxControl
                          isDebounce={true}
                          debounceeTimeOut={300}
                          onChange={e => {
                              this.handlFieldChange(e);
                              this.handleSintesiFieldUpdate( {...e, target: {...e.target, name: 'frontHeight'}}, v => TextInputParseInt(v.target.value));
                            }
                          }
                          labelText="Post height (H2) default 2250mm"
                          value={awning.installationBracketLength}
                          name="installationBracketLength"
                          type="number"
                          validationMessages={validationMessages}
                      /> : <TextBoxControl
                          isDebounce={true}
                          debounceeTimeOut={300}
                          onChange={(e)=> this.handleSintesiFieldUpdate(e, v => TextInputParseInt(v.target.value))}
                          labelText={"Front Height"}
                          value={awning.sintesi.frontHeight}
                          name="frontHeight"
                          type="number"
                          validationMessages={sintesiValidationMessages}
                      />}

                      <TextBoxControl
                          isDebounce={true}
                          debounceeTimeOut={300}
                          onChange={(e)=> this.handleSintesiFieldUpdate(e, v => TextInputParseInt(v.target.value))}
                          labelText="Fall (back to front)"
                          value={awning.sintesi.fallBackToFront}
                          name="fallBackToFront"
                          type="number"
                          validationMessages={sintesiValidationMessages}
                      />

                      <TextBoxControl
                          isDebounce={true}
                          debounceeTimeOut={300}
                          onChange={(e)=> this.handleSintesiFieldUpdate(e, v => TextInputParseInt(v.target.value))}
                          labelText="Front Differential (left to right)"
                          value={awning.sintesi.frontDifferential}
                          name="frontDifferential"
                          type="number"
                          validationMessages={sintesiValidationMessages}
                      />

                      { awning.projection > 0 &&
                      <div className={`alert alert-${isNullEmptyOrWhitespace(validationMessages.productLookupDataId) ? 'info' : 'danger'}`} role="alert" style={{marginLeft: '-15px'}}>
                        <TextViewControl                            
                            labelText={"Finished Projection (calculated)"}
                            value={isNullEmptyOrWhitespace(validationMessages.productLookupDataId) ? awning.projection.toString() : `${awning.projection.toString()} exceeds max 6800 finished projection`} />
                      </div> }

                      { awning.sintesi.finishedAngle > 0 &&
                      <div className="alert alert-info" role="alert" style={{marginLeft: '-15px'}}>
                        <TextViewControl
                            labelText={"Finished Angle (calculated)"}
                            value={awning.sintesi.finishedAngle.toString() + " degrees"} />
                      </div> }
                      
                      <RadioControlGroup
                          name={`sintesi_freeStandingType_${awning.id}`}
                          onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v=> TextInputParseInt(v.target.value))}
                          value={awning.sintesi.freeStandingType}
                          labelText='Freestanding'
                          validationMessages={sintesiValidationMessages}
                          options={SintesiFreeStandingTypes}
                          validationName={"freeStandingType"}
                      />

                      <RadioControlGroup
                          name={`sintesi_isQuatroFinish_${awning.id}`}
                          onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v=> TextInputParseBool(v.target.value))}
                          value={awning.sintesi.isQuatroFinish}
                          labelText='Quattro Finish'
                          validationMessages={sintesiValidationMessages}
                          options={YesOrNo}
                          validationName={"isQuatroFinish"}
                      />                                           
                        
                    </Aux>
                }

                <AwningSADimensionControl
                    awningId={awning.id}
                    onSteerAroundDimensionsUpdated={this.handleSteerAroundDimensionUpdate}
                    onAwningFieldUpdated={this.handlFieldChangeWithAwningId}
                    steerAroundTypes={this.state.frameFeatureSteerAroundTypes}
                    steerAroundDimensions={awning.steerAroundDimensions}
                    steerAroundType={parseInt(awning.frameFeatureSteerAroundType)}
                />

                {!isRoofingSystem && awning && <AwningFieldsControl
                    awningId={awning.id}
                    fieldConfiguration={awning.fieldConfiguration}
                    onFieldConfigurationUpdated={this.handleFrameFeatureFieldValueUpdate}
                    headboxWidth={headboxWidth}
                    sideOfOperation={awning.sideOfOperation}
                    explicitEdit={!awning ? false : (isSDSM(awning) || isScSz(awning) || isDualMount(awning))}
                    awning={awning}
                    fieldWidthMessage={isRoofingSystem ? "Field width is outside of headbox/guiderail excluding end-caps" : `Field widths are track centres (headbox width minus ${headboxWidth}mm)`}
                />}

                {awning.productId > 0 && isNullEmptyOrWhitespace(awning.fieldConfiguration) &&
                <TextBoxControl
                    isDebounce={true}
                    debounceeTimeOut={300}
                    onChange={(e) => this.handlFieldChange(e, (v, newState) => {
                      return {
                        ...newState,
                        awning: awning.productGroupId === PRODUCTGROUPID_CONSERVATORY ? newState.awning : getFrameFeatureDefaults(newState.awning, null, awning, order, selectedProduct),
                      };
                    })}
                    labelText={awning.productGroupId === PRODUCTGROUPID_CONSERVATORY && selectedProduct ? `Width (is field track centres, being headbox width minus ${selectedProduct.headboxWidth}mm)` : `Width`}
                    value={awning.width.toString()}
                    name="width"
                    type="number"
                    validationMessages={validationMessages}
                />}

                {awning.productGroupId === PRODUCTGROUPID_CONSERVATORY && <DiagramSD awning={awning} width={750}/>}

                {isRoofingSystem && <DiagramST awning={awning} width={750}/>}

                {(this.state.selectedProduct && awning.width > 0 &&
                    (awning.width >= this.state.selectedProduct.minWidthPriceWarning && awning.width <= this.state.selectedProduct.maxWidthPriceWarning)) &&
                <div className="form-group">
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> If Aluxor supplies
                    fabric, then additional fabric cost, for awnings to 6000. <br/>Standard cut-off
                    is {this.state.selectedProduct.minWidthPriceWarning}.</label>
                </div>}                

                { !isRoofingSystem &&
                (selectedProduct && !selectedProduct.fixedProjection ?
                    <TextBoxControl
                        isDebounce={true}
                        debounceeTimeOut={300}
                        onChange={(e) => this.handlFieldChange(e, (v, newState) => {
                          return {
                            ...newState,
                            awning: awning.productGroupId === PRODUCTGROUPID_CONSERVATORY ? newState.awning : getFrameFeatureDefaults(newState.awning, null, awning, order, selectedProduct),
                          };
                        })}
                        labelText="Projection (is back of headbox to end of guiderail)"
                        value={awning.projection ? awning.projection.toString() : ''}
                        name="projection"
                        type="number"
                        validationMessages={validationMessages}
                        disabled={!IsNullOrUndefined(awning.steerAroundDimensions)}
                    /> :
                    <RadioControlGroup
                        name={`projection_${awning.id}`}
                        onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, newState) => {
                          return {
                            ...newState,
                            awning: awning.productGroupId === PRODUCTGROUPID_CONSERVATORY ? newState.awning : getFrameFeatureDefaults(newState.awning, null, awning, order, selectedProduct),
                          };
                        }, "number")}
                        value={awning.projection}
                        labelText='Projection'
                        validationMessages={validationMessages}
                        options={this.state.projections}
                    />
                ) }

                <ProductInformationControl isOversquare={this.state.oversquare} id={`productInfo3_${awning.number}`}/>

                <RadioControlGroup
                    name={`isAssembled_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e)}
                    value={awning.isAssembled}
                    labelText='Joiner'
                    validationMessages={validationMessages}
                    options={this.state.joinerOptions}
                />

                <RadioControlGroup
                    name={`driveMechanismId_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, newState) => {
                      return {
                        ...newState, awning: {
                          ...newState.awning,
                          automationCableId: null,
                          automationSensorType: null,
                          motionSensorColour: null,
                          mocoType: null,
                          motorId: null,
                          wallSwitch: isHardwired(newState.awning) ? 'Wall Switch' : null,
                          remote: null,
                          desiredRemoteColour: null,
                          remoteParentAwningNumber: null,
                        }
                      };
                    }, "number")}
                    value={awning.driveMechanismId}
                    labelText='Drive Mechanism'
                    validationMessages={validationMessages}
                    options={driveMechanisms}
                    clearSelectionText={awning.number > 1 ? 'Same as awning 1' : ''}
                    onClearSelection={awning.number > 1 ? (e) => this.setDriveSameAsAwning(e, 1) : undefined}
                />

                {OrganisationOrderAwningSupplyOwnMotor(order, awning) === true &&
                <div className="pi-row" style={{marginLeft: '-30px'}}>
                  <div className="pi-col-sm-12" style={{fontWeight: 'bold', color: '#f58b3c'}}>* Motor and controller to
                    be sent to Aluxor for assembly
                  </div>
                </div>}


                <SelectControl
                    validationMessages={validationMessages}
                    labelText="Motor"
                    name="motorId"
                    options={this.state.motors}
                    simpleValue={true}
                    onChange={(val) => this.handleSelectChange('motorId', val, (v) => v)}
                    value={awning.motorId}
                    placeholder="Select Motor"
                    valueKey={'id'}
                    labelKey={'description'}
                />

                {awning.wallSwitch &&
                <TextViewControl boldLabel={true} style={{marginLeft: '-30px'}} labelText={`* Includes wall switch`}
                                 value={''}/>}        

                <RadioControlGroup
                    name={`crankHandleId_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, null, "number")}
                    value={awning.crankHandleId}
                    labelText="Crank Handle"
                    validationMessages={validationMessages}
                    options={this.state.crankHandles}
                    valueKey={'id'}
                    labelKey={'description'}
                />

                <RadioControlGroup
                    name={`sideOfOperation_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e)}
                    value={awning.sideOfOperation}
                    labelText={isNullEmptyOrWhitespace(awning.sideOfOperation) ? "Side of Operation (When looking from the outside back towards the headbox)": `Side of Operation (${awning.sideOfOperation} side when looking from the outside back towards the headbox)`}
                    validationMessages={validationMessages}
                    options={this.state.sideOfOperations}
                    visible={IsNullOrUndefined(awning.fieldConfiguration) && awning.productLookupDataId > 0}
                    toolTip={isNullEmptyOrWhitespace(awning.sideOfOperation) ? "When looking from the outside back towards the headbox" : `${awning.sideOfOperation} side when looking from the outside back towards the headbox`}
                />

                <RadioControlGroup
                    name={`middleArmSide_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, null, "number")}
                    value={awning.middleArmSide}
                    labelText={"Third Arm Side (For a 3 arm awning the middle arm can be fitted on the Left or Right side)"}
                    validationMessages={validationMessages}
                    options={MiddleArmOptions}
                    visible={awning.numberOfArms === 3 && awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM}          
                    toolTip={"For a 3 arm awning the middle arm can be fitted on the Left or Right side"}
                />
                
                <RadioControlGroup
                    name={`remote_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, newState) => {
                      if (v !== REMOTE_MULTICHANNEL) {
                        return {...newState, awning: {...newState.awning, remoteParentAwningNumber: null}};
                      }
                      return newState;
                    }, "number")}
                    value={awning.remote}
                    labelText="Remote Controls"
                    validationMessages={validationMessages}
                    options={!IsNullOrUndefined(awning.fieldConfiguration) && awning.fieldConfiguration.numberOfFields > 1 ? this.state.remotes.filter(x => x.value === REMOTE_MULTICHANNEL) : this.state.remotes}
                    visible={this.state.remotes.length > 0 && isNullEmptyOrWhitespace(awning.automationSensorType)}
                />

                <RadioControlGroup
                    name={`remoteParentAwningNumber_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, newState) => {
                      return {...newState, awning: {...newState.awning, desiredRemoteColour: null}};
                    }, "number")}
                    value={awning.remoteParentAwningNumber}
                    labelText="Join Multi Remote Awning"
                    validationMessages={validationMessages}
                    options={this.getRemoteParentOptions()}
                    clearSelectionText={'Clear selection'}
                    onClearSelection={(e) => {
                      e.preventDefault();
                      this.setState({awning: {...awning, remoteParentAwningNumber: null}})
                    }}
                />

                <RadioControlGroup
                    name={`desiredRemoteColour_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e)}
                    value={awning.desiredRemoteColour}
                    labelText="Remote Colour"
                    validationMessages={validationMessages}
                    options={this.state.remoteColours}
                    visible={this.state.remoteColours.length > 0 && isNullEmptyOrWhitespace(awning.automationSensorType) && isNullEmptyOrWhitespace(awning.remoteParentAwningNumber)}
                />

                {(this.state.remoteColours.length > 0 && isNullEmptyOrWhitespace(awning.automationSensorType) && isNullEmptyOrWhitespace(awning.remoteParentAwningNumber))
                  && (!isNullEmptyOrWhitespace(awning.desiredRemoteColour) && awning.desiredRemoteColour !== 'White') &&
                <div className="form-group">
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Somfy White handsets in stock. Delays on other Somfy colours until October 2021</label>
                </div>}
                
                {!IsNullOrUndefined(awning.desiredRemoteColour) && awning.desiredRemoteColour !== 'White' &&
                <div className="form-group" style={{marginTop: '-30px'}}>
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Non-white remote
                    colour will attract a surcharge</label>
                </div>}

                {!IsConservatoryOrRoofSystem(awning) &&
                <Aux>
                  {this.frameFeaturesControl()}
                  {(awning.numberOfCradles + awning.additionalCradles) > 0
                  && this.state.frameFeatures.length > 0 && this.state.frameFeatures.findIndex(x => x.id === FRAME_FEATURE_SELECTAPITCH) < 0 &&
                  <div className="form-group">
                    <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Selectapitch is not available as the awning is fitted with a cradle</label>
                  </div>
                  }
                </Aux>
                }


                {(awning.frameFeature === FRAME_FEATURE_SELECTAPITCH) &&
                <div className="form-group">
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Cradle support cannot
                    be supplied with this option</label>
                </div>}

                <RadioControlGroup
                    name={`drop_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, newState) => {
                      return {
                        ...newState, awning: {
                          ...newState.awning,
                          frameFeatureFabricBrandId: null,
                          frameFeatureFabricId: null,
                        }
                      };
                    }, "number")}
                    value={awning.drop}
                    labelText="Drop"
                    validationMessages={validationMessages}
                    options={this.state.drops}
                />

                <RadioControlGroup
                    name={`frameFeatureDriveMechanismId_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, null, "number")}
                    value={awning.frameFeatureDriveMechanismId}
                    labelText="Frame Feature Drive"
                    validationMessages={validationMessages}
                    options={this.state.frameFeatureDriveMechanisms}
                />

                <RadioControlGroup
                    name={`frameFeatureCrankHandleId_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, null, "number")}
                    value={awning.frameFeatureCrankHandleId}
                    labelText="Frame Feature Crank Handle"
                    validationMessages={validationMessages}
                    options={this.state.frameFeatureCrankHandles}
                    valueKey={'id'}
                    labelKey={'description'}
                />

                <RadioControlGroup
                    name={`frameFeatureSideOfOperation_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e)}
                    value={awning.frameFeatureSideOfOperation}
                    labelText='Frame Feature Side of Operation'
                    validationMessages={validationMessages}
                    options={this.state.frameFeaturSideOfOperations}
                    toolTip={"As looking back at the awning"}
                />

                <ColourSelectorControl
                    onChange={(val) => this.handleColourChange('frame', val)}
                    label='Frame'
                    customColours={this.state.coloursCustom}
                    standardColours={this.state.coloursStandard}
                    selectedStandardColourId={awning.frameColourId}
                    selectedCustomColourId={awning.frameCustomColourId}
                    name={'frameColourId'}
                    validationMessages={validationMessages}
                    disabled={false}
                    awningId={awning.id}
                    linkText={awning.number > 1 ? 'Same as awning 1' : ''}
                    onLink={awning.number > 1 ? (e) => this.setColourSameAsAwning(e, 1) : undefined}
                    singleColourSelection={this.state.selectedProduct ? this.state.selectedProduct.singleColourSelection : null}
                />

                { selectedProduct && isRoofingSystem && !IsNullOrUndefined(awning.sintesi) &&
                <ColourSelectorControl                    
                    onChange={(val) => this.handleSintesiColourUpdate(val, 'windbreak')}
                    label={'Windbreak'}
                    customColours={this.state.coloursCustom}
                    standardColours={this.state.coloursStandard}
                    selectedStandardColourId={awning.sintesi.windbreakColourId}
                    selectedCustomColourId={awning.sintesi.windbreakCustomColourId}
                    name={'windbreakColourId'}
                    validationMessages={sintesiValidationMessages}
                    awningId={awning.id}
                    frameStandardColourId={awning.frameColourId}
                    frameCustomColourId={awning.frameCustomColourId}
                    singleColourSelection={this.state.selectedProduct ? this.state.selectedProduct.singleColourSelection : null}                    
                    showSameAsFrame={true}
                    disabled={false}
                /> }
                
                <RadioControlGroup
                    name={`includePelmet_${awning.id}`}
                    onChange={(x, e) => {
                      this.handlFieldChangeWithAwningId(e, (v, n) => {
                        let boolVal = x.simpleValue;
                        return {
                          ...n, awning: {
                            ...n.awning,
                            pelmetColourId: boolVal ? n.awning.pelmetColourId : null,
                            pelmetCustomColourId: boolVal ? n.awning.pelmetCustomColourId : null,
                            includePelmet: boolVal,
                            pelmetType: boolVal ? n.awning.pelmetType : null,
                          }
                        };
                      })
                    }}
                    value={awning.includePelmet}
                    labelText={`Include ${pelmetName}`}
                    validationMessages={validationMessages}
                    options={this.state.pelmetOptions}
                    visible={(!IsNullOrUndefined(this.state.selectedProduct) &&
                        ((this.state.selectedProduct.hasPelmet &&
                            (
                                ((order.orderStatus === ORDER_STATUS_QUOTE && user.hasAccessTag(edit_order_can_deselect_pelmet_quote)) ||
                                    (order.orderStatus === ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED && user.hasAccessTag(edit_order_can_deselect_pelmet_amendment_requested)))
                                && !this.state.awning.productId === PRODUCTID_TOGA
                            ) || this.state.selectedProduct.pelmetDecision))) && awning.productLookupDataId > 0}
                />

                {(awning.includePelmet && (awning.productId === PRODUCTID_S1 || awning.productId === PRODUCTID_ECO)) &&
                <div className="alert alert-info" role="alert" style={{marginLeft: '-15px'}}><i className={'fa fa-info-circle'} /> {pelmetName} is 20mm
                  greater than awning width</div>
                }

                <RadioControlGroup
                    name={`pelmetType_${awning.id}`}
                    onChange={(x, e) => {
                      this.handlFieldChangeWithAwningId(e, (v, n) => {                        
                        return {
                          ...n, awning: {
                            ...n.awning,
                            pelmetType: x.simpleValue,
                          }
                        };
                      })
                    }}
                    value={awning.pelmetType}
                    labelText={`${pelmetName} Type`}
                    validationMessages={validationMessages}
                    options={PelmetTypes}
                    visible={IsPelmetTypeVisible(this.state.organisation, awning)}
                />                

                <ColourSelectorControl
                    disabled={!awning.includePelmet}
                    onChange={(val) => this.handleColourChange('pelmet', val)}
                    label={pelmetName}
                    customColours={this.state.coloursCustom}
                    standardColours={this.state.coloursStandard}
                    selectedStandardColourId={awning.pelmetColourId}
                    selectedCustomColourId={awning.pelmetCustomColourId}
                    name={'pelmetColourId'}
                    validationMessages={validationMessages}
                    awningId={awning.id}
                    frameStandardColourId={awning.frameColourId}
                    frameCustomColourId={awning.frameCustomColourId}
                />

                {/*<RadioControlGroup
          name={`includeFlashing_${awning.id}`}
          onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, n)=> {
            let boolVal = v == 'true';
            return {
              ...n, awning: {
                ...n.awning,
                flashingColourId: boolVal ? n.awning.flashingColourId : null,
                flashingCustomColourId: boolVal ? n.awning.flashingCustomColourId : null,
                flashingDepth: boolVal ? n.awning.flashingDepth : null,
                includeFlashing: boolVal,
              }
            };
          }) }
          value={awning.includeFlashing}
          labelText='Include Flashing'
          validationMessages={validationMessages}
          options={this.state.flashingOptions}
        /> */}

                <RadioControlGroup
                    name={`flashingType_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, n) => {
                      let boolVal = v > 0;
                      return {
                        ...n, awning: {
                          ...n.awning,
                          flashingColourId: boolVal ? n.awning.frameColourId : null,
                          flashingCustomColourId: boolVal ? n.awning.frameCustomColourId : null,
                          awningFlashing: {
                            ...this.state.awning.awningFlashing,
                            height: v === 1 ? 130 : 0
                          }
                        }
                      };
                    }, "number")
                    }
                    value={awning.flashingType}
                    labelText='Include Flashing'
                    validationMessages={validationMessages}
                    options={this.state.flashingOptions}
                />

                {(awning.flashingType === 2) &&
                <div className="form-group">
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Please send details to
                    Aluxor</label>
                </div>}

                {parseInt(awning.flashingType) === 1 && <TextBoxControl
                    name={`flashingHeight_${awning.id}`}
                    onChange={(x) => {
                      let v = parseInt(x.target.value);
                      if (v > 0 && v <= 420) {
                        this.setState({
                          awning: {
                            ...this.state.awning,
                            awningFlashing: {...this.state.awning.awningFlashing, height: v},
                          }
                        }, this.raiseAwningChanged);
                      }
                    }}
                    labelText="Flashing Height (standard 130 max. 420)"
                    value={awning.awningFlashing.height.toString()}
                    type="number"
                    image={`/img/powder_coating_sheet/FA_FLASH.png`}
                />}

                {parseInt(awning.flashingType) === FLASHINGTYPE_WallSeal &&
                <div className="form-group">
                  <img
                      src={`/img/powder_coating_sheet/${this.state.awning.productId === PRODUCTID_OLYMPUS ? 'OL_SEAL' : 'DS_SEAL'}.png`}
                      style={{paddingLeft: '10px', width: '100px', marginLeft: '-10px'}}/>
                </div>
                }

                <ColourSelectorControl
                    disabled={awning.flashingType == null || awning.flashingType == 0}
                    onChange={(val) => this.handleColourChange('flashing', val)}
                    label='Flashing'
                    customColours={this.state.coloursCustom}
                    standardColours={this.state.coloursStandard}
                    selectedStandardColourId={awning.flashingColourId}
                    selectedCustomColourId={awning.flashingCustomColourId}
                    name={'flashingColourId'}
                    validationMessages={validationMessages}
                    awningId={awning.id}
                    frameStandardColourId={awning.frameColourId}
                    frameCustomColourId={awning.frameCustomColourId}
                    onlyFrameColour={awning.flashingType === FLASHINGTYPE_WallSeal 
                      || awning.flashingType === FLASHINGTYPE_Sintesi300  
                      || awning.flashingType === FLASHINGTYPE_Sintesi500}
                    colorBondColourLookup={true}
                />

                <RadioControlGroup
                    name={`fabricSuppliedBy_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, n) => {
                      return {
                        ...n, awning: {
                          ...n.awning,
                          fabricBrandId: null,
                          fabricId: null,
                          frameFeatureFabricBrandId: null,
                          frameFeatureFabricId: null,
                        }
                      };
                    }, "number")}
                    labelText={`Fabric Choice${(awning.numberOfCradles + awning.additionalCradles) > 0 ? ' (mesh fabrics cannot be used with a cradle)' : ''}`}
                    validationMessages={validationMessages}
                    options={this.state.fabricSuppliers}
                    value={awning.fabricSuppliedBy}
                    clearSelectionText={awning.number > 1 ? 'Same as awning 1' : ''}
                    onClearSelection={awning.number > 1 ? (e) => this.setFabricSameAsAwning(e, 1) : undefined}
                />

                <SelectControl
                    validationMessages={validationMessages}
                    labelText="Fabric Brand"
                    disabled={!awningRequiresFabric}
                    name="fabricBrandId"
                    options={this.state.fabricBrands}
                    simpleValue={true}
                    onChange={(val) => this.handleSelectChange('fabricBrandId', val, (v) => v)}
                    value={awning.fabricBrandId}
                    placeholder="Select Fabric Brand"
                    valueKey={'id'}
                    labelKey={'description'}
                    toolTip={awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM ? 
                        "Soltis / Mesh PVC fabrics cannot be used on awnings where a cradle is fitted due to increased risk of marking or damage to the fabric. Aluxor will not warrant awnings fitted with fabrics out of specifications" :
                        awning.productGroupId === PRODUCTGROUPID_CONSERVATORY ? "Soltis / PVC fabric cannot be supplied for awnings with width or projection greater than 5500 due to spring operation constraints and fabric presentation issues, including increased risk of fabric creasing. Aluxor will not warrant awnings fitted with fabrics out of specifications" : ""}
                />

                { awning.fabricBrandId > 0 && awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM && <div className="alert alert-info" role="alert" style={{marginLeft: '-15px'}}>
                  If a fabric brand is not showing it is because the fabric cannot be used due to the awning width, projection or if it has a cradle
                </div> }

                {(awning.numberOfCradles + awning.additionalCradles) > 0 &&
                  <div className="alert alert-info" role="alert" style={{marginLeft: '-15px'}}>
                    Soltis / Mesh PVC fabrics cannot be used on awnings where a cradle is fitted due to increased risk of marking or damage to the fabric. Aluxor will not warrant awnings fitted with fabrics out of specifications
                  </div>
                }

                { awning.fabricBrandId > 0 && awning.productGroupId === PRODUCTGROUPID_CONSERVATORY && <div className="alert alert-info" role="alert" style={{marginLeft: '-15px'}}>
                  If a fabric brand is not showing it is because the fabric cannot be used due to the awning width or projection
                  <br />
                  <br />
                  Soltis / PVC fabric cannot be supplied for awnings with width or projection greater than 5500 due to spring operation constraints and fabric presentation issues, including increased risk of fabric creasing. Aluxor will not warrant awnings fitted with fabrics out of specifications
                </div> }

                { awning.fabricBrandId > 0 && awning.productGroupId === PRODUCTGROUPID_ROOFING_SYSTEMS && <div className="alert alert-info" role="alert" style={{marginLeft: '-15px'}}>
                  If a fabric brand is not showing it is because the fabric cannot be used with this product
                </div> }
                

                {!IsNullOrUndefined(awning.fabricBrandId) && this.state.fabricBrands && this.state.fabricBrands.filter(x => x.id === awning.fabricBrandId && x.notRecommended).length > 0 &&
                <div className="form-group">
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> This Fabric is NOT
                    RECOMMENDED with this product</label>
                </div>
                }

                {!IsNullOrUndefined(awning.fabricBrandId) && this.state.fabricBrands && this.state.fabricBrands.filter(x => x.id === awning.fabricBrandId && x.surcharge > 0).length > 0 &&
                <div className="form-group">
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> A surcharge applies to this fabric</label>
                </div>
                }

                <SelectControl
                    validationMessages={validationMessages}
                    labelText="Fabrics"
                    disabled={!awningRequiresFabric}
                    name="fabricId"
                    options={this.state.fabrics}
                    simpleValue={true}
                    onChange={(val) => this.handleSelectChange('fabricId', val, (v) => v)}
                    value={awning.fabricId}
                    valueKey={'id'}
                    labelKey={'description'}
                    placeholder="Select Fabric"
                />

                { (selectedFabric && !selectedFabric.isStocked) && 
                <div className="form-group">
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Fabric not stocked in Australia. Available on request.</label>
                </div>
                }

                { (selectedFabric && selectedFabric.isSupplierOutOfStock) &&                
                <div className="form-group">
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Fabric is currently out of stock at supplier{selectedFabric.expectedStockDate ? ', expected ' + moment(selectedFabric.expectedStockDate).format('MMM Do') : ', expected date unknown'}</label>
                </div>
                }
                {(awningRequiresFabric && (this.state.selectedProduct && this.state.selectedProduct.includeValance == 2)
                    && this.state.oversquare) && <div className="form-group">
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Fabric valance
                    replaces aluminium skirt on OS</label>
                </div>}

                <RadioControlGroup
                    name={`fabricValance_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, n) => {
                      return {
                        ...n, awning: {
                          ...n.awning,
                          trimBiasColourId: null,
                        }
                      }}, "number")}
                    labelText="Valance"
                    validationMessages={validationMessages}
                    options={this.state.valances}
                    visible={AllowedValance(awning, order, selectedProduct)}
                    value={awning.fabricValance}
                    toolTip={"Valance depth: 180mm for standard and 210mm for oversquare"}
                />


                <SelectControl
                    validationMessages={validationMessages}
                    labelText="Trim Bias Colour"
                    name="trimBiasColourId"
                    options={this.state.trimBiasColours}
                    simpleValue={true}
                    onChange={(val) => this.handleSelectChange('trimBiasColourId', val, (v) => v)}
                    value={awning.trimBiasColourId}
                    placeholder="Select Trim Bias Colour"
                />

                <SelectControl
                    validationMessages={validationMessages}
                    labelText="Vario Volant Fabric Brand"
                    disabled={!awningRequiresFabric}
                    name="frameFeatureFabricBrandId"
                    options={this.state.frameFeatureFabricBrands}
                    onChange={(val) => this.handleSelectChange('frameFeatureFabricBrandId', val, (v) => v.value)}
                    value={awning.frameFeatureFabricBrandId}
                    placeholder="Select Frame Feature Fabric Brand"
                />

                <SelectControl
                    validationMessages={validationMessages}
                    labelText="Vario Volant Fabric"
                    disabled={!awningRequiresFabric}
                    name="frameFeatureFabricId"
                    options={this.state.frameFeatureFabrics}
                    simpleValue={true}
                    onChange={(val) => this.handleSelectChange('frameFeatureFabricId', val, (v) => v)}
                    value={awning.frameFeatureFabricId}
                    valueKey={'id'}
                    labelKey={'description'}
                    placeholder="Select Fabric"
                />

                { (selectedFrameFeatureFabric && !selectedFrameFeatureFabric.isStocked) &&
                <div className="form-group">
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Fabric not stocked in Australia. Available on request.</label>
                </div>
                }
                { (selectedFrameFeatureFabric && selectedFrameFeatureFabric.isSupplierOutOfStock) &&                
                <div className="form-group">
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Fabric is currently out of stock at supplier{selectedFrameFeatureFabric.expectedStockDate ? ', expected ' + moment(selectedFrameFeatureFabric.expectedStockDate).format('MMM Do') : ', expected date unknown'}</label>
                </div>
                }

                <RadioControlGroup
                    name={`mountingBracketId_${awning.id}`}
                    onChange={(x, e) => {
                      this.handlFieldChangeWithAwningId(e, (v, n) => {
                        return {
                          ...n, awning: {
                            ...n.awning,
                            mountingBracketLength: v === MOUNTING_EXTENSION ? 97 : v === MOUNTING_BACKPOSTS ? 2250 : null,
                            additionalMountingBrackets: 0,
                            installationBracketId: null,
                            installationBracketTypeId: null,
                          },
                          installationBrackets: [],
                        };
                      }, "number");
                    }}
                    labelText={MountingBracketName(awning)}
                    validationMessages={validationMessages}
                    options={this.state.mountingBrackets}
                    value={awning.mountingBracketId}
                    valueKey={'id'}
                    labelKey={'description'}
                    imageUrlSelector={(x) => x.pcRef ? `/img/powder_coating_sheet/${x.pcRef}.png` : `/img/powder_coating_sheet/blank.png`}
                />

                {(awning.mountingBracketId === MOUNTING_EXTENSION || awning.mountingBracketId === MOUNTING_BACKPOSTS) &&
                <TextBoxControl
                    isDebounce={true}
                    debounceeTimeOut={300}
                    onChange={this.handlFieldChange}
                    labelText={awning.mountingBracketLength === MOUNTING_EXTENSION ? 'Mounting Bracket Extension Length (mm) 97mm default' : 'Mounting Height (mm)'}
                    value={awning.mountingBracketLength}
                    name="mountingBracketLength"
                    type="number"
                    validationMessages={validationMessages}
                />}

                {this.state.selectedProduct && this.state.selectedProduct.hasAdditionalMountingBrackets
                && <TextBoxControl
                    onChange={this.handlFieldChange}
                    labelText={awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM ? `Additional Mounting Brackets (${awning.numberOfMountingBracket} bracket${awning.numberOfMountingBracket > 1 ? "s" : ""} already included)` : "Additional Mounting Brackets"}
                    value={awning.additionalMountingBrackets.toString()}
                    name="additionalMountingBrackets"
                    type="number"
                    validationMessages={validationMessages}
                />}

                {this.state.selectedProduct &&
                !this.state.selectedProduct.hasAdditionalMountingBrackets && (awning.productLookupDataId > 0 && awning.numberOfMountingBracket <= 0)
                && requireAdditionalBracketCount
                && <TextBoxControl
                    onChange={this.handlFieldChange}
                    labelText="Number of Mounting Brackets"
                    value={awning.additionalMountingBrackets.toString()}
                    name="additionalMountingBrackets"
                    type="number"
                    validationMessages={validationMessages}
                />}


                <RadioControlGroup
                    name={`installationBracketTypeId_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, n) => {
                      return {
                        ...n, awning: {
                          ...n.awning,
                          installationBracketId: null,
                        }
                      };
                    }, "number")}
                    labelText={`Installation Bracket Type (optional)`}
                    validationMessages={validationMessages}
                    options={this.state.installationBracketTypes}
                    value={awning.installationBracketTypeId}
                    onClearSelection={(e) => {
                      e.preventDefault();
                      this.setState({
                        awning:
                            {
                              ...this.state.awning,
                              installationBracketId: null,
                              installationBracketTypeId: null,
                            },
                        installationBrackets: [],
                      }, this.raiseAwningChanged);
                    }}
                    valueKey={'id'}
                    labelKey={'description'}
                    imageUrlSelector={(x) => x.pcRef ? `/img/powder_coating_sheet/${x.pcRef}.png` : `/img/powder_coating_sheet/blank.png`}
                    visible={this.state.installationBracketTypes && this.state.installationBracketTypes.length > 1}
                />

                {(awning.installationBracketTypeId === 5) &&
                <div className="form-group">
                  <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> Please send details to
                    Aluxor</label>
                </div>}

                <RadioControlGroup
                    name={`installationBracketId_${awning.id}`}
                    onChange={(x, e) => {
                      this.handlFieldChangeWithAwningId(e, null, "number");
                    }}
                    labelText={awning && awning.mountingBracketId === SM_FASCIA_BRACKET_ID ? 'Fascia Bracket Type' : this.state.installationBracketTypes && this.state.installationBracketTypes.length === 1 ? `${this.state.installationBracketTypes[0].description} Installation Bracket` : `Installation Bracket`}
                    validationMessages={validationMessages}
                    options={this.state.installationBrackets}
                    value={awning.installationBracketId}
                    valueKey={'id'}
                    labelKey={'description'}
                />

                { !isRoofingSystem && <SelectControl
                    validationMessages={validationMessages}
                    labelText="Installation option"
                    name="instBracketPermutationId"
                    options={this.state.fieldInstallationOptions}
                    simpleValue={true}
                    onChange={(val) => {
                      if (awning.fieldConfiguration) {
                        this.setState({
                          awning: {
                            ...awning,
                            fieldConfiguration: {
                              ...awning.fieldConfiguration,
                              instBracketPermutationId: val,
                            }
                          }
                        }, this.raiseAwningChanged);
                      } else {
                        this.setState({
                          awning: {
                            ...awning,
                            instBracketPermutationId: val,
                          }
                        }, this.raiseAwningChanged);
                      }
                    }}
                    value={awning.fieldConfiguration ? awning.fieldConfiguration.instBracketPermutationId : awning.instBracketPermutationId}
                    placeholder="Select Installation Option"
                    valueKey={'id'}
                    labelKey={'description'}
                /> }

                {hasPosts && !isRoofingSystem && <TextBoxControl
                    isDebounce={true}
                    debounceeTimeOut={300}
                    onChange={this.handlFieldChange}
                    labelText="Post height (H3) default 2250mm"
                    value={awning.installationBracketLength}
                    name="installationBracketLength"
                    type="number"
                    validationMessages={validationMessages}
                />}                

                { (
                    (CanHaveWedges(awning) && awning.productLookupDataId > 0) 
                || (this.state.selectedProduct // extra cradles
                    && this.state.selectedProduct.hasCradles
                    && awning.frameFeature !== FRAME_FEATURE_SELECTAPITCH) 
                || (awning.automationSensorType == null // stabilisers
                        && awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM
                        && awning.productLookupDataId > 0)
                || (this.state.ledLightings.length > 0) //led
                || isSDorSM(awning) // rain cover
                ) && 
                
                <div className={"pi-row"}>
                  <div className={"pi-col-12"}>
                    <h6>Accessories</h6>
                  </div>
                </div> }

                { CanHaveWedges(awning) && awning.productLookupDataId > 0 && <CheckBoxControl
                    onChange={this.handlFieldChange}
                    labelText="Include Bracket Wedges"
                    value={awning.includeWedges}
                    name="includeWedges"
                    style={{marginLeft: '-15px'}}
                    visible={CanHaveWedges(awning) && awning.productLookupDataId > 0}
                /> }

                {this.state.selectedProduct
                && this.state.selectedProduct.hasCradles
                && awning.frameFeature !== FRAME_FEATURE_SELECTAPITCH
                && <CheckBoxControl
                    onChange={(e) => this.handlFieldChange(e, (v, newState) => {
                      return {
                        ...newState, awning: {
                          ...newState.awning,
                          hasAdditionalCradles: v,
                          additionalCradles: 0,
                        }
                      };
                    })}
                    labelText="Extra Cradles"
                    value={awning.hasAdditionalCradles}
                    name="hasAdditionalCradles"
                    style={{marginLeft: '-15px'}}
                />}

                {awning.hasAdditionalCradles && <TextBoxControl
                    onChange={(e) => this.handlFieldChange(e, (v, newState) => {
                      return {
                        ...newState, awning: {
                          ...newState.awning,
                          fabricBrandId: null,
                          fabricId: null,
                          frameFeatureFabricBrandId: null,
                          frameFeatureFabricId: null,
                        }
                      };
                    })}
                    labelText="Number of Extra Cradles"
                    value={awning.additionalCradles.toString()}
                    name="additionalCradles"
                    type="number"
                    validationMessages={validationMessages}
                />}

                {awning.automationSensorType == null
                && awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM
                && awning.productLookupDataId > 0 &&
                <CheckBoxControl
                    onChange={this.handlFieldChange}
                    labelText={`Stabiliser Poles (* can't be combined with automation sensors)`}
                    value={awning.includeStabiliserPoles}
                    name="includeStabiliserPoles"
                    style={{marginLeft: '-15px'}}                    
                /> }

                { (this.state.ledLightings.length > 0) && <CheckBoxControl
                    onChange={this.handlFieldChange}
                    labelText={`Led Lighting`}
                    value={awning.includeLed}
                    name="includeLed"
                    style={{marginLeft: '-15px'}}
                    visible={(this.state.ledLightings.length > 0)}
                /> }

                { isSDorSM(awning) && <CheckBoxControl
                    onChange={this.handlFieldChange}
                    labelText={`Include Rain Cover`}
                    value={awning.includeRainCover}
                    name="includeRainCover"
                    style={{marginLeft: '-15px'}}
                    visible={isSDorSM(awning)}
                /> }

                {awning.intermediateRoller > 0 && <RadioControlGroup
                    name={`additionalImRollerCount_${awning.id}`}
                    onChange={(x, e) => {
                      this.handlFieldChangeWithAwningId(e, (v, n) => {                        
                        return {
                          ...n, awning: {
                            ...n.awning,
                            additionalImRollerCount: parseInt(x.simpleValue),
                          }
                        };
                      })
                    }}
                    value={awning.additionalImRollerCount}
                    labelText={`Additional Intermediate Rollers (currently ${awning.intermediateRoller} roller${awning.intermediateRoller > 1 ? 's' : ''} included)`}
                    validationMessages={validationMessages}
                    options={AdditionalImRollers}
                    visible={awning.intermediateRoller !== null}
                /> }                

                {!IsNullOrUndefined(awning) && !IsNullOrUndefined(awning.sintesi) && this.state.selectedProduct &&
                <Aux>

                  <RadioControlGroup
                      name={`sintesi_additionalWindbreakCount_${awning.id}`}
                      onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v => TextInputParseInt(v.target.value))}
                      value={ awning.sintesi.additionalWindbreakCount }
                      labelText={`Additional Windbreaks`}
                      validationMessages={sintesiValidationMessages}
                      options={SintesAdditionalWindbreaks}
                      validationName={`additionalWindbreakCount`}
                  />
                  <RadioControlGroup
                      name={`sintesi_additionalSidePostCount_${awning.id}`}
                      onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v => TextInputParseInt(v.target.value))}
                      value={ awning.sintesi.additionalSidePostCount }
                      labelText={`Additional Side Post`}
                      validationMessages={sintesiValidationMessages}
                      options={SintesAdditionalSidePosts}
                      validationName={`additionalSidePostCount`}
                  />
                  <RadioControlGroup
                      name={`sintesi_additionalFrontPostCount_${awning.id}`}
                      onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v => TextInputParseInt(v.target.value))}
                      value={ awning.sintesi.additionalFrontPostCount }
                      labelText={`Additional Front Post`}
                      validationMessages={sintesiValidationMessages}
                      options={SintesAdditionalFrontPosts}
                      validationName={`additionalFrontPostCount`}
                  />
                  <RadioControlGroup
                      name={`sintesi_additionalBackPostCount_${awning.id}`}
                      onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v => TextInputParseInt(v.target.value))}
                      value={ awning.sintesi.additionalBackPostCount }
                      labelText={`Additional Back Post`}
                      validationMessages={sintesiValidationMessages}
                      options={SintesAdditionalBackPosts}
                      validationName={`additionalBackPostCount`}
                  />                  
                  
                  <SelectControl
                      validationMessages={sintesiValidationMessages}
                      labelText="Vertical Screens (select required sides)"
                      name="selectedVerticalScreenOptions"
                      options={SintesiVerticalScreenSides}
                      simpleValue={false}
                      multi={true}
                      onChange={(val) =>
                          this.handleSintesiFieldUpdate({
                            target: {
                              name: "selectedVerticalScreenOptions",
                              value: val
                            }
                          }, e => e.target.value, s => {
                            s.hasInfillPanelLeft = s.selectedVerticalScreenOptions.findIndex(x => x.label === 'Left') < 0 ? null : s.hasInfillPanelLeft;
                            s.hasInfillPanelRight = s.selectedVerticalScreenOptions.findIndex(x => x.label === 'Right') < 0 ? null : s.hasInfillPanelRight;
                            s.hasInfillPanelBack = s.selectedVerticalScreenOptions.findIndex(x => x.label === 'Back') < 0 ? null : s.hasInfillPanelBack;
                            if(s.selectedVerticalScreenOptions.length===0){
                              s.infillPanelMaterial = null;
                              s.verticalScreenIsManual = null;
                              s.verticalScreenFabricBrandId = null;
                              s.verticalScreenFabricId = null
                            }
                            return s;
                          })
                      }
                      value={awning.sintesi.selectedVerticalScreenOptions}
                      placeholder="Vertical Screen Sides"                      
                  />
                  
                  {awning.sintesi.verticalScreens.map(v => {
                    let sideName = SintesiVerticalScreenSides.find(x => x.value === v.sideType).label;
                    
                    return (<Aux key={`${awning.id}_${v.sideType}`}>
                      <div className={"row"} style={{marginLeft: "-30px", marginTop: "10px"}}><div className={"col-12"}><h4>Vertical Screen {sideName}</h4></div></div>
                      <TextBoxControl
                          isDebounce={true}
                          debounceeTimeOut={300}
                          onChange={(e) => this.handleVerticalScreenFieldUpdate(e, `verticalScreens_${v.sideType}_width`, v => TextInputParseInt(v))}
                          labelText={`Width (VS ${sideName})`}
                          value={v.width}
                          name={`verticalScreens_${v.sideType}_width`}
                          type="number"
                          validationMessages={sintesiValidationMessages}
                      />
                      <TextBoxControl
                          isDebounce={true}
                          debounceeTimeOut={300}
                          onChange={(e) => this.handleVerticalScreenFieldUpdate(e, `verticalScreens_${v.sideType}_projection`, v => TextInputParseInt(v))}
                          labelText={`Projection (VS ${sideName})`}
                          value={v.projection}
                          name={`verticalScreens_${v.sideType}_projection`}
                          type="number"
                          validationMessages={sintesiValidationMessages}
                      />
                      <RadioControlGroup
                          name={`${awning.id}_verticalScreens_${v.sideType}_sideOfOperation`}
                          validationName={`verticalScreens_${v.sideType}_sideOfOperation`}
                          onChange={(x, e) => this.handleVerticalScreenFieldUpdate(e, `verticalScreens_${v.sideType}_sideOfOperation`, v => v, 1)}
                          value={v.sideOfOperation}
                          labelText={`Side of Operation (VS ${sideName})`}
                          validationMessages={sintesiValidationMessages}
                          options={this.state.sideOfOperations}
                          visible={true}
                      />
                    </Aux>);
                  })}                  
                  
                  <RadioControlGroup
                      name={`sintesi_verticalScreenIsManual_${awning.id}`}
                      onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v => TextInputParseInt(v.target.value) === 1)}
                      value={ IsNullOrUndefined(awning.sintesi.verticalScreenIsManual) ? null : awning.sintesi.verticalScreenIsManual === true ? 1 : 0}
                      labelText={`Vertical Screen Operation`}
                      validationMessages={sintesiValidationMessages}
                      options={SintesiVerticalScreenDriveOperations}
                      visible={awning.sintesi.verticalScreens.length > 0}
                      validationName={`verticalScreenIsManual`}
                  />
                  { awning.sintesi.verticalScreens.length > 0 && <SelectControl
                      validationMessages={sintesiValidationMessages}
                      labelText="Vertical Screen Fabric Brand"                      
                      name="verticalScreenFabricBrandId"
                      options={this.state.sintesiVerticalFabricBrands}
                      simpleValue={true}
                      onChange={(val) => this.handleSintesiFieldUpdate( {target: {name: "verticalScreenFabricBrandId", value: val}} , e => e.target.value)}
                      value={awning.sintesi.verticalScreenFabricBrandId}
                      placeholder="Select Fabric Brand"
                      valueKey={'id'}
                      labelKey={'description'}
                  /> }

                  {!IsNullOrUndefined(awning.sintesi.verticalScreenFabricBrandId) && this.state.sintesiVerticalFabricBrands && this.state.sintesiVerticalFabricBrands.filter(x => x.id === awning.sintesi.verticalScreenFabricBrandId && x.surcharge > 0).length > 0 &&
                  <div className="form-group">
                    <label style={{color: '#ae2128'}}><i className={'fa fa-exclamation-triangle'}/> A surcharge applies to this fabric</label>
                  </div>
                  }

                  { awning.sintesi.verticalScreens.length > 0 && awning.sintesi.verticalScreenFabricBrandId > 0 && <SelectControl
                      validationMessages={sintesiValidationMessages}
                      labelText="Vertical Screen Fabric"                      
                      name="verticalScreenFabricId"
                      options={this.state.sintesiVerticalFabrics}
                      simpleValue={true}
                      onChange={(val) => this.handleSintesiFieldUpdate( {target: {name: "verticalScreenFabricId", value: val}} , e => e.target.value)}
                      value={awning.sintesi.verticalScreenFabricId}
                      valueKey={'id'}
                      labelKey={'description'}
                      placeholder="Select Fabric"
                  /> }

                  <RadioControlGroup
                      name={`sintesi_hasInfillPanelLeft_${awning.id}`}
                      onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v => TextInputParseBool(v.target.value), newSintesi => {
                        if(!newSintesi.hasInfillPanelBack && !newSintesi.hasInfillPanelRight && !newSintesi.hasInfillPanelLeft){
                          return {...newSintesi, infillPanelMaterial: null};
                        }
                        return newSintesi;
                      })}
                      value={awning.sintesi.hasInfillPanelLeft}
                      labelText={`Infill Panel Left`}
                      validationMessages={sintesiValidationMessages}
                      options={YesOrNo}
                      validationName={`hasInfillPanelLeft`}
                      visible={awning.sintesi.selectedVerticalScreenOptions.findIndex(x => x.label === 'Left') >= 0}                      
                  />
                  <RadioControlGroup
                      name={`sintesi_hasInfillPanelRight_${awning.id}`}
                      onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v => TextInputParseBool(v.target.value))}
                      value={awning.sintesi.hasInfillPanelRight}
                      labelText={`Infill Panel Right`}
                      validationMessages={sintesiValidationMessages}
                      options={YesOrNo}
                      validationName={`hasInfillPanelRight`}
                      visible={awning.sintesi.selectedVerticalScreenOptions.findIndex(x => x.label === 'Right') >= 0}
                  />
                  <RadioControlGroup
                      name={`sintesi_hasInfillPanelBack_${awning.id}`}
                      onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v => TextInputParseBool(v.target.value))}
                      value={awning.sintesi.hasInfillPanelBack}
                      labelText={`Infill Panel Back`}
                      validationMessages={sintesiValidationMessages}
                      options={YesOrNo}
                      validationName={`hasInfillPanelBack`}
                      visible={awning.sintesi.selectedVerticalScreenOptions.findIndex(x => x.label === 'Back') >= 0}
                  />
                  
                  {  (awning.sintesi.hasInfillPanelBack === true || awning.sintesi.hasInfillPanelRight === true || awning.sintesi.hasInfillPanelLeft === true) && <RadioControlGroup
                      name={`sintesi_infillPanelMaterial_${awning.id}`}
                      onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v => TextInputParseInt(v.target.value))}
                      value={awning.sintesi.infillPanelMaterial}
                      labelText={`Infill Panel Material`}
                      validationMessages={sintesiValidationMessages}
                      options={SintesiInfillPanelMaterial}
                      validationName={`infillPanelMaterial`}
                  /> }

                  <SelectControl
                      validationMessages={sintesiValidationMessages}
                      labelText="Light Options"
                      name="selectedLightingOptions"
                      options={SintesiLightingOptions}
                      simpleValue={false}
                      multi={true}
                      onChange={(val) => this.handleSintesiFieldUpdate( {target: {name: "selectedLightingOptions", value: val}} , e => e.target.value)}
                      value={awning.sintesi.selectedLightingOptions}
                      placeholder="Light Options"
                  />

                  <RadioControlGroup
                      name={`sintesi_heaterCount_${awning.id}`}
                      onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v => TextInputParseInt(v.target.value), (newSintesi)=> {
                        return {
                          ...newSintesi,
                          hasHeaterExtension: newSintesi.heaterCount <= 0 ? null : newSintesi.hasHeaterExtension
                        };
                      })}
                      value={ awning.sintesi.heaterCount }
                      labelText={`Heater (2300W)`}
                      validationMessages={sintesiValidationMessages}
                      options={SintesHeaters}
                      validationName={`heaterCount`}
                  />

                  { awning.sintesi.heaterCount > 0 && <RadioControlGroup
                      name={`sintesi_hasHeaterExtension_${awning.id}`}
                      onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v => TextInputParseBool(v.target.value))}
                      value={ awning.sintesi.hasHeaterExtension }
                      labelText={`Heater Extension Channel`}
                      validationMessages={sintesiValidationMessages}
                      options={YesOrNo}
                      validationName={`hasHeaterExtension`}
                  /> }

                  <RadioControlGroup
                      name={`sintesi_hasAudioSet_${awning.id}`}
                      onChange={(x, e) => this.handleSintesiFieldUpdateWithAwningId(e, v => TextInputParseBool(v.target.value))}
                      value={ awning.sintesi.hasAudioSet }
                      labelText={`Include Audio Set`}
                      validationMessages={sintesiValidationMessages}
                      options={YesOrNo}
                      validationName={`hasAudioSet`}
                  />
                  
                </Aux>
                }

                <RadioControlGroup
                    name={`automationSensorType_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, newState) => {
                      return {
                        ...newState, awning: {
                          ...newState.awning,
                          automationSensorType: v == null ? null : v,
                          motionSensorColour: null,
                          includeStabiliserPoles: v == null ? newState.awning.includeStabiliserPoles : false,
                        }
                      };
                    }, "number")}
                    value={awning.automationSensorType}
                    labelText='Automation Sensors'
                    onClearSelection={(awning.automationSensorType ? (e) => {
                      e.preventDefault();
                      this.setState({
                        awning: {
                          ...this.state.awning,
                          automationSensorType: null,
                          automationCableId: null,
                          motionSensorColour: null
                        }
                      }, this.raiseAwningChanged);
                    } : null)}
                    validationMessages={validationMessages}
                    options={SensorTypes(awning)}
                    visible={(isHardwired(awning) || isRemote(awning)) && !isGeigerRemote(awning) && !isRoofingSystem}
                />

                <RadioControlGroup
                    name={`motionSensorColour_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e)}
                    value={awning.motionSensorColour}
                    labelText="Motion sensor colour"
                    validationMessages={validationMessages}
                    options={this.state.motionSensorColours}
                    visible={isMotionSensor(awning)}
                />

                <RadioControlGroup
                    name={`remote_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, newState) => {
                      if (v !== REMOTE_MULTICHANNEL) {
                        return {...newState, awning: {...newState.awning, remoteParentAwningNumber: null}};
                      }
                      return newState;
                    }, "number")}
                    value={awning.remote}
                    labelText="Remote Controls"
                    validationMessages={validationMessages}
                    options={this.state.remotes}
                    visible={this.state.remotes.length > 0 && !isNullEmptyOrWhitespace(awning.automationSensorType)}
                />

                <RadioControlGroup
                    name={`remoteParentAwningNumber_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, (v, newState) => {
                      return {...newState, awning: {...newState.awning, desiredRemoteColour: null}};
                    }, "number")}
                    value={awning.remoteParentAwningNumber}
                    labelText="Join Multi Remote Awning"
                    validationMessages={validationMessages}
                    options={this.getRemoteParentOptions()}
                    clearSelectionText={'Clear selection'}
                    onClearSelection={(e) => {
                      e.preventDefault();
                      this.setState({awning: {...awning, remoteParentAwningNumber: null}})
                    }}
                    visible={!isNullEmptyOrWhitespace(awning.automationSensorType)}
                />

                <RadioControlGroup
                    name={`desiredRemoteColour_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e)}
                    value={awning.desiredRemoteColour}
                    labelText="Remote colour"
                    validationMessages={validationMessages}
                    options={this.state.remoteColours}
                    visible={this.state.remoteColours.length > 0 && !isNullEmptyOrWhitespace(awning.automationSensorType)}
                />

                <RadioControlGroup
                    name={`automationCableId_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, null, "number")}
                    value={awning.automationCableId}
                    labelText='Automation Cable'
                    validationMessages={validationMessages}
                    options={this.state.automationCables}
                    visible={(isHardwired(awning) || isRemote(awning))}
                />

                <RadioControlGroup
                    name={`mocoType_${awning.id}`}
                    onChange={(x, e) => this.handlFieldChangeWithAwningId(e, null, "number")}
                    value={awning.mocoType}
                    labelText='Automation Accessories (optional)'
                    onClearSelection={(awning.mocoType ? (e) => {
                      e.preventDefault();
                      this.setState({awning: {...this.state.awning, mocoType: null}}, this.raiseAwningChanged);
                    } : null)}
                    validationMessages={validationMessages}
                    options={this.state.mocoTypes}
                    visible={(isHardwired(awning) || isRemote(awning))}
                />

                <div className="form-group" style={{marginTop: '20px'}}>
                  <label>Notes</label>

                  {awning.notes.map(x => {
                    return (
                        <div className="pi-row" key={x.id}>
                          <div className="pi-col-sm-2">{moment(x.createdDate).format(DATE_SHORT_FORMAT)}</div>
                          <div className="pi-col-sm-4">
                            {x.createdByPersonName}
                          </div>
                          <div className="pi-col-sm-6">
                            {x.description}
                          </div>
                        </div>
                    );
                  })}
                  <textarea className={'form-control'}
                            value={awning.noteText == null ? '' : awning.noteText}
                            onChange={this.handlFieldChange}
                            name='noteText'
                  />
                </div>

                <AwningAttachments
                    attachments={!awning.attachments ? [] : awning.attachments}
                    onChange={(attachments) => {
                      this.setState({awning: {...awning, attachments}}, this.raiseAwningChanged);
                    }}
                />

              </div>}
        </div>);
  }
}

AwningView.propTypes = {
  awning: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onMessagesChange: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  siteSettings: PropTypes.object.isRequired,
  organisation: PropTypes.object,
};

export default AwningView;