import {
  ORDER_STATUS_IN_PRODUCTION,
  SUPPORT_REQUEST_STATUS_INQUIRY,
  SUPPORT_REQUEST_STATUS_RECEIVED
} from "../constants";

export default {
  user: {
    id: 0,
    personName: '',
    firstName: '',
    lastName: '',
    userName: '',
    personId: 0,
    roles: [],
    accessTags: [],
    organisation: {
      id: 0,
      standardOrderNote: '',
      fabricMakerShowSequenceNumber: null,
    },
    isInRole: () => false,
    hasAccessTag: () => false,
    accountMessages: [],
    portalServices: [],
  },
  organisations: {
    loaded: false,
    items: [],
  },
  lookups: {
    fabricMakers: [],
    powderCoaters: [],
    dispatchTypes: [],
    dispatchCentres: [],
    creditTerms: [],
    creditApplicationStatuses: [],
    organisationStatuses: [],
    states: [],
    paymentMethods: [],
    standardColoursForSearch: [],
    driveMechanisms: [],
    siteSettings: {
      AluxorUpdatesMessage: '',
      ProductPriceListMessage: '',
      ProductSintesiPriceListMessage: '',
    },
    quoteAddons: [],
  },
  orders: {
    loaded: false,
    unfilteredItems: [],
    items: [],
    colours: [],
    motors: [],
    nonPostFilter: {
      sorted: [{id: "sequenceNumber", desc: true}, {id: "createdDate", desc: true}],
      pageIndex: 0,
      pageSize: 10,
      sortDirectionDesc: true,
      advancedOpen: false,
    },
    filter: {
      organisationIds: [],
      orderStatuses: [],
      width: null,
      projection: null,
      colourId: [],      
      motorBrandId: null,
      outstandingFabric: null,
      outstandingPc: null,
      orderId: '',
      customerRef: '',
      fabricMakerId: [], 
      powderCoaterId: [], 
      productIds: [],
      createdDateFrom: null,
      createdDateTo: null,
      sequenceNumber: '',
      invoiceNumber: '',
      isPcOrderAlxRecieved: false,
      standardColourId: [],
      driveMechanismId: [],
    }
  },
  spares: {
    loaded: false,
    items: [],
    filter: {
      sorted: [{id: "spareGroupDescription", desc: false}],
      filtered: [],
      pageIndex: 0,
      pageSize: 10,
    }
  },
  identity: {
    accessToken: undefined
  },
  resourceCart: {
    items: [],
    isOpen: false,
  },
  httpInfo: {
    pendingCount: 0,
  },
  supplierOrders: {
    loaded: false,    
    items: [],
    colours: [],
    motors: [],    
    products: [],    
    nonPostFilter: {
      sorted: [{id: "sequenceNumber", desc: true}, {id: "createdDate", desc: true}],
      pageIndex: 0,
      pageSize: 10,
      sortDirectionDesc: true,
      advancedOpen: false,
    },
    filter: {
      organisationIds: [],
      orderStatuses: [3,4],
      width: null,
      projection: null,
      colourId: [],
      motorBrandId: null,
      outstandingFabric: null,
      outstandingPc: null,
      orderId: '',
      customerRef: '',
      productIds: [],
      createdDateFrom: null,
      createdDateTo: null,
      sequenceNumber: '',
      invoiceNumber: '',
      isPcOrderAlxRecieved: false,
      isPcRecDate: null,
      isFabricRecDate: null,
    },    
  },
  supportRequests:{
    loaded: false,
    requests: [],    
    filter: {
      statuses: [SUPPORT_REQUEST_STATUS_INQUIRY, SUPPORT_REQUEST_STATUS_RECEIVED],
      organisations: [],
      productIds: [],
      supportRequestTypeIds: [],
      customerReferenceNumber: '',
      sequenceNumber: '',
    },
    products: [],
  }
};