import * as types from './actionTypes';
import {apiRequest} from '../utils/request';
import {toastr} from 'react-redux-toastr';
import initialState from '../reducers/initialState';
import {uniqBy, join} from 'lodash';
import {DATE_SEARCH_FORMAT, DATE_SHORT_FORMAT, isNullEmptyOrWhitespace, UrlSegmentArray, UrlSegmentString} from "../constants";
import moment from "moment";

export function loadOrdersSuccess({orders, filter, nonPostFilter}){
  toastr.success('Orders loaded');
  return {type: types.LOAD_ORDERS_SUCCESS, orders, filter, nonPostFilter};
}
//TODO: No diff between this and updatefilter
export function loadOrders(filter = initialState.orders.filter, nonPostFilter = initialState.orders.nonPostFilter) {
  return function (dispatch, getState) {

    let url = constructOrdersFilter(filter);

    toastr.warning('Loading orders...');
    return apiRequest(url)
      .then(x => {
        if(x.data){
          dispatch(loadOrdersSuccess({orders: [...x.data], filter, nonPostFilter}));
        } else {
          toastr.error('Error while searching...');
        }
      });
  };
}

/*export function loadOrdersBy(by, f, sortBy) {
  return function (dispatch) {
    let url = `/api/orders/searchBy?by=${by}`;

    let  filter = sortBy ? {...initialState.orders.filter,
        outstandingOnly: f,
        sorted: [{id: sortBy, desc: true}]
      }
    : {...initialState.orders.filter, outstandingOnly: f};
    dispatch({type: types.ORDERS_UPDATE_FILTER, filter});
    toastr.warning('Loading orders...');
    return apiRequest(url)
      .then(x => {
        dispatch(loadOrdersSuccess({orders: [...x.data], filter}));
      });
  };
}*/

function constructOrdersFilter(filter = initialState.orders.filter) {
  let url = `/api/orders?`;

  url += UrlSegmentArray('organisationId', filter.organisationIds);
  url += UrlSegmentArray('orderStatus', filter.orderStatuses);
  url += UrlSegmentString('orderId', filter.orderId);
  url += UrlSegmentString('customerRef', filter.customerRef);
  url += UrlSegmentArray('fabricMakerId', filter.fabricMakerId);
  url += UrlSegmentArray('powderCoaterId', filter.powderCoaterId);
  url += UrlSegmentArray('productIds', filter.productIds);
  url += UrlSegmentString('createdDateFrom', isNullEmptyOrWhitespace(filter.createdDateFrom) ? null : moment(filter.createdDateFrom).format(DATE_SEARCH_FORMAT));
  url += UrlSegmentString('createdDateTo', isNullEmptyOrWhitespace(filter.createdDateTo) ? null : moment(filter.createdDateTo).format(DATE_SEARCH_FORMAT));
  url += UrlSegmentString('outstandingFabric', filter.outstandingFabric);
  url += UrlSegmentString('outstandingPc', filter.outstandingPc);
  url += UrlSegmentString('sequenceNumber', filter.sequenceNumber);
  url += UrlSegmentString('invoiceNumber', filter.invoiceNumber);
  url += UrlSegmentString('projection', filter.projection);
  if (filter.width !== null) {
    url += UrlSegmentString('minWidth', filter.width.value.minWidth);
    url += UrlSegmentString('maxWidth', filter.width.value.maxWidth);
  }
  url += UrlSegmentArray('colourId', filter.colourId);
  url += UrlSegmentArray('standardColourId', filter.standardColourId);
  url += UrlSegmentString('motorBrandId', filter.motorBrandId);
  url += UrlSegmentArray('driveMechanismId', filter.driveMechanismId);

  return url;
}

export function updateNonPostFilter(nonPostFilter = initialState.orders.nonPostFilter) {
  return function (dispatch, getState) {
    dispatch({type: types.ORDERS_UPDATE_NONPOSTFILTER, nonPostFilter});
  }
}

export function updateFilter(filter = initialState.orders.filter){
  return function (dispatch, getState) {

    //check if the filter has changed, if so construct API call
    let {currentFilter} = getState().orders.filter;

    if(JSON.stringify(currentFilter) !== JSON.stringify(filter))
    {
      dispatch({type: types.ORDERS_UPDATE_FILTER, filter});
      /*
      let url = constructOrdersFilter(filter);

      return apiRequest(url)
        .then(x => {
          dispatch(loadOrdersSuccess({orders: [...x.data], filter}));
        });*/
    } else {
      console.log('Filter unchanged - no-op');
    }
/*
    let statusFilter = filter.filtered.find(x => x.id == "orderStatusDescription");

    if(!statusFilter || (statusFilter
        && ((statusFilter.value !== 'all'
        && !getState().orders.items.find(x => x.orderStatus == parseInt(statusFilter.value)))
      || (statusFilter.value == 'all' && uniqBy(getState().orders.items, x => x.orderStatusDescription).length <= 1)))
    ){
        toastr.success('Updating...');

        
        if(statusFilter && statusFilter.value !== 'all'){
            url += `?orderStatus=${statusFilter.value.replace(' ', '')}`;
        }
        return apiRequest(url)
          .then(x => {
            dispatch(loadOrdersSuccess({orders: [...x.data], filter}));
          });

    } 
    else {
      dispatch({type: types.ORDERS_UPDATE_FILTER, filter});
    }*/
  }
}