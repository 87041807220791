import join from 'lodash/join';
import moment from "moment";
import store from './store';

export const BASE_URL = '';
export const COLOUR_STANDARD_CUSTOM_ID = 7;
export const FABRIC_SUPPLIED_NONE = 0;
export const FABRIC_SUPPLIED_BY_ALUXOR = 1;
export const FABRIC_SUPPLIED_CUSTOMER = 2;
export const DATE_SHORT_FORMAT = "DD/MM/YYYY";
export const DATE_SUPER_SHORT_FORMAT = "DD/MM";
export const DATE_SEARCH_FORMAT = "YYYY-MM-DD";
export const ORDER_STATUS_QUOTE = 1;
export const ORDER_STATUS_ORDER_REQUESTED = 2;
export const ORDER_STATUS_IN_PRODUCTION = 3;
export const ORDER_STATUS_ORDER_DELAYED = 4;
export const ORDER_STATUS_ORDER_PRODUCTION_COMPLETE = 6;
export const ORDER_STATUS_ORDER_PRODUCTDISPATCHED = 7;
export const ORDER_STATUS_ORDER_CANCELLED = 9;
export const ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED = 10;
export const FABRIC_RECOVER_ORDER_STATUS_QUOTE = 1;
export const FABRIC_RECOVER_ORDER_STATUS_IN_PRODUCTION = 2;
export const FABRIC_RECOVER_ORDER_STATUS_COMPLETED = 3;
export const FABRIC_RECOVER_ORDER_STATUS_INACTIVE = 4;
export const FABRIC_RECOVER_ORDER_STATUS_AMEND = 5;
export const FABRIC_RECOVER_ORDER_STATUS_CANCELLED = 6;

export const FRAME_FEATURE_SELECTAPITCH = 1;
export const FRAME_FEATURE_VARIOVOLANT = 2;
export const FRAME_FEATURE_SA = 4;
export const FRAME_FEATURE_SACP = 5;
export const FRAME_FEATURE_FRAMEADJUSTERS = 3;
export const DRIVE_MECHANISM_MANUAL = 1;
export const DRIVE_MECHANISM_MotorisedHardwired = 2;
export const DRIVE_MECHANISM_MotorisedRemote = 3;
export const DRIVE_MECHANISM_ManualOverrideRemote = 5;
export const DRIVE_MECHANISM_MotorisedPrepOnly = 4;
export const DRIVE_MECHANISM_ManualOverrideHardwired = 6;
export const DRIVE_MECHANISM_Geiger = 7;
export const SENSOR_TYPE_MOTION = 1;
export const DISPATCH_INTERSTATE_FREIGHT = 1;
export const DISPATCH_LOCAL_FREIGHT = 2;
export const DISPATCH_COLLECT = 3;
export const COURIER_OTHER = 8;

export const PRODUCTID_ECO = 1;
export const PRODUCTID_S1 = 2;
export const PRODUCTID_S3 = 3;
export const PRODUCTID_TOGA = 4;
export const PRODUCTID_OLYMPUS = 5;
export const PRODUCTID_DS = 6;
export const PRODUCTID_SD = 9;
export const PRODUCTID_ST = 7;
export const PRODUCTID_SDM = 28;
export const PRODUCTID_SDC = 29;
export const PRODUCTID_SDCZ = 30;
export const PRODUCTID_MOTORS_SD = 31;

export const PRODUCTGROUPID_FOLDING_ARM = 1;
export const PRODUCTGROUPID_CONSERVATORY = 2;
export const PRODUCTGROUPID_ROOFING_SYSTEMS = 3;

export const ROLE_Administrator = 'Administrator';
export const ROLE_Administrator_Id = 1;
export const ROLE_ProductManager = 'Product Manager';
export const ROLE_ProductManager_Id = 2;
export const ROLE_CustomerAdmin = 'Customer Admin';
export const ROLE_CustomerAdmin_Id = 3;
export const ROLE_Fabric = 'Fabric Supplier';
export const ROLE_Fabric_Id = 8;
export const ROLE_PC = 'PC Supplier';
export const ROLE_PC_Id = 9;

export const PORTALSERVICE_AwningQuote = 1;
export const PORTALSERVICE_SpareQuote = 2;
export const FLASHINGTYPE_WallSeal = 3;
export const FLASHINGTYPE_Sintesi300 = 4;
export const FLASHINGTYPE_Sintesi500 = 5;
export const MOUNTING_EXTENSION = 37;
export const MOUNTING_BACKPOSTS = 44;

export const REMOTE_MULTICHANNEL = 2;
export const SPARE_SD_AWNING_TAPE = 78;
export const SPARE_SCSZ_AWNING_TAPE = 246;
export const SPARE_SD_AWNING_BUCKLE = 77;
export const REPORTSERVER_BASEURL = 'https://alx-wosp-pdf.azurewebsites.net';
export const SIDE_LEFT=1;
export const SIDE_RIGHT=2;

export const INVOICE_TYPE_ORDERAWNING = 1;
export const INVOICE_TYPE_FABRICRECOVER = 2;
export const INVOICE_TYPE_PCSTOCKORDER = 3;

export const FABRIC_VALANCE_WAVY = 2;

export const PAYMENT_METHOD_CC = 1;
export const PAYMENT_METHOD_BANK = 1;

export const SM_FASCIA_BRACKET_ID = 46;
export const SD_CONTINUOUS_ANGLE_BRACKET_ID = 65;

export const SUPPORT_REQUEST_STATUS_INQUIRY = 1;
export const SUPPORT_REQUEST_STATUS_RECEIVED = 2;
export const SUPPORT_REQUEST_STATUS_COMPLETED = 3;

export const PELMET_TYPE_Aluminium = 1;
export const PELMET_TYPE_Colorbond = 2;
export const QUOTE_ADDON_TYPE_INSTALL_COST = 1;
export const QUOTE_ADDON_TYPE_NOTE = 2;
export const QUOTE_ADDON_TYPE_GENERALNOTE = 4;

export function isSelectaPitch(awning) {
  let id = awning ? awning.frameFeature : -1;
  return id === FRAME_FEATURE_SELECTAPITCH;
}

export function isVarioVolant(awning) {
  let id = awning ? awning.frameFeature : -1;
  return id === FRAME_FEATURE_VARIOVOLANT;
}

export function isSteerAround(awning) {
  let id = awning ? awning.frameFeature : -1;
  return id === FRAME_FEATURE_SA || id === FRAME_FEATURE_SACP;
}
export function isSDSM(awning) {
  return IsNullOrUndefined(awning) ? false : awning.productId === PRODUCTID_SDM;
}
export function isSDorSM(awning) {
  return IsNullOrUndefined(awning) ? false : (awning.productId === PRODUCTID_SDM) ||
  (awning.productId === PRODUCTID_SD);
}

export function isFrameAdjusters(awning) {
  let id = awning ? awning.frameFeature : -1;
  return id === FRAME_FEATURE_FRAMEADJUSTERS;
}

export function isManualFrameFeature(awning) {
  let id = awning ? awning.frameFeatureDriveMechanismId : -1;
  return id === DRIVE_MECHANISM_MANUAL;
}

export function isRemoteFrameFeature(awning) {
  let id = awning ? awning.frameFeatureDriveMechanismId : -1;  
  return id === DRIVE_MECHANISM_MotorisedRemote || id === DRIVE_MECHANISM_ManualOverrideRemote || id === DRIVE_MECHANISM_Geiger;
}

export function isManual(awning) {
  let id = awning ? awning.driveMechanismId : -1;
  return id === DRIVE_MECHANISM_MANUAL;
}

export function isRemote(awning) {
  let id = awning ? awning.driveMechanismId : -1;
  return id === DRIVE_MECHANISM_MotorisedRemote || id === DRIVE_MECHANISM_ManualOverrideRemote || id === DRIVE_MECHANISM_Geiger;
}

export function isMotor(awning) {
  let id = awning ? awning.driveMechanismId : -1;
  return id === DRIVE_MECHANISM_MotorisedRemote || 
      id === DRIVE_MECHANISM_MotorisedHardwired || 
      id === DRIVE_MECHANISM_Geiger || id === DRIVE_MECHANISM_ManualOverrideHardwired || id === DRIVE_MECHANISM_ManualOverrideRemote;
}

export function isGeigerRemote(awning) {
  let id = awning ? awning.driveMechanismId : -1;
  return id == DRIVE_MECHANISM_Geiger;
}

export function isHardwired(awning) {
  let id = awning ? awning.driveMechanismId : -1;
  return id == DRIVE_MECHANISM_MotorisedHardwired || id == DRIVE_MECHANISM_ManualOverrideHardwired;
}

export function isMotionSensor(awning) {
  let id = awning ? awning.automationSensorType : -1;
  return id == SENSOR_TYPE_MOTION;
}

export function isOl(awning) {
  let id = awning ? awning.productId : -1;
  return id == PRODUCTID_OLYMPUS;
}

export function isDs(awning) {
  let id = awning ? awning.productId : -1;
  return id == PRODUCTID_DS;
}

export function isScSz(awning) {
  let id = awning ? awning.productId : -1;
  return id === PRODUCTID_SDC || id === PRODUCTID_SDCZ;
}

export function isDualMount(awning) {  
  return (awning && awning.productGroupId!==PRODUCTGROUPID_ROOFING_SYSTEMS && awning.fieldConfiguration && awning.fieldConfiguration.fieldConfigurationOption && 
          awning.fieldConfiguration.fieldConfigurationOption.isDualMount);
}

export function hasInstallationPosts(awning, fieldInstallationOptions){
  return (((awning.instBracketPermutationId && fieldInstallationOptions.find(y => y.id === awning.instBracketPermutationId) && fieldInstallationOptions.find(y => y.id ===awning.instBracketPermutationId).postCount > 0)) ||
          (awning.fieldConfiguration && awning.fieldConfiguration.instBracketPermutationId && fieldInstallationOptions.find(y => y.id ===awning.fieldConfiguration.instBracketPermutationId) &&
              fieldInstallationOptions.find(y => y.id === awning.fieldConfiguration.instBracketPermutationId).postCount > 0));
}

export const MoneyFormatter = new Intl.NumberFormat('en-au', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 2,
});

export function isNullEmptyOrWhitespace(v) {
  return v === null || v === undefined || v == '' || v.toString().trim() == '';
}

export function SpaceString(items, delimiter = ' ') {
  let filtered = items.filter(x => !isNullEmptyOrWhitespace(x));
  return filtered.length == 0 ? null :
    join(filtered, delimiter);
}

export function isCustomerCollect(order) {
  return order.dispatchTypeId === 3;
}

export function SensorTypes(awning) {
  let returnVal = isRemote(awning) ?
    [{value: 1, label: 'Motion sensor'}, {value: 2, label: 'Wind sensor'}, {value: 3, label: 'Sun and wind sensor'},{value: 4, label: 'Rain sensor'}, {value: 5, label: 'Timer'}] :
    [{value: 2, label: 'Wind sensor'}, {value: 3, label: 'Sun and wind sensor'},{value: 4, label: 'Rain sensor'}, {value: 5, label: 'Timer'}];

  if(awning.productId === PRODUCTID_SD || awning.productId === PRODUCTID_SDM ||awning.productId === PRODUCTID_SDC ||awning.productId === PRODUCTID_SDCZ){
    returnVal = returnVal.filter(x => x.value !== 3 && x.value !== 1);
  }

  return returnVal;
}

/**
 * @return {boolean}
 */
export function IsNullOrUndefined(input){
  return input === null || input === undefined;
}

/**
 * @return {string}
 */
export function MountingBracketName(awning) {
  return IsNullOrUndefined(awning) ? '' : awning.productId === PRODUCTID_SDM ? 'Headbox Mounting' : 'Mounting Bracket';
}
export function headBoxWith(awning){
  return isSDorSM(awning) ? 60 : 44;
}

export function fieldWidthDeduction(width, field, fieldConfiguration, awning) {
    
  if(!isSDSM(awning) && !isScSz(awning) && !isDualMount(awning))
  {
    return width;
  }

  let adjustment = headBoxWith(awning);
  let firstFieldAdjust = adjustment / 2;

  if (field.number === 1 || field.number === fieldConfiguration.numberOfFields) {
    width = width - firstFieldAdjust;
  } else {
    width = width - adjustment;
  }
  return width;
}

export function fieldWidthAddition(width, field, fieldConfiguration, awning) {
  if(width <= 0){
    return width;
  }
  if(!isSDSM(awning) && !isScSz(awning) && !isDualMount(awning))
  {
    return width;
  }
  let adjustment = headBoxWith(awning);
  let firstFieldAdjust = adjustment / 2;

  if (field.number === 1 || field.number === fieldConfiguration.numberOfFields) {
    width = width + firstFieldAdjust;
  } else {
    width = width + adjustment;
  }
  return width;
}

export function fieldHeadboxWidthAddition(width, field, fieldConfiguration, awning) {
  if(width <= 0){
    return 0;
  }

  if(!isSDSM(awning) && !isScSz(awning) && !isDualMount(awning))
  {
    return width;
  }
  let adjustment = headBoxWith(awning);
  let firstFieldAdjust = adjustment / 2;

  if (field.number === 1 || field.number === fieldConfiguration.numberOfFields) {
    return firstFieldAdjust;
  } else {
    return adjustment;
  }
}

export const JoinerOptionsRequiredJoiner = [{value: true, label: 'Dispatch fully assembled'},{value: false, label: 'Dispatched in sections'}];
export const JoinerOptionsOptionalJoiner = [{value: true, label: 'No joiner'},{value: false, label: 'Make as joiner'}];
export const SectionOptionsAssembled = [{value: 1, label: 'One'}];
export const SectionOptionsSections = [{value: 1, label: 'One'},{value: 2, label: 'Two'},{value: 3, label: 'Three'}];
export const FabricInstructions = [{value: 1, label: 'Top and bottom pocket 6mm spline'},{value: 2, label: 'Front pocket 6mm spline + raw edge at top'}];
export const BasePowderCoatingImageUrl = '/img/powder_coating_sheet/';

export const orders_list_view_screen_all =  'orders_list_view_screen_*';
export const orders_list_list_all_organisations_all =  'orders_list_list_all_organisations_*';
export const orders_list_select_edit_order_quote = 'orders_list_select_edit_order_quote';
export const orders_list_select_edit_order_amendment_requested = 'orders_list_select_edit_order_amendment_requested';
export const orders_list_select_view_order_all =  'orders_list_select_view_order_*';
export const orders_list_select_delete_order_quote = 'orders_list_select_delete_order_quote';
export const edit_order_view_screen_quote = 'edit_order_view_screen_quote';
export const edit_order_view_screen_amendment_requested = 'edit_order_view_screen_amendment_requested';
export const edit_order_view_motor_selection_amendment_requested = 'edit_order_view_motor_selection_amendment_requested';
export const edit_order_view_order_status_changes_all =  'edit_order_view_order_status_changes_*';
export const view_order_view_screen_all =  'view_order_view_screen_*';
export const view_order_submit_order_quote = 'view_order_submit_order_quote';
export const view_order_select_edit_quote_quote = 'view_order_select_edit_quote_quote';
export const view_order_select_print_order_all =  'view_order_select_print_order_*';
export const view_order_select_link_to_clipboard_all =  'view_order_select_link_to_clipboard_*';
export const view_order_show_price_information_all =  'view_order_show_price_information_*';
export const view_order_view_order_status_changes_all =  'view_order_view_order_status_changes_*';
export const view_order_editable_fabric_detail_order_requested = 'view_order_editable_fabric_detail_order_requested';
export const view_order_editable_fabric_detail_in_production = 'view_order_editable_fabric_detail_in_production';
export const view_order_editable_powder_coater_detail_order_requested = 'view_order_editable_powder_coater_detail_order_requested';
export const view_order_editable_powder_coater_detail_in_production = 'view_order_editable_powder_coater_detail_in_production';
export const view_order_editable_dispatch_method_order_requested = 'view_order_editable_dispatch_method_order_requested';
export const view_order_editable_dispatch_method_in_production = 'view_order_editable_dispatch_method_in_production';
export const view_order_editable_dispatch_method_production_complete = 'view_order_editable_dispatch_method_production_complete';
export const view_order_editable_motor_order_requested = 'view_order_editable_motor_order_requested';
export const view_order_editable_motor_in_production = 'view_order_editable_motor_in_production';
export const view_order_save_order_all =  'view_order_save_order_*';
export const view_order_editable_joiner_in_production = 'view_order_editable_joiner_in_production';
export const view_order_editable_joiner_order_requested = 'view_order_editable_joiner_order_requested';
export const view_order_editable_order_and_awning_note_all =  'view_order_editable_order_and_awning_note_*';
export const view_order_select_delayed_in_production = 'view_order_select_delayed_in_production';
export const view_order_select_request_amendment_in_production = 'view_order_select_request_amendment_in_production';
export const view_order_view_production_sheet_in_production = 'view_order_view_production_sheet_in_production';
export const view_order_view_pc_sheets_in_production = 'view_order_view_pc_sheets_in_production';
export const view_order_select_production_complete_in_production = 'view_order_select_production_complete_in_production';
export const view_order_select_delay_resolved_order_delayed = 'view_order_select_delay_resolved_order_delayed';
export const view_order_select_request_amendment_order_delayed = 'view_order_select_request_amendment_order_delayed';
export const view_order_select_frieght_action_production_complete = 'view_order_select_frieght_action_production_complete';
export const view_order_submit_order_amendment_requested = 'view_order_submit_order_amendment_requested';
export const view_order_select_edit_quote_amendment_requested = 'view_order_select_edit_quote_amendment_requested';
export const view_order_select_request_amendment_order_requested = 'view_order_select_request_amendment_order_requested';
export const view_order_view_fabric_sheets_in_production = 'view_order_view_fabric_sheets_in_production';
export const view_order_view_dispatch_sheet_product_dispatched = 'view_order_view_dispatch_sheet_product_dispatched';
export const view_order_view_invoice_sheet_in_production = 'view_order_view_invoice_sheet_in_production';
export const view_order_view_invoice_sheet_production_complete = 'view_order_view_invoice_sheet_production_complete';
export const view_order_view_invoice_sheet_product_dispatched = 'view_order_view_invoice_sheet_product_dispatched';
export const view_order_view_flashing_sheet_in_production = 'view_order_view_flashing_sheet_in_production';
export const view_order_select_link_to_login_all = 'view_order_select_link_to_login_*';
export const view_order_accept_order_order_requested = 'view_order_accept_order_order_requested';
export const view_order_cancel_order_amendment_requested = 'view_order_cancel_order_amendment_requested';
export const edit_order_cancel_order_amendment_requested = 'edit_order_cancel_order_amendment_requested';
export const edit_order_can_deselect_pelmet_quote = 'edit_order_cancel_order_amendment_requested';
export const edit_order_can_deselect_pelmet_amendment_requested = 'edit_order_can_deselect_pelmet_amendment_requested';

export const spare_order_view_screen_quote = 'spare_order_view_screen_quote';
export const spare_order_view_screen_amendment_requested = 'spare_order_view_screen_amendment_requested';
export const orders_list_select_edit_spare_order_quote = 'orders_list_select_edit_spare_order_quote';
export const orders_list_select_edit_spare_order_amendment_requested = 'orders_list_select_edit_spare_order_amendment_requested';
export const orders_list_select_delete_spare_order_quote = 'orders_list_select_delete_spare_order_quote';
export const view_order_view_motor_sheet_in_production = 'view_order_view_motor_sheet_in_production';
export const view_order_view_abs_sheet_all = 'view_order_view_abs_sheet_*';

export const POWDER_COATER_TPP_ID = 1;

export const validationCreator = (isValid = true, message = '')=> {
  return {isValid, message};
};

export const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PhoneRegex = /^\D*0(\D*\d){9}\D*$/;

export function IsStringValid(val, reg){
  let isValid = reg ? (val === null || val === undefined || val === '' || (val.hasOwnProperty('trim') && val.trim() == '' )
    || !val.toString().match(reg)) : (val === null || val === undefined || val === '' || (val.hasOwnProperty('trim') && val.trim() == '' ));
  return !isValid;
}

export function obsfucate(input){
  try {
    return btoa(input.toString());
  } catch(e) {
    console.log(input);
    throw e;
  }
}


export function deobsfucate(input){
  return atob(input);
}

export const Aux = props => {
  let {visible} = props;

  return visible === true || IsNullOrUndefined(visible) ? props.children : null;
};

export function getBracketTotal(awning) {
  let {
    numberOfMountingBracket,
    additionalMountingBrackets,
    fieldConfiguration,
    frameFeature,
    steerAroundDimensions,
    frameFeatureSteerAroundType
  } = awning;
  
  let fixingCentres = null;
  let singleTracksTotal = null;
  let coupledTracksTotal = null;

  if (!IsNullOrUndefined(fieldConfiguration) && !IsNullOrUndefined(fieldConfiguration.fieldConfigurationOption)) {
    fixingCentres = fieldConfiguration.fieldConfigurationOption.fixingCentres;
    singleTracksTotal = fieldConfiguration.fieldConfigurationOption.singleTracksTotal
    coupledTracksTotal = fieldConfiguration.fieldConfigurationOption.coupledTracksTotal;    
  }

  let bracketTotal = 0;  
  
  if(awning.mountingBracketId === SD_CONTINUOUS_ANGLE_BRACKET_ID) {
    bracketTotal = 2 + additionalMountingBrackets;
  }
  else if (!IsNullOrUndefined(awning.parentOrderAwningId)) {
    bracketTotal = 0;
  } else if ((frameFeature === FRAME_FEATURE_SA || frameFeature === FRAME_FEATURE_SACP) &&
      steerAroundDimensions != null) {

    // if this is a single field, then always * 2, otherwise multiply by the number of single tracks
    let multiplier = IsNullOrUndefined(fieldConfiguration)
        ? 2
        : singleTracksTotal + coupledTracksTotal;
    
    //Has to be at least two, then up to whatever is needed in the rules document. Look at the type to determine which values to use.
    let memberNumber = (input) => {
      if (IsNullOrUndefined(input) || input <= 2500) {
        return 2;
      }

      // max 6500
      return input <= 4500 ? 3 : 4;
    };

    //type 1
    if (frameFeatureSteerAroundType === 1 || frameFeatureSteerAroundType === 5) {
      bracketTotal = memberNumber(steerAroundDimensions.h1) + memberNumber(steerAroundDimensions.h3);
    }

    //type 2 & 4
    if (frameFeatureSteerAroundType === 2 || frameFeatureSteerAroundType === 6
        || frameFeatureSteerAroundType === 4 ||
        frameFeatureSteerAroundType === 8
    ) {
      bracketTotal = memberNumber(steerAroundDimensions.h1) + memberNumber(steerAroundDimensions.h2);
    }

    //type 3
    if (frameFeatureSteerAroundType === 3 || frameFeatureSteerAroundType === 7) {      
      bracketTotal = memberNumber(steerAroundDimensions.h1) + memberNumber(steerAroundDimensions.h2) + memberNumber(steerAroundDimensions.h3);
    }
    bracketTotal = bracketTotal * multiplier;
    
  } else if (!IsNullOrUndefined(fixingCentres)) {
    bracketTotal = ((numberOfMountingBracket + additionalMountingBrackets) / 2) * fixingCentres;
  } else {
    bracketTotal = numberOfMountingBracket + additionalMountingBrackets;  
  }  
  
  return bracketTotal;
}


export function AwningBracketTotal(awning) {
  if(IsNullOrUndefined(awning)){
    return {bracketTotal: 0, bracketTotalDualMount: null, bracketTotalSingleMount: null};
  }

  //let isParentField = IsNullOrUndefined(awning.parentOrderAwningId) && !IsNullOrUndefined(awning.fieldConfiguration);

  let {numberOfMountingBracket, fieldConfiguration} = awning;
  let fixingCentres = null;
  let singleTracksTotal = null;
  let coupledTracksTotal = null;

  if(!IsNullOrUndefined(fieldConfiguration) && !IsNullOrUndefined(fieldConfiguration.fieldConfigurationOption)){
    fixingCentres = fieldConfiguration.fieldConfigurationOption.fixingCentres;
    singleTracksTotal = fieldConfiguration.fieldConfigurationOption.singleTracksTotal;
    coupledTracksTotal = fieldConfiguration.fieldConfigurationOption.coupledTracksTotal;
  }

  let bracketTotal = getBracketTotal(awning);

  let bracketTotalDualMount = coupledTracksTotal > 0 || IsNullOrUndefined(singleTracksTotal) || IsNullOrUndefined(fixingCentres) ? null :
      ((singleTracksTotal - fixingCentres) * (numberOfMountingBracket / 2));
  let bracketTotalSingleMount = IsNullOrUndefined(bracketTotalDualMount) ? null : bracketTotal - bracketTotalDualMount;

  return {bracketTotal, bracketTotalDualMount, bracketTotalSingleMount};  
}

export function IsMotorRequiredOnField(fieldLeft, fieldRight, offsetIfRight = false) {
  let returnData = {
    motorLeft: !IsNullOrUndefined(fieldLeft) && fieldLeft.isMotorRequired && fieldLeft.sideOfOperation === "Right",
    motorRight: !IsNullOrUndefined(fieldRight) && fieldRight.isMotorRequired && fieldRight.sideOfOperation === "Left",
    rightOffset: false,
  };
  returnData.rightOffset = returnData.motorRight && offsetIfRight;
  return returnData;
}
export function SortDate(a, b){
  if(IsNullOrUndefined(a)){
    return -1;
  }
  if(IsNullOrUndefined(b)){
    return 1;
  }
  
  if(!moment(a, DATE_SHORT_FORMAT).isValid()){    
    return 1;
  }
  if(!moment(b, DATE_SHORT_FORMAT).isValid()){
    return -1;
  }
  

  let aVal = moment(a, DATE_SHORT_FORMAT);
  let bVal = moment(b, DATE_SHORT_FORMAT);

  return aVal.isBefore(bVal) ? -1 : 1;
}


export const OrderStatuses = [
  { label: 'Quote', value: ORDER_STATUS_QUOTE },
  { label: 'Requested', value: ORDER_STATUS_ORDER_REQUESTED },
  { label: 'Production', value: ORDER_STATUS_IN_PRODUCTION },
  { label: 'Delayed', value: ORDER_STATUS_ORDER_DELAYED },
  { label: 'Cancelled', value: ORDER_STATUS_ORDER_CANCELLED },
  { label: 'Amend', value: ORDER_STATUS_ORDER_AMMENDMENDTREQUESTED },
  { label: 'Complete', value: ORDER_STATUS_ORDER_PRODUCTION_COMPLETE },
  { label: 'Dispatched', value: ORDER_STATUS_ORDER_PRODUCTDISPATCHED },
];

export const FabricRecoverOrderStatuses = [
  { label: 'Quote', value: FABRIC_RECOVER_ORDER_STATUS_QUOTE },  
  { label: 'In Production', value: FABRIC_RECOVER_ORDER_STATUS_IN_PRODUCTION },
  { label: 'Complete', value: FABRIC_RECOVER_ORDER_STATUS_COMPLETED },
  // { label: 'Inactive', value: FABRIC_RECOVER_ORDER_STATUS_INACTIVE },
  { label: 'Cancelled', value: FABRIC_RECOVER_ORDER_STATUS_CANCELLED },
];

/**
 * @return {boolean}
 */
export function AwningRequiresFabric(order, awning){
  if(IsNullOrUndefined(order) || IsNullOrUndefined(awning) || IsNullOrUndefined(order.organisationId) || awning.fabricSuppliedBy === FABRIC_SUPPLIED_NONE || IsNullOrUndefined(store))
  {
    return false;
  }
  if(awning.fabricSuppliedBy === FABRIC_SUPPLIED_BY_ALUXOR){
    return true;
  }
  let state = store.getState().organisations;
  if(!state.loaded){
    return false;
  }

  let organisation = state.items.filter(x => x.id === order.organisationId);
  if(organisation.length !== 1){
    if(order.organisationId > 0) {
      console.error('Unable to find organisation ' + order.organisationId);
    }
    return false;
  }

  return !IsNullOrUndefined(organisation[0].fabricMakerId);
}

export function TextInputParseInt(input) {
  let val = parseInt(input);
  if (!(val >= 0)) {
    val = "";
  }
  return val;
}

export function TextInputParseBool(input) {

  if (IsNullOrUndefined(input)) {
    return false;
  }

  if (input === false || input === true) {
    return input;
  }
  if (input.toString().toLowerCase() === "true") {
    return true;
  }
  if (input.toString().toLowerCase() === "false") {
    return false;
  }
  
  return TextInputParseInt(input) === 1;
}

export function RequireAdditionalBracketCount(brackets, awning){
  let bracket = IsNullOrUndefined(awning) ? null : brackets.find(x => x.id === awning.mountingBracketId);
  let requireAdditionalBracketCount = IsNullOrUndefined(bracket) ? false : bracket.requireAdditionalBracketCount;
  return requireAdditionalBracketCount;
}

export function OrganisationOrderAwningSupplyOwnMotor(order, awning){
  return isMotor(awning) && awning.canSupplyOwnMotor && awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM && awning.productId !== PRODUCTID_DS;
}

export function IsConservatoryOrRoofSystem(awning) {
  return IsNullOrUndefined(awning) ? false : awning.productGroupId === PRODUCTGROUPID_CONSERVATORY || awning.productGroupId === PRODUCTGROUPID_ROOFING_SYSTEMS;
}
export function IsConservatory(awning) {
  return IsNullOrUndefined(awning) ? false : awning.productGroupId === PRODUCTGROUPID_CONSERVATORY;
}

export function IsRoofSystem(awning) {
  return IsNullOrUndefined(awning) ? false : awning.productGroupId === PRODUCTGROUPID_ROOFING_SYSTEMS;
}

export function CalculateFinishedProjection(projection, backHeight, frontHeight, fallBackToFront, hasPosts) {
  if (projection <= 0 || backHeight <= 0 || frontHeight <= 0) {
    return 0;
  }
  //let height = backHeight - (frontHeight + fallBackToFront + (hasPosts ? 215 : 133));
  let height = backHeight - (frontHeight + fallBackToFront + 160);  
  let p = projection - (200 + 57); // may change, as it doens't account for posts or not https://trello.com/c/ETvj4Ivc/665-st-angle
  // a2 = b2 + c2
  let a = (height * height)
  let b = (p * p); 
  let c = Math.sqrt(a + b);

  let beta = Math.asin(height / c);
  //console.log(height, projection, c, beta, beta * (180 / Math.PI));

  let angle = beta * (180 / Math.PI);
  angle = Math.round(angle * 10) / 10;

  let finishedProjection = Math.ceil(c) + 257;
  
  return {finishedProjection, finishedAngle: angle};
}

export function CanHaveWedges(awning){
  if(IsNullOrUndefined(awning)){
    return false;    
  }
  return awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM && awning.productId !== PRODUCTID_OLYMPUS;
}

export function UrlSegmentArray(name, arrValues) {
  return arrValues.length === 0 ? '' : '&' + join(arrValues.map(x => `${name}=${x}`), "&");
}

export function UrlSegmentString(name, value) {
  return isNullEmptyOrWhitespace(value) ? '' : `&${name}=${value}`;
}

export function GetOrderPriceMessage(order, siteSettings){
  
  let message = "";
  
  if(order.awnings.findIndex(x => x.productGroupId === PRODUCTGROUPID_FOLDING_ARM || x.productGroupId === PRODUCTGROUPID_CONSERVATORY) >= 0){
    message = siteSettings.ProductPriceListMessage;
  }
  if(order.awnings.findIndex(x => x.productGroupId === PRODUCTGROUPID_ROOFING_SYSTEMS) >= 0){
    message += " " + siteSettings.ProductSintesiPriceListMessage;
  }
  return message;
}

export function IsPelmetTypeVisible(organisation, awning){
  if(IsNullOrUndefined(organisation) || IsNullOrUndefined(awning)){
    return false;
  }
  
  return organisation.enableCustomerInformation === true && awning.includePelmet === true && 
      (awning.productId === PRODUCTID_ECO || awning.productId === PRODUCTID_S1);  
}