import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextViewControl from '../common/TextViewControl';
import {
    DATE_SHORT_FORMAT,
    isSelectaPitch,
    FABRIC_SUPPLIED_BY_ALUXOR,
    isVarioVolant,
    JoinerOptionsOptionalJoiner,
    JoinerOptionsRequiredJoiner,
    SectionOptionsSections,
    ORDER_STATUS_ORDER_REQUESTED,
    SpaceString,
    PRODUCTID_OLYMPUS,
    PRODUCTID_TOGA,
    isRemote,
    isHardwired,
    view_order_editable_order_and_awning_note_all,
    isNullEmptyOrWhitespace,
    FabricInstructions,
    IsNullOrUndefined,
    PRODUCTGROUPID_CONSERVATORY,
    PRODUCTGROUPID_FOLDING_ARM,
    AwningRequiresFabric,
    FLASHINGTYPE_WallSeal,
    Aux,
    PRODUCTGROUPID_ROOFING_SYSTEMS, isSDorSM
} from '../../constants';
import moment from 'moment';
import {apiRequest, apiPost} from "../../utils/request";
import SelectControl from '../common/SelectControl';
import {toastr} from 'react-redux-toastr';
import {SummaryDivider} from '../common/SummaryControls';
import {validationCreator} from './AwningValidators';
import RadioControlGroup from "../common/RadioControlGroup";
import {AwningProductSpecifications} from "./AwningProductSpecifications";
import AwningAttachments from "./AwningAttachments";
import {SideOfOperationText} from "./AwningFieldsControl";
import DiagramSD from "../awningdiagrams/DiagramSD";
import DiagramFA from "../awningdiagrams/DiagramFA";
import DiagramST from "../awningdiagrams/DiagramST";
import AwningSADimensionSummary from "./AwningSADimensionSummary";


const ColourText = (props) => {
  let {labelText, standardColour, customColour} = props;
  return (
    <TextViewControl
      labelText={labelText}
      value={customColour == null ? standardColour : `Custom - ${customColour}`}
    />
  );
};

const noSectionsAllowed = (awning) => {
  return awning.productId === PRODUCTID_TOGA || awning.productId === PRODUCTID_OLYMPUS || awning.productGroupId === PRODUCTGROUPID_CONSERVATORY;
};

const ValidationRules = {
    sections: {
        validator: (val, state, props) => {
            if (IsNullOrUndefined(val) && props && props.canEditJoiner && !noSectionsAllowed(props.awning)) {
                return validationCreator(false, 'Please make a sections selection');
            } else if (val < 2 && props && props.canEditJoiner && !noSectionsAllowed(props.awning) && props.awning.width > 7000) {
                return validationCreator(false, 'Awnings greater than 7000 must be made with 2 or more sections');
            }
            return validationCreator();
        }
    },
    isAssembled: {
        validator: (val, state, props) => {            
            if (IsNullOrUndefined(val) && props && props.canEditJoiner && !noSectionsAllowed(props.awning)) {
                return validationCreator(false, 'Please make a joiner selection');
            } else if (val === true && props && props.canEditJoiner && !noSectionsAllowed(props.awning) && props.awning.width > 7000) {
                return validationCreator(false, 'Awnings greater than 7000 must be made with a joiner');
            }
            return validationCreator();
        }
    },
    middleArmSide: {
        validator: (val, state, props) => {             
            if ((IsNullOrUndefined(val) || val <= 0) && props && props.canEditJoiner && props.awning.numberOfArms === 3) {
                return validationCreator(false, 'Please make a middle arm side selection');
            } 
            return validationCreator();
        }
    }
}

class AwningSummaryView extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      awning: props.awning,
      validationMessages: {},
      joinerOptions: [],
      sectionOptions: [],
      noteText: '',
      middleArmOptions: [{value: 1, label: 'Left'}, {value: 2, label: 'Right'}],
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.getValidationMessages = this.getValidationMessages.bind(this);
    this.raiseAwningChanged =this.raiseAwningChanged.bind(this);
    this.handleAddAwningNote = this.handleAddAwningNote.bind(this);
    this.isValidAwning = this.isValidAwning.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState ||
      prevState.awning.defaultSections !== this.state.awning.defaultSections
      || this.state.joinerOptions.length == 0) {
      if (this.state.awning.defaultSections == 1) {
        this.setState({
          joinerOptions: JoinerOptionsOptionalJoiner,
        });
      } else {
        this.setState({
          joinerOptions: JoinerOptionsRequiredJoiner,
          sectionOptions: SectionOptionsSections,
        });
      }
    }

    if (prevState.awning.isAssembled !== this.state.awning.isAssembled) {
      this.setState({
        sectionOptions: !this.state.awning.isAssembled ? SectionOptionsSections : [],
      });
    }
  }

  getValidationMessages(fieldName, val){
    let validationMessages = {...this.state.validationMessages};
    let validation = ValidationRules[`${fieldName}`];
    if (validation) {
      let validationResult = validation.validator(val, this.state, this.props);
      if (!validationResult.isValid) {
        validationMessages[`${fieldName}`] = validationResult.message;
      } else {
        validationMessages[`${fieldName}`] = '';
      }
    }
    return validationMessages;
  }

  handleSelectChange(awningFieldName, val, valSelector, fieldName = null, action = null) {
    let x = {...this.state.awning};
    let newState = {...this.state};
    let awningFieldValue =  valSelector(val);
    x[`${awningFieldName}`] = awningFieldValue;

    if(fieldName){
      newState[`${fieldName}`] = val;
    }
    let validationMessages = this.getValidationMessages(awningFieldName, awningFieldValue);

    newState.validationMessages = validationMessages;
    newState.awning = x;

    if(action){
      newState = action(val, newState);
    }
    this.setState(newState, this.raiseAwningChanged);
  }

  raiseAwningChanged(){
    this.props.onChange(this.state.awning);
  }

  handleAddAwningNote() {
    let request = {
      awningId: this.state.awning.id,
      noteText: this.state.noteText,
    };
    apiPost(`/api/orders/${this.state.awning.orderId}/addOrderAwningNote`, request)
      .then(x => {
        if (x.data) {
          toastr.success('Added awning note');
          this.setState({awning: {...x.data}, noteText: ''}, this.raiseAwningChanged);
        } else {
          toastr.success('Error while submitting');
          console.log(x);
        }
      });
  }

  isValidAwning(){
    let {awning} = this.state;
    let messages = {};
    let isValid = true;

    for (let propName in ValidationRules) {

      let rule = ValidationRules[propName];
      let val = awning[propName];

      let valResult = rule.validator(val, this.state, this.props);
      if (!valResult.isValid) {
        messages[propName] = valResult.message;
        isValid = false;
      }
    }
    this.setState({validationMessages: messages});
  }

  componentWillReceiveProps(nextProps){
    this.setState({awning: nextProps.awning});
  }

  render() {
    let {awning, validationMessages} = this.state;
    let {order} = this.props;
    let {fieldConfiguration} = awning;
    let {canEditJoiner, canEditMotor, canAddNote, orderStatus, canEditFabricInstructions, canEditAttachment, awningCount} = this.props;
    let hasFieldConfiguration = !IsNullOrUndefined(fieldConfiguration);
    let awningRequiresFabric = AwningRequiresFabric(order, awning);
        
    return awning && awning.id > 0 ? (
      <div className="pi-section"
           style={{paddingLeft: '15px', paddingTop: '15px', paddingBottom: '15px', border: '2px solid #666', marginBottom: '15px'}}>

        <div className="pi-row">
          {hasFieldConfiguration ?
              <h4>{awning.productShortName} Set awnings #{awning.number} - {awning.number+fieldConfiguration.numberOfFields-1} ({fieldConfiguration.numberOfFields} fields)</h4>
            : <h4>Awning #{awning.number} of {awningCount}</h4>}
        </div>

        <SummaryDivider labelText="Size and Operation"/>

        <TextViewControl
          labelText="Product"
          value={`${awning.productGroupDescription} - ${awning.productDescription}`}
        />

        <TextViewControl
          labelText="Size"
          value={`${awning.width.toString()} x ${awning.projection.toString()}`}
          visible={!hasFieldConfiguration}
        />

        {fieldConfiguration && fieldConfiguration.fields.map(field => {
          return (<TextViewControl
            key={`${awning.id}_${field.number}`}
            labelText={`Field size #${field.number}`}
            value={`${field.width.toString()} x ${awning.projection.toString()}${field.isMotorRequired ? `  ${field.sideOfOperation} motor` : ''}`}
          />);
        })}

        <TextViewControl
          labelText="Drop"
          value={awning.drop}
        />

        { awning.flashingType > 0 && awning.flashingType !== FLASHINGTYPE_WallSeal && <TextViewControl
          labelText={`Has flashing`}
          value={''}
        /> }
          { awning.flashingType === FLASHINGTYPE_WallSeal && <TextViewControl
              labelText={`Has wall seal`}
              value={''}
          /> }

        <TextViewControl
          labelText="Drive mechanism"
          value={awning.driveMechanismDescription}
        />

          { awning.canSupplyOwnMotor && <TextViewControl
              labelText="Motor supplied by"
              value={order.organisationShortName}
          /> }

        {awning.motorId && awning.productGroupId !== PRODUCTGROUPID_CONSERVATORY && <TextViewControl
          labelText="Motor"
          value={ `${awning.motorBrandDescription} - ${awning.motorDescription}` }
        /> }
        { awning.productGroupId === PRODUCTGROUPID_CONSERVATORY &&
        <TextViewControl
          labelText="Motors"
          value={ IsNullOrUndefined(awning.fieldConfiguration) ? '1' : `${awning.fieldConfiguration.numberOfMotors}` }
        />}

        <RadioControlGroup
          onChange={(v) => this.setState({awning: {...awning, motorFittingCharge: v.simpleValue}}, this.raiseAwningChanged)}
          name={`${awning.id}_motorfitting`}
          options={[{value: true, label: 'Yes'}, {value: false, label: 'No'}]}
          visible={canEditMotor && awning.canMotorFittingCharge}
          labelText={'Fit motor charge'}
          value={awning.motorFittingCharge}
          style={{marginLeft: '0px'}}
        />

        {(awning.sections > 1 || awning.width > 7000) && <TextViewControl
          labelText="Sections"
          value={awning.sections}
        />}

        {(canEditJoiner && !noSectionsAllowed(awning)) ? <SelectControl
            labelText="Joiner"
            name="isAssembled"
            options={this.state.joinerOptions}
            onChange={(val) => this.handleSelectChange('isAssembled', val, (v) => v.value, null, (v, state) => {
              return {...state, awning: {...state.awning, sections: state.awning.defaultSections}};
            })}
            value={awning.isAssembled}
            placeholder="Select Joiner"
            validationMessages={validationMessages}
          /> : (awning.sections > 1 || awning.width > 7000) ? <TextViewControl
            labelText="Joiner"
            value={awning.isAssembled ? 'Fully assembled' : 'Dispatched in sections'}
          /> : null
        }

        {(awning.sections > 1 || awning.width > 7000 || !awning.isAssembled) && canEditJoiner && !noSectionsAllowed(awning) &&
           <SelectControl
            labelText="Sections"
            name="sections"
            options={this.state.sectionOptions}
            onChange={(val) => this.handleSelectChange('sections', val, (v) => v.value)}
            value={awning.sections}
            placeholder="Select Sections"
            validationMessages={validationMessages}
          />
        }

        {orderStatus === ORDER_STATUS_ORDER_REQUESTED && awning.numberOfArms === 3 && awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM ?
          <SelectControl
            labelText="Third arm side"
            name="middleArmSide"
            options={this.state.middleArmOptions}
            onChange={(val) => this.handleSelectChange('middleArmSide', val, (v) => v.value)}
            value={awning.middleArmSide}
            placeholder="Select third arm side"
            validationMessages={validationMessages}
                /> : (orderStatus > ORDER_STATUS_ORDER_REQUESTED && awning.numberOfArms === 3 && awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM && <TextViewControl
            labelText="Third arm side"
            value={awning.middleArmSideDescription}
          />)
        }

        {awning.wallSwitch != null && <TextViewControl
          labelText="Wall switch"
          value={awning.wallSwitch}
        />}

        <TextViewControl
          labelText={'Side of Operation'}
          value={awning.sideOfOperation}
          visible={IsNullOrUndefined(awning.fieldConfiguration)}
        />
          { ((awning.frameFeature && awning.productGroupId !== PRODUCTGROUPID_ROOFING_SYSTEMS) || (awning.fieldConfiguration && awning.fieldConfiguration.numberOfFields > 1)) &&
        <TextViewControl
          labelText="Frame feature"
          value={awning.frameFeatureDescription}
        /> }

      { awning.fieldConfiguration && awning.fieldConfiguration.fieldConfigurationOption &&
        <TextViewControl
          labelText="Field option"
          value={awning.fieldConfiguration.fieldConfigurationOption.optionAsString}
        />}

        {(awning.frameFeature && (awning.numberOfCradles + awning.additionalCradles) > 0) && <TextViewControl
              labelText="Note"
              value={'Selectapitch is not supplied on an awning with a cradle'}
          />}

        <TextViewControl
          labelText="Frame feature drive mechanism"
          value={awning.frameFeatureDriveMechanismDescription}
        />
        <TextViewControl
          labelText="Frame feature side"
          value={awning.frameFeatureSideOfOperation}
        />

        <SummaryDivider labelText="Powdercoat Colour"/>

        <ColourText labelText="Frame colour" standardColour={awning.frameColourDescription}
                    customColour={awning.frameCustomColourDescription}/>
        {awning.includePelmet && <ColourText labelText={`${awning.pelmetName}`} standardColour={awning.pelmetColourDescription}
                                             customColour={awning.pelmetCustomColourDescription}/>}
        {(awning.flashingColourId || awning.flashingCustomColourId) &&
        <ColourText labelText="Flashing colour" standardColour={awning.flashingColourDescription}
                    customColour={awning.flashingCustomColourDescription}/>}

          {awning.awningFlashing && awning.awningFlashing.colorbondColourId > 0 &&
              <div className="alert alert-info" role="alert"><i
                  className={'fa fa-info-circle'}/>
                  &nbsp;Please note minor colour variation between powdercoat and Colorbond flashing colour, the
                  matching Colorbond colour is: {awning.awningFlashing.colorbondColourDescription}
              </div>
          }

        <AwningSADimensionSummary awning={awning} />          
          
        <SummaryDivider labelText="Fabric"/>

        <TextViewControl
          labelText="Fabric"
          value={awning.fabricSuppliedByDescription}
        />

        {awningRequiresFabric && <TextViewControl
          labelText="Fabric brand"
          value={awning.fabricBrandDescription}
        />}

        {awningRequiresFabric && <TextViewControl
          labelText="Fabric style"
          value={awning.fabricDescription}
        />}

        {awningRequiresFabric && !awning.fabricIsSupplierOutOfStock && <TextViewControl
          labelText="Fabric stocked"
          value={awning.fabricIsStocked ? 'Yes' : 'Not in Australia, on request'}
        />}

          {awningRequiresFabric && awning.fabricIsSupplierOutOfStock &&
          <div className="pi-row">
              <div className={"col-12"} style={{color: '#ae2128'}}>
                <i className={'fa fa-exclamation-triangle'}/> Fabric is currently out of stock at supplier{awning.fabricExpectedStockDate ? ', expected ' + moment(awning.fabricExpectedStockDate).format('MMM Do') : ', expected date unknown'}
              </div>    
          </div>
          }          

        {awningRequiresFabric && <TextViewControl
          labelText="Valance"
          value={awning.fabricValanceDescription}
        /> }

        {awning.fabricValance && awning.trimBiasColourId && <TextViewControl
          labelText="Trim bias colour"
          value={awning.trimBiasColourDescription}
        />}

        {canAddNote && awningRequiresFabric &&
        <div className="pi-row" style={{marginTop: '15px'}}>
          <div className="pi-col-sm-4"><label htmlFor="fabricNote">Fabric Note</label></div>
          <div className="pi-col-sm-8">
            <input type={'text'} className={'form-control'}
                                     value={awning.fabricNote ? awning.fabricNote : ''}
                                     onChange={(e) => this.setState({awning: {...awning, fabricNote: e.target.value}}, this.raiseAwningChanged)}
                                     name='fabricNote'/>
          </div>
        </div>
        }

        {canEditFabricInstructions && awningRequiresFabric &&
        <RadioControlGroup
          name={`fabricInstruction_${awning.id}`}
          onChange={(x, e) => {
            this.setState({awning: {...awning, fabricInstruction: x.simpleValue}}, this.raiseAwningChanged);
          }}
          value={awning.fabricInstruction}
          labelText='Fabric Instructions'
          validationMessages={{}}
          options={FabricInstructions}
          style={{marginLeft: '0px'}}
        />
        }

        <TextViewControl
          labelText="VV fabric brand"
          value={awning.frameFeatureFabricBrandDescription}
        />
        <TextViewControl
          labelText="VV fabric style"
          value={awning.frameFeatureFabricDescription}
        />

        {awning.frameFeatureFabricBrandDescription && !awning.frameFeatureFabricIsSupplierOutOfStock && <TextViewControl
          labelText="VV fabric stocked"
          value={awning.frameFeatureFabricIsStocked ? 'Yes' : 'Not in Australia, on request'}
        />}

          {awning.frameFeatureFabricBrandDescription && awning.frameFeatureFabricIsSupplierOutOfStock &&
          <div className="pi-row">
              <div className={"col-12"} style={{color: '#ae2128'}}>
                  <i className={'fa fa-exclamation-triangle'}/> Fabric is currently out of stock at supplier{awning.frameFeatureFabricExpectedStockDate ? ', expected ' + moment(awning.frameFeatureFabricExpectedStockDate).format('MMM Do') : ', expected date unknown'}
            </div>    
          </div>
          }
          

        {(awning.crankHandleDescription ||
          awning.frameFeatureCrankHandleDescription ||
          awning.remoteDescription ||
          awning.sensorTypeDescription ||
          awning.motionSensorColour ||
          awning.automationCableDescription ||
          awning.mocoTypeDescription) &&
        <SummaryDivider labelText="Control and Accessories"/>}

        <TextViewControl
          labelText="Crank handle"
          value={awning.crankHandleDescription}
        />
        <TextViewControl
          labelText="Crank handle"
          value={awning.frameFeatureCrankHandleDescription}
        />

        {awning.remoteDescription && <TextViewControl
          labelText="Remote control"
          value={SpaceString([awning.remoteDescription, awning.desiredRemoteColour, IsNullOrUndefined(awning.remoteParentAwningNumber) ? null : `join Awning#${awning.remoteParentAwningNumber}`], ' - ')}
        /> }

        <TextViewControl
          labelText="Automation sensor"
          value={awning.sensorTypeDescription}
        />
        <TextViewControl
          labelText="Motion sensor colour"
          value={awning.motionSensorColour}
        />
        <TextViewControl
          labelText="Automation cable"
          value={SpaceString([awning.automationCableDescription, awning.mocoTypeDescription], ' - ')}
        />
        
        { !IsNullOrUndefined(awning.includeRainCover) && isSDorSM(awning) && <TextViewControl
          labelText="Rain cover"
          value={awning.includeRainCover === true ? 'Yes' : 'No'}
        /> }

        {awning.awningImport && <div>
          <SummaryDivider labelText="Imported Record"/>
          <TextViewControl labelText="Production Number" value={awning.awningImport.productionNumber}/>
          <TextViewControl labelText="Order Date"
                           value={moment(awning.awningImport.orderDate).format(DATE_SHORT_FORMAT)}/>
          <TextViewControl labelText="Customer" value={awning.awningImport.customer}/>
          <TextViewControl labelText="Customer Ref" value={awning.awningImport.customerRef}/>
          <TextViewControl labelText="Invoice" value={awning.awningImport.invoice}/>
          {awning.awningImport.dateComplete && <TextViewControl labelText="Date Complete"
                                                                value={moment(awning.awningImport.dateComplete).format(DATE_SHORT_FORMAT)}/>}
          <TextViewControl labelText="Status" value={awning.awningImport.status}/>
          <TextViewControl labelText="Comment 1" value={awning.awningImport.comment1}/>
          <TextViewControl labelText="Powder Coater" value={awning.awningImport.powderCoater}/>
          {awning.awningImport.powderCoaterDateSent && <TextViewControl labelText="FPC Date Sent"
                                                                        value={moment(awning.awningImport.powderCoaterDateSent).format(DATE_SHORT_FORMAT)}/>}
          {awning.awningImport.powderCoaterDateRec && <TextViewControl labelText="PC Date Received"
                                                                       value={moment(awning.awningImport.powderCoaterDateRec).format(DATE_SHORT_FORMAT)}/>}
          <TextViewControl labelText="Fabric Supplier" value={awning.awningImport.fabricSupplier}/>
          {awning.awningImport.fabricOrderDate && <TextViewControl labelText="Fabric Order Date"
                                                                   value={moment(awning.awningImport.fabricOrderDate).format(DATE_SHORT_FORMAT)}/>}
          {awning.awningImport.fabricDateRec && <TextViewControl labelText="Fabric Received"
                                                                 value={moment(awning.awningImport.fabricDateRec).format(DATE_SHORT_FORMAT)}/>}
          <TextViewControl labelText="Product" value={awning.awningImport.productAbbreviation1}/>
          <TextViewControl labelText="Product Dis/Oly" value={awning.awningImport.productDisOly}/>
          <TextViewControl labelText="Product Sd/Nob" value={awning.awningImport.productSdNob}/>
          <TextViewControl labelText="Product Vert" value={awning.awningImport.productVert}/>
          <TextViewControl labelText="Width" value={awning.awningImport.width}/>
          <TextViewControl labelText="Projection" value={awning.awningImport.projection}/>
          <TextViewControl labelText="Colour" value={awning.awningImport.colour}/>
          <TextViewControl labelText={awning.pelmetName} value={awning.awningImport.pelmet}/>
          <TextViewControl labelText="Custom Colour" value={awning.awningImport.customColour}/>
          <TextViewControl labelText="Drive" value={awning.awningImport.driveMechanism}/>
          <TextViewControl labelText="Motor" value={awning.awningImport.motor}/>
          <TextViewControl labelText="Control" value={awning.awningImport.control}/>
          <TextViewControl labelText="Other Automation" value={awning.awningImport.otherAutomation}/>
          <TextViewControl labelText="Crank" value={awning.awningImport.crank}/>
          <TextViewControl labelText="Fascia" value={awning.awningImport.fascia}/>
          <TextViewControl labelText="Rafter" value={awning.awningImport.rafter}/>
          <TextViewControl labelText="Cradle" value={awning.awningImport.cradle}/>
          <TextViewControl labelText="Uni Brackets" value={awning.awningImport.uniBrackets}/>
          <TextViewControl labelText="Comment 2" value={awning.awningImport.comment2}/>
        </div>}

        <AwningProductSpecifications awning={awning} />

        {(canAddNote || awning.notes.length > 0) &&
        <div className="pi-row" style={{marginTop: '15px'}}>
          <div className="pi-col-sm-4"><label htmlFor="orderAwningNote">Awning Note</label></div>
          <div className="pi-col-sm-6">
            {canAddNote && <textarea className={'form-control'}
                                                             value={this.state.noteText}
                                                             onChange={(e) => this.setState({noteText: e.target.value})}
                                                             name='noteText'/>}

          </div>
          <div className="pi-col-sm-2">
            {this.state.noteText.trim() != '' && <button
              onClick={this.handleAddAwningNote}
              className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing">
              <i className="fa fa-save"></i>
            </button>}
          </div>
        </div>}
        {awning.notes.map(x => {
            return (
                <Aux key={x.id}>
                    <div className="pi-row font-weight-bold">
                        <div className="pi-col-sm-4">{moment(x.createdDate).format(DATE_SHORT_FORMAT)}</div>
                        <div className="pi-col-sm-4">
                            {x.createdByPersonName}
                        </div>
                    </div>
                    <div className="pi-row">
                        <div className="pi-col-sm-12">
                            {x.description}
                        </div>
                    </div>
                </Aux>
            ); 
        })}

        {canEditAttachment && <div style={{marginTop: '20px', marginLeft: '15px'}} className={'awning-summary-attachments'}>
          <AwningAttachments
            attachments={!awning.attachments ? [] : awning.attachments}
            onChange={(attachments) => {
              this.setState({awning: {...awning, attachments}}, this.raiseAwningChanged);
            }}
          />
        </div> }

          <div onClick={this.props.onExpandArmBracketSheet} style={{cursor: 'pointer'}} className={'awning-summary-diagram'}>

          { awning.productGroupId === PRODUCTGROUPID_CONSERVATORY &&
              <DiagramSD awning={awning} width={475} title={'Multi Field Configuration Diagram'} /> }
          { awning.productGroupId === PRODUCTGROUPID_FOLDING_ARM &&
              <DiagramFA awning={awning} width={400} title={'Arm Bracket Spacing Diagram'} /> }
          { awning.productGroupId === PRODUCTGROUPID_ROOFING_SYSTEMS &&
              <DiagramST awning={awning} width={475} title={'Multi Field Configuration Diagram'} /> }

          </div>

      </div>) : null;
  }
}

AwningSummaryView.propTypes = {
    awning: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    awningCount: PropTypes.number.isRequired,
    awningSetCount: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    orderStatus: PropTypes.number.isRequired,
    canAddNote: PropTypes.bool.isRequired,
    canEditMotor: PropTypes.bool.isRequired,
    canEditJoiner: PropTypes.bool.isRequired,
    canEditFabricInstructions: PropTypes.bool.isRequired,
    onExpandArmBracketSheet: PropTypes.func.isRequired,
    canEditAttachment: PropTypes.bool.isRequired,
};

AwningSummaryView.defaultProps = {
  canAddNote: false,
  canEditMotor: false,
  canEditJoiner: false,
  canEditFabricInstructions: false,
  canEditAttachment: false,
};

export default AwningSummaryView;