import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router';
import OrdersPage from '../components/orders/OrdersPage';
import HomePage from '../components/portal/HomePage';
import EditOrderPage from '../components/orders/EditOrderPage';
import OrderSummaryPage from '../components/orders/OrderSummaryPage';
import SpareOrderPage from '../components/orders/SpareOrderPage';
import MangageCustomersPage from '../components/portal/MangageCustomersPage';
import ProductResourcesPage from '../components/portal/ProductResourcesPage';
import VideoResourcesPage from '../components/portal/VideoResourcesPage';
import NewCustomerPage from "../components/portal/NewCustomerPage";
import LoginPage from '../components/portal/LoginPage';
import PasswordResetPage from '../components/portal/PasswordResetPage';
import {loadCurrentUser} from '../actions/userActions';
import {login, logout, loginWithOrderToken} from '../actions/identityActions';
import {loadOrganisations} from '../actions/organisationActions';
import {loadLookups} from '../actions/lookupActions';
import {
  orders_list_view_screen_all,
  edit_order_view_screen_quote,
  edit_order_view_screen_amendment_requested,
  view_order_view_screen_all,
  spare_order_view_screen_quote,
  spare_order_view_screen_amendment_requested,
  ROLE_Administrator,
  orders_list_list_all_organisations_all,
  ORDER_STATUS_IN_PRODUCTION,
  ORDER_STATUS_ORDER_REQUESTED,
  IsNullOrUndefined, ROLE_ProductManager
} from '../constants';
import querySearch from "stringquery";
import Select from 'react-select';
import { Link } from 'react-router-dom';
import AutoReload from "../components/common/AutoReload";
import MotorOrderPage from "../components/myob/MotorOrderPage";
import InternalResourcesPage from "../components/portal/InternalResourcesPage";
import LookupArmBracketSpacingSheet from "../components/orders/LookupArmBracketSpacingSheet";
import FabricRecoverSheet from "../components/fabric/FabricRecoverSheet";
import FabricLookupPage from "../components/fabric/FabricLookupPage";
import ColourLookupPage from "../components/colour/ColourLookupPage";
import PcDataPage from "../components/portal/PcDataPage";
import initialState from "../reducers/initialState";
import {loadOrders} from "../actions/orderActions";
import PcRefOrderPage from "../components/pc/PcRefOrderPage";
import ResourceCartControl from "../components/resourceCart/ResourceCartControl";
import JobsPage from "../components/supplier/JobsPage";
import FabricRecoverOrderPage from "../components/fabric/FabricRecoverOrderPage";
import FabricRecoverOrdersPage from "../components/fabric/FabricRecoverOrdersPage";
import {GetCurrentCount} from "../utils/request";
import HttpInfoControl from "../components/common/HttpInfoControl";
import {loadSupplierOrders} from "../actions/supplierOrderActions";
import LegacyOrdersPage from "../components/legacy_orders/LegacyOrdersPage";
import SintesiLookupPage from "../components/orders/SintesiLookupPage";
import SiteSettingsPage from "../components/portal/SiteSettingsPage";
import SupportRequestsPage from "../components/support/SupportRequestsPage";
import SupportRequestPage from "../components/support/SupportRequestPage";

require('es6-promise/auto');



class Main extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.initialFilter = this.initialFilter.bind(this);
    }

    componentWillMount(){
      if(this.props.orderToken){
        this.props.dispatch(loginWithOrderToken(this.props.orderToken, this.props.history));
      } else if(!this.props.identity.accessToken && !this.props.userToken) {
        this.props.dispatch(login());
      }
    }

  initialFilter(user) {
    if (user.hasAccessTag(orders_list_list_all_organisations_all)) {
      return {        
        filter: {
          ...initialState.orders.filter,
          orderStatuses: [ORDER_STATUS_IN_PRODUCTION, ORDER_STATUS_ORDER_REQUESTED],
        },
        nonPostFilter: {
          ...initialState.orders.nonPostFilter,
        }
      };
    } else {
      return {        
        filter: {
          ...initialState.orders.filter,
          orderStatuses: [ORDER_STATUS_IN_PRODUCTION, ORDER_STATUS_ORDER_REQUESTED],
        },
        nonPostFilter: {
          ...initialState.orders.nonPostFilter,
          sorted: [{id: "orderStatusDescription", desc: false}, {id: "createdDate", desc: true}],
        }
      };
    }
  }

    componentDidUpdate(prevProps){
      if((this.props.identity.accessToken && prevProps.identity.accessToken !== this.props.identity.accessToken)){
        this.props.dispatch(loadCurrentUser());
        this.props.dispatch(loadOrganisations());
        this.props.dispatch(loadLookups());
      }
      if(this.props.user.id !== prevProps.user.id){
        let {filter, nonPostFilter} = this.initialFilter(this.props.user)
        this.props.dispatch(loadOrders(filter, nonPostFilter));
        this.props.dispatch(loadSupplierOrders());
      }
    }

    render() {
        let {user, identity, organisationId, resourceCart, organisation} = this.props;
        let canEditQuote = user.hasAccessTag(edit_order_view_screen_quote) || user.hasAccessTag(edit_order_view_screen_amendment_requested);
        let canEditSpareQuote = user.hasAccessTag(spare_order_view_screen_quote) || user.hasAccessTag(spare_order_view_screen_amendment_requested);

        return (<div id="pi-all">
          <AutoReload url={'/index.html'} tryDelay={5 * 60 * 1000} forceDelay={24 * 60 * 60 * 1000}/>
          <div className="pi-header">

            <div className="pi-section-w pi-section-dark">
              <div className="pi-section pi-row-sm">
                <div className="pi-row-block pi-row-block-txt">
                  <i className="pi-row-block-icon icon-phone pi-icon-base pi-icon-square"/>Call Us:&nbsp;
                  <strong>+61 2 9907 2211</strong>
                </div>
                <div className="pi-row-block pi-row-block-txt pi-hidden-xs">
                  <i className="pi-row-block-icon icon-mail pi-icon-base pi-icon-square"/>Email: <a href="mailto:info@aluxor.com.au">info@aluxor.com.au</a>
                </div>
                <div className="pi-row-block pi-row-block-txt pi-hidden-xs">
                  <i className="pi-row-block-icon icon-monitor pi-icon-base pi-icon-square"/>Web: <a href="http://www.aluxor.com.au/" target="_blank">www.aluxor.com.au</a>
                </div>
                { identity.accessToken &&
                <div className="pi-row-block pi-pull-right pi-hidden-2xs">
                  <br />Welcome {user.userName}&nbsp;<a href="#" onClick={(e) => {
                    e.preventDefault();
                    this.props.dispatch(logout(this.props.history));
                }}>logout&nbsp;&nbsp;<img src="/img/Portal2.png" /></a>
                </div> }
                <div className="pi-row-block pi-pull-right pi-hidden-2xs">

                </div>
                <div className="pi-row-block pi-row-block-txt pi-pull-right pi-hidden-xs">&nbsp;</div>

              </div>
            </div>

            <div className="pi-header-sticky">
              <div className="pi-section-w pi-section-white pi-shadow-bottom pi-row-reducible">
                <div className="pi-section pi-row-lg">
                  <div className="pi-row-block pi-row-block-logo">
                    <a href="/#"><img src="img/logo-base.png" alt="" /></a>
                  </div>
                  <div className="pi-row-block pi-row-block-txt pi-hidden-2xs">&nbsp;</div>
                  <div className="pi-row-block pi-pull-right pi-hidden-lg-only pi-hidden-md-only">
                    <button className="btn pi-btn pi-mobile-menu-toggler" data-target="#pi-main-mobile-menu">
                      <i className="icon-menu pi-text-center"/>
                    </button>
                  </div>                  
                  <div className="pi-row-block pi-pull-right">                         
                    <ResourceCartControl resourceCartState={resourceCart} isAdmin={!IsNullOrUndefined(user) && (user.isInRole(ROLE_Administrator) || user.isInRole(ROLE_ProductManager) ) } />                    
                  </div>
                  <div className="pi-row-block pi-pull-right">
                    <h4 className={'mt-5 mr-5'}>
                      <HttpInfoControl />
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div id="pi-main-mobile-menu" className="pi-section-menu-mobile-w pi-section-dark">
              <div className="pi-section-menu-mobile">
                <ul className="pi-menu-mobile pi-items-have-borders pi-menu-mobile-dark">
                  <li style={{paddingLeft:'18px', paddingBottom: '5px'}}>
                    <Select
                      style={{marginTop: '30px', width: '325px'}}
                      labelText="Customer"
                      name="customer"
                      options={this.props.organisations.items}
                      valueKey={'id'}
                      labelKey={'description'}
                      onChange={(val) => {
                        if(val === null){
                          return;
                        }
                        let s = this.props.location.pathname.split('/manage/customers');
                        if(s.length <= 1 || s.length > 2){
                          this.props.history.push(`/manage/customers/${val.id}`);
                        }
                        else if(s.length === 2) {
                          this.props.history.push(`/manage/customers/${val.id}/${s[1]
                            .replace(/\//g, '').replace(/[0-9]/g, '')}`);
                        }
                      }}
                      value={organisationId}
                      clearable={false}
                      backspaceRemoves={false}
                    />
                  </li>
                  <li>
                    <Link to={`/manage/customers/${organisationId}`}>Customer Profile</Link>
                  </li>
                  { organisation && organisation.requiresAcceptedTerms && <li><Link to={`/manage/customers/${organisationId}/terms`}>Customer Terms</Link></li> }
                  <li><Link to={`/manage/customers/${organisationId}/users`}>User Management</Link></li>
                  { user.isInRole(ROLE_Administrator) && <li><Link to={`/manage/customers/${organisationId}/portalservices`}>Portal Services</Link></li> }
                  { user.isInRole(ROLE_Administrator) &&<li><Link to={`/manage/customers/${organisationId}/productaccess`}>Product Access</Link></li> }
                </ul>

              </div>
            </div>

          </div>

          {this.props.identity.accessToken ? (
            <div className="pi-section-w pi-section-white">
              <div className={'container'}>
                <Switch>
                  <Route exact path='/' component={HomePage}/>
                  {user.hasAccessTag(orders_list_view_screen_all) &&
                  <Route exact path='/orders/list' component={OrdersPage}/>}
                  {user.hasAccessTag(edit_order_view_screen_quote) &&
                  <Route exact path='/orders/create' render={props => <EditOrderPage {...props} />} /> }
                  {canEditQuote && <Route exact path='/orders/edit/:orderId' component={EditOrderPage}/>}
                  {canEditSpareQuote && <Route exact path='/spares/edit/:orderId' component={SpareOrderPage}/>}
                  {user.hasAccessTag(view_order_view_screen_all) &&
                  <Route path='/orders/view/:orderId' component={OrderSummaryPage}/>}
                  <Route path='/spares/create' component={SpareOrderPage}/>
                  <Route exact path='/manage/customers' component={MangageCustomersPage}/>
                  <Route path='/manage/customers/:id' component={MangageCustomersPage}/>
                  <Route path='/products/resources' component={ProductResourcesPage}/>
                  <Route path='/products/videos' component={VideoResourcesPage}/>
                  <Route path='/pcdata/lookup' component={PcDataPage}/>
                  <Route exact path='/manage/new-customer' component={NewCustomerPage}/>
                  <Route exact path='/manage/myob/motor-order' component={MotorOrderPage}/>
                  <Route path='/internal/resources' component={InternalResourcesPage}/>
                  <Route exact path='/products/armbracketspacing' component={LookupArmBracketSpacingSheet} />
                  <Route exact path='/calculators/fabricrecover' component={FabricRecoverSheet} />
                  <Route exact path='/fabrics/lookup' component={FabricLookupPage} />
                  <Route exact path='/colours/lookup' component={ColourLookupPage} />
                  <Route exact path='/pc/order' component={PcRefOrderPage} />
                  <Route path='/supplier/jobs' component={JobsPage} />
                  <Route exact path='/fabricRecoverOrders/create' component={FabricRecoverOrderPage}/>
                  <Route exact path='/fabricRecoverOrders/edit/:orderId' component={FabricRecoverOrderPage}/>
                  <Route exact path='/fabricRecoverOrders/list' component={FabricRecoverOrdersPage}/>
                  <Route exact path='/legacyOrders/list' component={LegacyOrdersPage}/>
                  <Route exact path='/sintesi/lookup' component={SintesiLookupPage}/>
                  <Route path='/manage/site' component={MangageCustomersPage}/>
                  <Route exact path='/supportRequests' component={SupportRequestsPage}/>
                  <Route exact path='/supportRequests/create' component={SupportRequestPage}/>
                  <Route exact path='/supportRequests/:requestId' component={SupportRequestPage}/>
                </Switch>
              </div>
            </div>

          ) : this.props.userToken ?
            <PasswordResetPage user={this.props.user} identity={this.props.identity}
                               userToken={this.props.userToken}/> :
            <LoginPage user={this.props.user} identity={this.props.identity}/>
          }


          <div className="pi-section-w pi-border-bottom pi-border-top-light pi-section-dark">
            <div className="pi-section pi-padding-bottom-10">

              <div className="pi-row">
                <div className="pi-col-md-4 pi-padding-bottom-30">
                  <ul className="pi-list-with-icons pi-list-big-margins">

                    <li>
                      <span className="pi-bullet-icon"><i className="icon-location"/></span>
                      <strong>Address:</strong> 22/9 Powells Road, <br />Brookvale, NSW 2100<br />
                    </li>

                  </ul>
                </div>
                <div className="pi-clearfix pi-hidden-lg-only pi-hidden-md-only"/>
                <div className="pi-col-md-4 pi-col-sm-6 pi-padding-bottom-30" style={{backgroundImage: `url('img/map-base.png')`, backgroundPosition: '50% 55px', backgroundRepeat: 'no-repeat'}}>
                  <ul className="pi-list-with-icons pi-list-big-margins">
                    <li>
                      <span className="pi-bullet-icon"><i className="icon-phone"/></span>
                      <strong>Phone:</strong> +61 2 <strong>9907 2211</strong>
                    </li>
                    <li>
                      <span className="pi-bullet-icon"><i className="icon-mail"/></span>
                      <strong>Email:</strong> <a href="mailto:info@aluxor.com.au">info@aluxor.com.au</a>
                    </li>
                    <li>
                      <span className="pi-bullet-icon"><i className="icon-monitor"/></span>
                      <strong>Web:</strong> <a href="http://www.aluxor.com.au/" target="_blank">www.aluxor.com.au</a>
                    </li>

                  </ul>
                </div>
                <div className="pi-col-md-4 pi-col-sm-6 pi-padding-bottom-30">
                  <ul className="pi-list-with-icons pi-list-big-margins">
                    <li>
                      <span className="pi-bullet-icon"><i className="icon-clock"/></span>
                      Monday - Friday: <strong>7:30 am - 3:30 pm</strong>
                      <br />
                        Saturday - Sunday: <strong>Closed</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>);
    }
}

function mapStateToProps(state, ownProps) {
  let organisationId = state.user.organisation.id;
  if(ownProps.match && ownProps.match.params && ownProps.match.params.id){
    organisationId = parseInt(ownProps.match.params.id);
  }
  let queryTags = querySearch(ownProps.location.search);
  let {userToken, orderToken} = queryTags;
  let filteredOrgs = state.organisations.items.filter(x => x.id === organisationId);

  return {
    user: state.user,
    organisationId,
    organisations: state.organisations,
    identity: state.identity,
    userToken,
    orderToken,
    resourceCart: state.resourceCart,
    organisation: filteredOrgs.length === 1 ? filteredOrgs[0] : null,
//    httpInfo: state.httpInfo,
  };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
