import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {apiRequest} from "../../utils/request";
import {
  DATE_SEARCH_FORMAT,
  DATE_SHORT_FORMAT,
  FABRIC_RECOVER_ORDER_STATUS_QUOTE,
  FabricRecoverOrderStatuses,
  INVOICE_TYPE_FABRICRECOVER,
  isNullEmptyOrWhitespace,
  obsfucate, OrderStatuses, UrlSegmentArray, UrlSegmentString,
} from "../../constants";
import moment from "moment";
import ReactTable from "react-table";
import Select from "react-select";
import DatePicker from "react-datepicker";

const initState = {
  orders: [],
  columns: [],
  filter: {
    sequenceNumber: null, 
    customer: null,
    customerReferenceNumber: null,
    invoiceNumber: null,
    powderCoater: null, 
    fabricMaker: null, 
    product: [],
    createdDateFrom: null,
    createdDateTo: null,
  },
};

const FilterProducts = [
  { label: 'Arms', value: 'Arm' },
  { label: 'AU', value: 'AU' },
  { label: 'CX', value: 'CX' },
  { label: 'DS', value: 'DS' },
  { label: 'EC', value: 'EC' },
  { label: 'MK', value: 'MK' },
  { label: 'OL', value: 'OL' },
  { label: 'OS', value: 'OS' },
  { label: 'PA', value: 'PA' },
  { label: 'Parts', value: 'Parts' },
  { label: 'Recover', value: 'Recover' },
  { label: 'S1', value: 'S1' },
  { label: 'S3', value: 'S3' },
  { label: 'SD', value: 'SD' },
  { label: 'SD CP', value: 'SD CP' },
  { label: 'SD SA', value: 'SD SA' },
  { label: 'SM', value: 'SM' },
  { label: 'SP', value: 'SP' },
  { label: 'SP S3', value: 'SP S3' },
  { label: 'ST', value: 'ST' },
  { label: 'TG', value: 'TG' },
  { label: 'TG OS', value: 'TG OS' },
  { label: 'TG VV', value: 'TG VV' },
  { label: 'VR', value: 'VR' },
  { label: 'VS', value: 'VS' },
  { label: 'VV S1', value: 'VV S1' },
  { label: 'WF', value: 'WF' },
  { label: 'ZT', value: 'ZT' }
];

const years = [{ label: '1900', value:'1900'},
  { label: '2000', value:'2000'},
  { label: '2005', value:'2005'},
  { label: '2006', value:'2006'},
  { label: '2007', value:'2007'},
  { label: '2008', value:'2008'},
  { label: '2009', value:'2009'},
  { label: '2010', value:'2010'},
  { label: '2011', value:'2011'},
  { label: '2012', value:'2012'},
  { label: '2013', value:'2013'},
  { label: '2014', value:'2014'},
  { label: '2015', value:'2015'},
  { label: '2016', value:'2016'},
  { label: '2017', value:'2017'},
  { label: '2018', value:'2018'},
  { label: '2019', value:'2019'},
  { label: '2020', value:'2020'}];

class LegacyOrdersPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {...initState};

    this.fetchOrders = this.fetchOrders.bind(this);   
    this.setColumns = this.setColumns.bind(this);
    this.updateFilterField = this.updateFilterField.bind(this);
    this.updateFilterFieldWithValue = this.updateFilterFieldWithValue.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  async fetchOrders() {

    let {filter} = this.state;
    
    let url = `/api/legacyOrders?`;

    url += UrlSegmentString('sequenceNumber', filter.sequenceNumber);
    url += UrlSegmentString('customer', filter.customer);
    url += UrlSegmentString('customerReferenceNumber', filter.customerReferenceNumber);
    url += UrlSegmentString('invoiceNumber', filter.invoiceNumber);
    url += UrlSegmentString('powderCoater', filter.powderCoater);
    url += UrlSegmentString('fabricMaker', filter.fabricMaker);
    url += UrlSegmentArray('product', filter.product);
    url += UrlSegmentString('createdDateFrom', isNullEmptyOrWhitespace(filter.createdDateFrom) ? null : moment(filter.createdDateFrom).format(DATE_SEARCH_FORMAT));
    url += UrlSegmentString('createdDateTo', isNullEmptyOrWhitespace(filter.createdDateTo) ? null : moment(filter.createdDateTo).format(DATE_SEARCH_FORMAT));
    
    let orders = (await apiRequest(url)).data;
    this.setState({
      orders,
    }, () => {
      this.setColumns()
    });
  }

  setColumns() {
    this.setState({
      columns: [{id: 'sequenceNumber', Header: 'Prod. No.', 
        accessor: d => d.sequenceNumber,
        sortMethod: (a, b) => {
          let aa = a.indexOf(".") > 0 ? a.substring(0, a.indexOf(".")) : a;
          let bb = b.indexOf(".") > 0 ? b.substring(0, b.indexOf(".")) : b;
                  
          let aVal = parseInt(aa);
          let bVal =  parseInt(bb);

          return aVal < bVal ? -1 : 1;
        },
      },
        {
          id: 'createdDate', 
          Header: 'Created Date', 
          accessor: d => moment(d.createdDate).format(DATE_SHORT_FORMAT),
          sortMethod: (a, b) => {
            let aVal = moment(a, DATE_SHORT_FORMAT);
            let bVal = moment(b, DATE_SHORT_FORMAT);

            return aVal.isBefore(bVal) ? -1 : 1;
          },
        },
        {id: 'customer', Header: 'Customer', accessor: d => d.customer,},
        {id: 'customerReferenceNumber', Header: 'CRN', accessor: d => d.customerReferenceNumber,},
        {id: 'invoiceNumber', Header: 'Inv. No.', accessor: d => d.invoiceNumber,},
        {id: 'product', Header: 'Product', accessor: d => d.product,},
        {id: 'width', Header: 'Width', accessor: d => d.width,},
        {id: 'projection', Header: 'Projection', accessor: d => d.projection,},
        {id: 'pcColour', Header: 'Colour', accessor: d => d.pcColour,},
        {id: 'pcColour2', Header: 'Colour 2', accessor: d => d.pcColour2,},
        {id: 'operation', Header: 'Operation', accessor: d => d.operation,},
        {id: 'motorType', Header: 'Motor Type', accessor: d => d.motorType,},
        {id: 'automation', Header: 'Automation', accessor: d => d.automation,}
      ],
    });
  }

  updateFilterField(e){
    let {filter} = this.state;    
    
    filter[e.target.name] = e.target.value;
    
    this.setState({filter});
  }

  updateFilterFieldWithValue(val, fieldName = null){
    let {filter} = this.state;

    filter[fieldName] = val;

    this.setState({filter});
  }
  
  resetFilters(e){
    e.preventDefault();   
    this.setState({filter: {
        sequenceNumber: null,
        customer: null,
        customerReferenceNumber: null,
        invoiceNumber: null,
        powderCoater: null,
        fabricMaker: null,
        product: [],
        createdDateFrom: null,
        createdDateTo: null,
      }});
  }

  render() {

    let {user,} = this.props;
    let {orders, columns, filter} = this.state;

    return (
      <div>
        <div className={"row mt-2"}>
          <div className={"col-6"}>
            <h2>Prod Database</h2>            
          </div>          
        </div>

        <div className={"row mt-2"}>
          <div className={"col-12"}>
            <form>
              <div className="form-group row">
                <div className="col-3">
                  <input type={'string'}
                         className={'form-control'}
                         value={filter.sequenceNumber || ""}
                         onChange={this.updateFilterField}
                         name={'sequenceNumber'}
                         placeholder={'Prod. No.'} />
                </div>
                <div className="col-3">
                  <input type={'string'}
                         className={'form-control'}
                         value={filter.customer || ""}
                         onChange={this.updateFilterField}
                         name={'customer'}
                         placeholder={'Customer'} />
                </div>
                <div className="col-3">
                  <input type={'string'}
                         className={'form-control'}
                         value={filter.customerReferenceNumber || ""}
                         onChange={this.updateFilterField}
                         name={'customerReferenceNumber'}
                         placeholder={'CRN'} />
                </div>
                <div className="col-3">                  
                  <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={true}
                      labelText="Product"
                      name="product"
                      options={FilterProducts}
                      onChange={(val) => 
                          this.updateFilterFieldWithValue(isNullEmptyOrWhitespace(val) ? [] : val.split(',').map(x => x), "product")}
                      simpleValue={true}
                      value={filter.product}
                      placeholder={'Product'}
                  />                
                </div>
              </div>             
              
              <div className="form-group row">
                <div className="col-3">
                  <input type={'string'}
                         className={'form-control'}
                         value={filter.invoiceNumber || ""}
                         onChange={this.updateFilterField}
                         name={'invoiceNumber'}
                         placeholder={'Inv. No.'} />
                </div>
                <div className={'col-sm-3'}>
                  <DatePicker
                      selected={filter.createdDateFrom}
                      selectsStart
                      startDate={filter.createdDateFrom}
                      endDate={filter.createdDateTo}
                      onChange={val => this.updateFilterFieldWithValue(val, 'createdDateFrom')}
                      className={'form-control'}
                      dateFormat={DATE_SHORT_FORMAT}
                      placeholderText={'Date From'}
                      dropdownMode={"scroll"}
                  /><br />
                  <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={false}
                      labelText="Year From"
                      name="yearFrom"
                      options={years}
                      onChange={(val) => this.updateFilterFieldWithValue(isNullEmptyOrWhitespace(val) ? null :
                          moment(`${val}-01-01`), "createdDateFrom")}
                      simpleValue={true}
                      value={filter.createdDateFrom === null ? null : moment(filter.createdDateFrom).year().toString()}
                      placeholder={'Year From'}
                  />
                </div>
                <div className={'col-sm-3'}>
                  <DatePicker
                      selected={filter.createdDateTo}
                      selectsEnd
                      startDate={filter.createdDateFrom}
                      endDate={filter.createdDateTo}
                      onChange={val => this.updateFilterFieldWithValue(val, 'createdDateTo')}
                      className={'form-control'}
                      dateFormat={DATE_SHORT_FORMAT}
                      placeholderText={'Date To'}
                      dropdownMode={"scroll"}
                  /><br />
                  <Select
                      clearable={true}
                      deleteRemoves={true}
                      backspaceRemoves={true}
                      multi={false}
                      labelText="Year To"
                      name="yearTo"
                      options={years}
                      onChange={(val) => this.updateFilterFieldWithValue(isNullEmptyOrWhitespace(val) ? null :
                          moment(`${val}-12-31`), "createdDateTo")}
                      simpleValue={true}
                      value={filter.createdDateTo === null ? null : moment(filter.createdDateTo).year().toString()}
                      placeholder={'Year To'}
                  /> 
                  
                </div>
                <div className={"col-3 text-right"}>
                  <div className="form-group">
                    <button
                        onClick={this.resetFilters}
                        className="btn pi-btn pi-uppercase mr-3">
                      <i className="fa fa-times" />&nbsp;Reset
                    </button>
                    <button
                        onClick={async (e)=>{
                          e.preventDefault();
                          await this.fetchOrders();
                        }}
                        className="btn pi-btn-base pi-uppercase pi-weight-700 pi-letter-spacing"
                    >
                      <i className="icon-floppy pi-icon-left"/>&nbsp;Search
                    </button>
                  </div>
                </div>                
              </div>             
              
            </form>
          </div>
        </div>
        
        <div className={"row mt-2 mb-4"}>
          <div className={"col-12"}>
            {orders.length >0 && <p><i>Found {orders.length} results {orders.length === 100 ? '(results are limited to the top 100)' : ''}</i></p> }
            <ReactTable                
                filterable={false}
                data={orders}
                columns={columns}
            />
          </div>
        </div>
        <div className={"row mt-4 mb-4"}>
          <div className={"col-12"}>&nbsp;</div>
        </div>
        
      </div>
    );
  }
}

LegacyOrdersPage.propTypes = {
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {

  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LegacyOrdersPage));